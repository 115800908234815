import React, { Component } from "react"
import { Row, Col, Card, CardBody, Button, Table, Input } from "reactstrap"
// Modal, ModalHeader, ModalBody, ModalFooter
import MetaTags from 'react-meta-tags'
import moment from 'moment'


// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';

import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"


import httprequester from '../../lib/httprequester'

class HandlingCMS extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      listHomepage: [],
      alert: false,
      openmodal: false,
      // modal: false,
      message: '',
      urlimage: '',
      id: null,
      chspew: false,
      hid: '',
      hasilEdit: null
    }

  }

  getHomepage = () => {
    let body = {
      // "hp_type": "event"
    }
    httprequester.postnobody('homepage/getall_detail', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          // console.log('data', response.data.detailMessage)
          this.setState({
            listHomepage: response.data.detailMessage
          })
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  handlecancel = () => {
    this.setState({
      alert: false
    })
  }

  approve = (id) => {
    // console.log('id', id)
    // console.log('hasu',this.state.hasilEdit)

    let hasil = this.state.listHomepage.find(i => i.hp_id == id)
    // console.log('as',hasil)
    let body = {
      "hp_id": id,
      "hp_hid": this.state.hasilEdit ? this.state.hasilEdit : hasil.hp_hid,
      "hp_type": hasil.hp_type
    }

    httprequester.postnobody('homepage/update', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          this.setState({
            message: response.data.detailMessage,
            alert: true
          })
          await this.getHomepage()
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }


  componentDidMount() {
    this.getHomepage()
  }

  render() {
    const columns = [{
      dataField: 'hp_title',
      text: 'Nama',
      editable: false
    }, {
      dataField: 'hp_type',
      text: 'Type',
      editable: false,
      hidden: true
    },
    {
      dataField: 'hp_type',
      text: 'Menu',
      formatter: (row) => {
        console.log('bener', row)
        let x = ""
        if (row.includes("article")) {
          x = "Artikel"
        } else if (row.includes("event")) {
          x = "Acara Ilmiah"

          if (row === 'event-internasional') {
            x = 'Acara Internasional';
          } else if (row === 'event-homepage') {
            x = 'Acara Homepage';
          }
        } else if (row.includes("jurnal")) {
          x = "Jurnal"
        } else if (row.includes("tata laksana")) {
          x = "Pedoman Tata Laksana"
        } else if (row.includes("cme")) {
          x = "CME Online"
        } else if (row.includes("webinar")) {
          x = "Webcast"
        } else if (row.includes("animasi")) {
          x = "Petunjuk Pemakaian Obat"
        } else if ((row.includes("tanya-ahli"))) {
          x = "Tanya Ahli"
        } else {
          x = "Tindakan Medis Spesialistik"
        }
        return x
      },
      editable: false
    },
    {
      dataField: 'hp_hid',
      text: 'ID'
    }, {
      dataField: 'hp_id',
      text: 'Aksi',
      editable: false,
      formatter: (row) => {
        // console.log('bener', row)
        return <div>
          <Button
            color="danger"
            className="btn-sm"
            onClick={(e) => { this.approve(row) }}
          >
            Simpan
          </Button>
        </div>
      }
    }];
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Headline | Ruang Ilmiah</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Headline" breadcrumbItem="Headline" />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <BootstrapTable
                      keyField="id"
                      data={this.state.listHomepage}
                      columns={columns}
                      cellEdit={cellEditFactory({
                        mode: 'click',
                        blurToSave: true,
                        afterSaveCell: (oldValue, newValue, row, column) => {
                          this.setState({
                            hasilEdit: newValue
                          })
                        }
                      })}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          {this.state.alert ?
            <SweetAlert success title={this.state.message} onConfirm={this.handlecancel}>
            </SweetAlert> : ''
          }

        </div>
      </React.Fragment>
    )
  }
}

export default HandlingCMS