import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import Axios from "axios";
import { Link } from "react-router-dom";
// import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import { Row, Col, Button, Card, CardBody, CardFooter, CardHeader, CardGroup, CardImg, CardTitle, CardSubtitle, CardText, Form, FormGroup, Input, Label, List, Table } from 'reactstrap';
import { Container, } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SweetAlert from 'react-bootstrap-sweetalert';
import { urlAPI } from '../../config';

class Create extends Component {
    constructor(props) {
        super(props);
        this.handleAddAdmin = this.handleAddAdmin.bind(this);
        this.onChangeAdminName = this.onChangeAdminName.bind(this);
        this.onChangeAdminPassword = this.onChangeAdminPassword.bind(this);
        this.state = {
            admin_name: "",
            admin_password: "",
            admin_status: "A",
            admin_previlege: [],
            successful: false,
            message: "",
            alert: false,
            admin: {
                name: "admin",
                access:"N"
            },
            user: {
                name: "user",
                access:"N"
            },
            jurnal_tatalaksana: {
                name: "jurnal-tata-laksana",
                access:"N"
            },
            ebook: {
                name: "ebook",
                access:"N"
            },
            webinar: {
                name: "webinar",
                access:"N"
            },
            tindakan: {
                name: "tindakan-medis-spesialistik",
                access:"N"
            },
            animasi: {
                name: "animasi-penggunaan-obat",
                access:"N"
            },
            acara_ilmiah: {
                name: "acara-ilmiah",
                access:"N"
            },
            artikel: {
                name: "artikel",
                access:"N"
            },
            cme_online: {
                name: "cme-online",
                access:"N"
            },
            tanya_ahli: {
                name: "tanya-ahli",
                access:"N"
            },
            master: {
                name: "master",
                access:"N"
            },
            file_manajer: {
                name: "file-manajer",
                access:"N"
            },
            headline: {
                name: "headline",
                access:"N"
            },
        };
    }

    handlecancel = () => {
        this.setState({
            alert: false,
            validation: false
        })
    }

    onChangeAdminName(e) {
        this.setState({
            admin_name: e.target.value
        });
    }

    onChangeAdminPassword(e) {
        this.setState({
            admin_password: e.target.value
        });
    }

    // changeStatus(e, name, privilege) {

    //     let x = (e.target.checked) ? "Y" : "N"
    //     let y = this.state[name]

    //     this.setState({
    //         [name]: { ...y, [privilege]: x }
    //     })
    // }

    changeStatus(e, obj, name) {
        // console.log("a => ", e.target.checked);
        // console.log("b => ", obj);
        // console.log("c => ", name);

        let x = name
        let y = (e.target.checked) ? "Y" : "N"

        this.setState({
            [obj]: { name: x, access: y }
        })
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    async handleAddAdmin(e) {
        let data = {
            admin_name: this.state.admin_name,
            admin_password: this.state.admin_password,
            admin_status: this.state.admin_status,
            admin_previlege: [
                this.state.admin,
                this.state.user,
                this.state.jurnal_tatalaksana,
                this.state.ebook,
                this.state.webinar,
                this.state.tindakan,
                this.state.animasi,
                this.state.acara_ilmiah,
                this.state.artikel,
                this.state.cme_online,
                this.state.tanya_ahli,
                this.state.master,
                this.state.file_manajer,
                this.state.headline
            ]
        }
        // console.log("kiriman => ", data)
        // let history = useHistory();
        e.preventDefault();

        if (this.state.admin_name && this.state.admin_password) {
            this.setState({
                validation: false
            })

            this.setState({
                message: "",
                successful: false,
                validation: false
            });

            var config = {
                method: 'post',
                url: urlAPI + 'admin/save',
                
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                },
                data: data
            };

            await Axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                })
            // this.setState({
            //     message: response.data.detailMessage,
            //     alert: true,
            //     loading: false,
            // })
            this.props.history.push('/list-admin')

                .catch(function (error) {
                    console.log(error);
                });
        } else {
            this.setState({
                validation: true,
                loading: false
            })
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Tambah Admin</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Administrator" breadcrumbItem="Create Admin" />
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md="10"></Col>
                                    <Col md="2">
                                        <Link to="/list-admin">
                                            <Button color="btn-sm btn btn-primary">
                                                Back
                                            </Button>
                                        </Link>
                                    </Col>
                                </Row>
                                <CardTitle className="mb-4">TAMBAH ADMINISTRATOR</CardTitle>

                                <Form
                                    onSubmit={this.handleAddAdmin}>
                                    <div className="row mb-3">
                                        <Label
                                            htmlFor="horizontal-firstname-Input"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Admin Name
                                        </Label>
                                        <Col sm={9}>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="horizontal-firstname-Input"
                                                name="admin_name"
                                                value={this.state.admin_name}
                                                onChange={this.onChangeAdminName}
                                                placeholder="Admin Name"
                                            />
                                        </Col>
                                        {this.state.validation ? <p style={{ color: 'red' }}>Admin Name is required</p> : ''}
                                        <br />
                                    </div>
                                    <div className="row mb-3">
                                        <Label
                                            htmlFor="horizontal-email-Input"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Admin Password
                                        </Label>
                                        <Col sm={9}>
                                            <Input
                                                type="password"
                                                className="form-control"
                                                id="horizontal-email-Input"
                                                value={this.state.admin_password}
                                                onChange={this.onChangeAdminPassword}
                                                placeholder="Admin Password"
                                            />
                                        </Col>
                                        {this.state.validation ? <p style={{ color: 'red' }}>Admin Password is required</p> : ''}
                                    </div>
                                    <div className="table-responsive mt-5">
                                        <Table className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th width="1px">No</th>
                                                    <th width="200px">Menu</th>
                                                    <th width="100px">Hak Akses</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">1</th>
                                                    <td>Admin</td>
                                                    <td>
                                                        <input className="form-check-Input" type="checkbox" value="Y" checked={(this.state.admin.access === "Y") ? true : false} onChange={(e) => this.changeStatus(e, "admin", "admin")} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">2</th>
                                                    <td>User</td>
                                                    <td>
                                                        <input className="form-check-Input" type="checkbox" value="Y" checked={(this.state.user.access === "Y") ? true : false} onChange={(e) => this.changeStatus(e, "user", "user")} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">3</th>
                                                    <td>Jurnal & Tata Laksana</td>
                                                    <td>
                                                        <input className="form-check-Input" type="checkbox" value="Y" checked={(this.state.jurnal_tatalaksana.access === "Y") ? true : false} onChange={(e) => this.changeStatus(e, "jurnal_tatalaksana", "jurnal-tata-laksana")} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">4</th>
                                                    <td>eBook</td>
                                                    <td>
                                                        <input className="form-check-Input" type="checkbox" value="Y" checked={(this.state.ebook.access === "Y") ? true : false} onChange={(e) => this.changeStatus(e, "ebook", "ebook")} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">5</th>
                                                    <td>Webinar</td>
                                                    <td>
                                                        <input className="form-check-Input" type="checkbox" value="Y" checked={(this.state.webinar.access === "Y") ? true : false} onChange={(e) => this.changeStatus(e, "webinar", "webinar")} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">6</th>
                                                    <td>Tindakan Medis Spesialistik</td>
                                                    <td>
                                                        <input className="form-check-Input" type="checkbox" value="Y" checked={(this.state.tindakan.access === "Y") ? true : false} onChange={(e) => this.changeStatus(e, "tindakan", "tindakan-medis-spesialistik")} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">7</th>
                                                    <td>Animasi Penggunaan Obat</td>
                                                    <td>
                                                        <input className="form-check-Input" type="checkbox" value="Y" checked={(this.state.animasi.access === "Y") ? true : false} onChange={(e) => this.changeStatus(e, "animasi", "animasi-penggunaan-obat")} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">8</th>
                                                    <td>Acara Ilmiah</td>
                                                    <td>
                                                        <input className="form-check-Input" type="checkbox" value="Y" checked={(this.state.acara_ilmiah.access === "Y") ? true : false} onChange={(e) => this.changeStatus(e, "acara_ilmiah", "acara-ilmiah")} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">9</th>
                                                    <td>Artikel</td>
                                                    <td>
                                                        <input className="form-check-Input" type="checkbox" value="Y" checked={(this.state.artikel.access === "Y") ? true : false} onChange={(e) => this.changeStatus(e, "artikel", "artikel")} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">10</th>
                                                    <td>CME Online</td>
                                                    <td>
                                                        <input className="form-check-Input" type="checkbox" value="Y" checked={(this.state.cme_online.access === "Y") ? true : false} onChange={(e) => this.changeStatus(e, "cme_online", "cme-online")} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">11</th>
                                                    <td>Tanya Ahli</td>
                                                    <td>
                                                        <input className="form-check-Input" type="checkbox" value="Y" checked={(this.state.tanya_ahli.access === "Y") ? true : false} onChange={(e) => this.changeStatus(e, "tanya_ahli", "tanya-ahli")} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">12</th>
                                                    <td>Master</td>
                                                    <td>
                                                        <input className="form-check-Input" type="checkbox" value="Y" checked={(this.state.master.access === "Y") ? true : false} onChange={(e) => this.changeStatus(e, "master", "master")} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">13</th>
                                                    <td>File Manajer</td>
                                                    <td>
                                                        <input className="form-check-Input" type="checkbox" value="Y" checked={(this.state.file_manajer.access === "Y") ? true : false} onChange={(e) => this.changeStatus(e, "file_manajer", "file-manajer")} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">14</th>
                                                    <td>Headline</td>
                                                    <td>
                                                        <input className="form-check-Input" type="checkbox" value="Y" checked={(this.state.headline.access === "Y") ? true : false} onChange={(e) => this.changeStatus(e, "headline", "headline")} />
                                                    </td>
                                                </tr>
                                                {/* <tr>
                                                    <th scope="row">15</th>
                                                    <td>Hapus Organisasi</td>
                                                    <td>
                                                        <input className="form-check-Input" type="checkbox" value="Y" checked={(this.state.organisasi.delete_organisasi === "Y") ? true : false} onChange={(e) => this.changeStatus(e, "organisasi", "delete_organisasi")} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">16</th>
                                                    <td>Tambah Ebook</td>
                                                    <td>
                                                        <input className="form-check-Input" type="checkbox" value="Y" checked={(this.state.ebook.create_ebook === "Y") ? true : false} onChange={(e) => this.changeStatus(e, "ebook", "create_ebook")} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">17</th>
                                                    <td>Lihat Ebook</td>
                                                    <td>
                                                        <input className="form-check-Input" type="checkbox" value="Y" checked={(this.state.ebook.read_ebook === "Y") ? true : false} onChange={(e) => this.changeStatus(e, "ebook", "read_ebook")} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">18</th>
                                                    <td>Ubah Ebook</td>
                                                    <td>
                                                        <input className="form-check-Input" type="checkbox" value="Y" checked={(this.state.ebook.update_ebook === "Y") ? true : false} onChange={(e) => this.changeStatus(e, "ebook", "update_ebook")} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">19</th>
                                                    <td>Hapus Ebook</td>
                                                    <td>
                                                        <input className="form-check-Input" type="checkbox" value="Y" checked={(this.state.ebook.delete_ebook === "Y") ? true : false} onChange={(e) => this.changeStatus(e, "ebook", "delete_ebook")} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">20</th>
                                                    <td>Moderasi Acara Ilmiah</td>
                                                    <td>
                                                        <input className="form-check-Input" type="checkbox" value="Y" checked={(this.state.acara_ilmiah.update_acara_ilmiah === "Y") ? true : false} onChange={(e) => this.changeStatus(e, "acara_ilmiah", "update_acara_ilmiah")} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">21</th>
                                                    <td>Moderasi Jurnal & Tata Laksana</td>
                                                    <td>
                                                        <input className="form-check-Input" type="checkbox" value="Y" checked={(this.state.jurnal.update_jurnal === "Y") ? true : false} onChange={(e) => this.changeStatus(e, "jurnal", "update_jurnal")} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">22</th>
                                                    <td>Tambah Webinar</td>
                                                    <td>
                                                        <input className="form-check-Input" type="checkbox" value="Y" checked={(this.state.webinar.create_webinar === "Y") ? true : false} onChange={(e) => this.changeStatus(e, "ebook", "create_webinar")} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">23</th>
                                                    <td>Lihat Webinar</td>
                                                    <td>
                                                        <input className="form-check-Input" type="checkbox" value="Y" checked={(this.state.webinar.read_webinar === "Y") ? true : false} onChange={(e) => this.changeStatus(e, "ebook", "read_webinar")} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">24</th>
                                                    <td>Ubah Webinar</td>
                                                    <td>
                                                        <input className="form-check-Input" type="checkbox" value="Y" checked={(this.state.webinar.update_webinar === "Y") ? true : false} onChange={(e) => this.changeStatus(e, "ebook", "update_webinar")} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">25</th>
                                                    <td>Hapus Webinar</td>
                                                    <td>
                                                        <input className="form-check-Input" type="checkbox" value="Y" checked={(this.state.webinar.delete_webinar === "Y") ? true : false} onChange={(e) => this.changeStatus(e, "ebook", "delete_webinar")} />
                                                    </td>
                                                </tr> */}
                                                <div className="row justify-content-end">
                                                    <Col sm={12}>
                                                        <div>
                                                            <Button
                                                                type="submit"
                                                                color="primary"
                                                                className="w-md"
                                                            >
                                                                Save
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </div>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
                {/* {this.state.alert ?
                    <SweetAlert 
                        title="Berhasil Tersimpan" 
                        onConfirm={this.handlecancel}>
                    </SweetAlert> : ''
                } */}
                {this.state.alert ? (
                    <SweetAlert
                        title="Data Berhasil Tersimpan"
                        onConfirm={() => this.handlecancel({ alert: false })}
                    ></SweetAlert>
                ) : null}

            </React.Fragment>
        )
    }
}

export default Create;