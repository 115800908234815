import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardTitle,
    CardText,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Alert
} from 'reactstrap';
import { Link } from "react-router-dom";
import {
    Container,
} from "reactstrap"
import httprequester from '../../lib/httprequester'

import { isEmpty, map, size } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

class Animasi extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            sizePerPage: 10,
            totalPage: [],
            listAnimasi: [],
            listCategory: [],
            listValue: [],
            alert: false,
            openmodal: false,
            // modal: false,
            message: '',
            urlimage: '',
            id: null,
            categoriesAnimasi: '',
            statusAnimasi: 'Published',
            searchAnimasi: '',
            valueAnimasi: '',
            urutkanAnimasi: '',
            namaalert:'',
            alertcopy:false
        }
    }

    componentDidMount() {
        this.getAnimasi();
        this.getCategory();
    }

    handlePageClick = (e) => {
        let a = e
        this.setState({
            page:a
        })

        let body = {
            "spesialisasi": "",
            "search": this.state.searchAnimasi,
            "penyakit": "",
            "status": this.state.statusAnimasi,
            "molekul": "",
            "penulis": "",
            "page": e,
            "limit": 10,
            "urutan": "animasi_title ASC"
        }

        console.log("body =>", body)
        httprequester.postarticle('animasi/getall', body, {
            success: async function (response) {
                let data = []
                let cek = response.data.result.data
                console.log("res => ", cek)
                if (response.data.message == "success") {
                    // console.log("res2 => ", response.data.result.data.dataRows)
                    let pages = []
                    for (let index = 1; index <= response.data.result.lastPage; index++) {
                        pages.push(index)
                    }
                    // if(response.data.result.data.length)
                    response.data.result.data.dataRows.forEach(async (v, key) => {
                        await data.push({
                            animasi_molekul: v[0],
                            created_at: v[1],
                            animasi_penyakit: v[4],
                            created_by: v[5],
                            animasi_content: v[6],
                            animasi_penulis: v[7],
                            animasi_thumbnail: v[8],
                            animasi_status: v[10],
                            animasi_slug: v[11],
                            animasi_spesialis: v[12],
                            animasi_id: v[15],
                            animasi_title: v[16],
                            animasi_video: v[17]
                        })
                    })
                    this.setState({
                        listAnimasi: data,
                        page: response.data.result.page,
                        sizePerPage: response.data.result.perPage,
                        totalPage: pages,
                    })
                }
                // console.log("listAnimasi => ", this.state.listAnimasi)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    getAnimasi = () => {

        let body = {
            "spesialisasi": "",
            "search": this.state.searchAnimasi,
            "penyakit": "",
            "status": this.state.statusAnimasi,
            "molekul": "",
            "penulis": "",
            "page": 1,
            "limit": 10,
            "urutan": "animasi_title ASC"
        }

        console.log("body =>", body)
        httprequester.postarticle('animasi/getall', body, {
            success: async function (response) {
                let data = []
                let cek = response.data.result.data
                console.log("res => ", cek)
                if (response.data.message == "success") {
                    // console.log("res2 => ", response.data.result.data.dataRows)
                    let pages = []
                    for (let index = 1; index <= response.data.result.lastPage; index++) {
                        pages.push(index)
                    }
                    // if(response.data.result.data.length)
                    response.data.result.data.dataRows.forEach(async (v, key) => {
                        await data.push({
                            animasi_molekul: v[0],
                            created_at: v[1],
                            animasi_penyakit: v[4],
                            created_by: v[5],
                            animasi_content: v[6],
                            animasi_penulis: v[7],
                            animasi_thumbnail: v[8],
                            animasi_status: v[10],
                            animasi_slug: v[11],
                            animasi_spesialis: v[12],
                            animasi_id: v[15],
                            animasi_title: v[16],
                            animasi_video: v[17]
                        })
                    })
                    this.setState({
                        listAnimasi: data,
                        page: response.data.result.page,
                        sizePerPage: response.data.result.perPage,
                        totalPage: pages,
                    })
                }
                // console.log("listAnimasi => ", this.state.listAnimasi)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    getCategory = () => {
        httprequester.get('category/getall', {
            success: async function (response) {

                // console.log("resCat => ", response)
                if (response.data.message == "success") {
                    this.setState({
                        listCategory: response.data.detailMessage
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    updatestatusAnimasi = (value) => {
        let body = {
            "animasi_id": value.animasi_id,
            "animasi_status": value.animasi_status === 'Published' ? 'Unpublished' : 'Published'
        }
        console.log("bodyUpdate =>", body)
        httprequester.postarticle('animasi/update', body, {
            success: async function (response) {
                console.log("resUpdate => ", response)
                this.setState({
                    alert: true,
                    message: 'success'
                })
                setTimeout(() => {
                    this.getAnimasi();
                }, 2000);
                
                // if (response.data.message == "success") {
                //     this.setState({
                //         listAnimasi: data
                //     })
                // }
                // console.log("artikel => ", this.state.listAnimasi)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)
        })
    }

    copyurl = (value) => {
        this.setState({
            namaalert: value.animasi_title,
            alertcopy: true
        })
        navigator.clipboard.writeText(value.animasi_id)
        setTimeout(() => {
            this.setState({
                alertcopy: false
            })
        }, 3000);
    }

    deleteAnimasi = (value) => {
        let body = {
            "animasi_id": value.animasi_id,
            "animasi_status": "Deleted"
        }
        console.log("bodyDelete =>", body)
        httprequester.postarticle('animasi/update', body, {
            success: async function (response) {
                console.log("resDelete => ", response)
                this.setState({
                    message: response.data.message,
                    alert: true
                })
                setTimeout(() => {
                    this.getAnimasi();
                }, 2000);
                
                // if (response.data.message == "success") {
                //     this.setState({
                //         listAnimasi: data
                //     })
                // }
                // console.log("artikel => ", this.state.listAnimasi)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)
        })
    }

    render() {
        console.log("pagenya", this.state.page)
        const { page, totalPage } = this.state
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Petunjuk Penggunaan Obat</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Halaman Utama" breadcrumbItem="Petunjuk Penggunaan Obat" />

                        <Row style={{ paddingBottom: 10 }}>
                            <Col xs="12" md="4">
                                <Link to="/animasi-penggunaan-obat/tambah-animasi-penggunaan-obat">
                                    <Button color="danger">
                                        Tambah Petunjuk Penggunaan Obat
                                    </Button>
                                </Link>
                            </Col>
                        </Row>

                        <div>
                            {this.state.alert ? (
                                <SweetAlert success title={this.state.message} onConfirm={() => this.setState({ alert: false })}>
                                </SweetAlert>
                            ) : null}
                            <Row>
                                <Col xs="12" md="9">
                                    {this.state.alertcopy ? <div>
                                        <Alert
                                            color="primary"
                                            isOpen={this.state.alertcopy}
                                        >
                                            sucess copy url {this.state.namaalert}
                                        </Alert>
                                    </div> : ''}
                                    <Row xs="3">
                                        {this.state.listAnimasi.map(function (animasi, index) {
                                            return <Col key={index}>
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle className="text-truncate font-size-15" tag="h5" style={{ maxHeight: '50%' }}>
                                                            {animasi.animasi_title}
                                                        </CardTitle>
                                                        <p className="text-muted mb-4">{animasi.animasi_penulis}</p>
                                                        <CardText>
                                                            {animasi.animasi_spesialis}
                                                        </CardText>
                                                        <CardText>
                                                            {animasi.animasi_penyakit}
                                                        </CardText>
                                                        <CardText>
                                                            {animasi.animasi_molekul}
                                                        </CardText>
                                                        <Row>
                                                            <div className="d-flex flex-wrap gap-3" style={{ paddingBottom: 5, justifyContent: "center", alignItems: "center" }}>
                                                                <div className="btn-group btn-group-lg">
                                                                    {/* <button type="button" className="btn btn-outline-danger" onClick={() => { console.log("btnPreview") }}> <i className="fas fa-eye"></i> </button> */}
                                                                    {/* <Link type="button" className="btn btn-outline-danger" to={`artikel-preview/${animasi.animasi_id}-${animasi.animasi_slug}`} target="_blank"> <i className="fas fa-eye"></i> </Link> */}
                                                                    <Link type="button" className="btn btn-outline-danger" to={`animasi-penggunaan-obat/edit-animasi-penggunaan-obat/${animasi.animasi_id}/${animasi.animasi_status}`}> <i className="fas fa-edit"></i> </Link>
                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => { this.deleteAnimasi(animasi) }}> <i className="fas fa-trash"></i> </button>
                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => { this.copyurl(animasi) }}> <i className="fas fa-copy"></i> </button>
                                                                    {/* to={`webinar/edittimestamp/${webinar.webinar_id}`} */}
                                                                </div>
                                                            </div>

                                                            <div className="d-grid gap-2">
                                                                <Button color="primary" type="button" className="btn-lg" onClick={() => { this.updatestatusAnimasi(animasi) }}> {animasi.animasi_status === 'Published' ? 'Unpublished' : 'Published'} </Button>
                                                            </div>
                                                            {/* <Col>
                                                                <Button
                                                                    color="danger"
                                                                    type="button"
                                                                    onClick={() => { this.updatestatusAnimasi(article) }}
                                                                // onClick={() => {console.log("tah eta => ", article)}}
                                                                >
                                                                    {article.article_status === 'Published' ? 'Unpublished' : 'Published'}
                                                                </Button>
                                                            </Col>
                                                            <Col>
                                                                <Button
                                                                    color="danger"
                                                                    type="button"
                                                                    onClick={() => { this.deleteArtikel(article) }}
                                                                // onClick={() => {console.log("tah eta => ", article)}}
                                                                >
                                                                    <i className="fas fa-eye"></i>
                                                                </Button>
                                                            </Col>
                                                            <Col>
                                                                <Button
                                                                    color="danger"
                                                                    type="button"
                                                                    onClick={() => { this.deleteArtikel(article) }}
                                                                // onClick={() => {console.log("tah eta => ", article)}}
                                                                >
                                                                    <i className="fas fa-trash"></i>
                                                                </Button>
                                                            </Col> */}
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        }.bind(this))}
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <Pagination className="pagination pagination-rounded justify-content-end mb-2">
                                                {/* <PaginationItem disabled={this.state.page === 1}>
                                                    <PaginationLink
                                                        previous
                                                        // href="#"
                                                        onClick={() => this.handlePageClick(this.state.page - 1)}
                                                    />
                                                </PaginationItem> */}
                                                {this.state.totalPage.map((v, key) => {
                                                    return <PaginationItem active={this.state.page === v} key={key}>
                                                        <PaginationLink
                                                            onClick={() => this.handlePageClick(v)}
                                                        >
                                                            {v}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                })}
                                                {/* <PaginationItem disabled={this.state.page === this.state.totalPage}>
                                                    <PaginationLink
                                                        next
                                                        // href="#"
                                                        onClick={() => this.handlePageClick(this.state.page + 1)}
                                                    />
                                                </PaginationItem> */}
                                            </Pagination>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xl={3} lg={4}>
                                    <Card>
                                        <CardBody className="p-4">
                                            <div>
                                                <p className="text-muted">Animasi Penggunaan Obat Status</p>

                                                <Input
                                                    // id="focusAfterClose"
                                                    onChange={(e) => this.setState({ statusAnimasi: e.target.value })}
                                                    // onChange= {(e) => console.log(e.target.value) }
                                                    type="select"
                                                >
                                                    <option value="Published">
                                                        Published
                                                    </option>
                                                    <option value="Unpublished">
                                                        Unpublished
                                                    </option>
                                                </Input>

                                            </div>

                                            <hr className="my-4" />

                                            {/* <div>
                                                <p className="text-muted">Urutkan</p>

                                                <Input
                                                    type="select"
                                                    name="select"
                                                    id="select"
                                                    onChange={(e) => this.setState({ urutkanAnimasi: e.target.value })}
                                                    value={this.urutkanAnimasi}
                                                >
                                                    <option value="">Urutkan berdasarkan</option>
                                                    <option value="ASC">
                                                        ASC
                                                    </option>
                                                    <option value="DESC">
                                                        DESC
                                                    </option>
                                                </Input>
                                            </div>

                                            <hr className="my-4" /> */}

                                            <div className="search-box">
                                                <p className="text-muted">Cari</p>
                                                <div className="position-relative">
                                                    <input
                                                        type="search"
                                                        className="form-control rounded bg-light border-light"
                                                        placeholder="Cari..."
                                                        onChange={(e) => this.setState({ searchAnimasi: e.target.value })}
                                                        value={this.state.searchAnimasi}
                                                    />
                                                    <i className="mdi mdi-magnify search-icon"></i>
                                                </div>
                                            </div>

                                            <hr className="my-4" />

                                            {/* <div>
                                                <p className="text-muted">Kategori</p>

                                                <Input
                                                    type="select"
                                                    name="select"
                                                    id="select"
                                                    onChange={(e) => this.setState({ categoriesArticle: e.target.value })}
                                                    value={this.categoriesArticle}
                                                >
                                                    <option value="">Pilih Kategori</option>
                                                    <option value="Kategori">
                                                        Kategori
                                                    </option>
                                                    <option value="Spesialis">
                                                        Spesialis
                                                    </option>
                                                    <option value="Molekul">
                                                        Molekul
                                                    </option>
                                                    <option value="Penyakit">
                                                        Penyakit
                                                    </option>
                                                    <option value="Penulis">
                                                        Penulis
                                                    </option>
                                                    {this.state.listCategory ? this.state.listCategory.map((cat) => (
                                                        <option key={cat.category_id} value={cat.category_name}>{cat.category_name}</option>
                                                    )) : null}
                                                </Input>
                                            </div>

                                            <hr className="my-4" /> */}

                                            {/* <div>
                                                <p className="text-muted">List {this.state.categoriesArticle}</p>

                                                <Input
                                                    type="select"
                                                    name="select"
                                                    id="select"
                                                    onChange={(e) => this.setState({ c: e.target.value })}
                                                    value={this.valueAnimasi}
                                                >
                                                    <option value="">Pilih {this.state.categoriesArticle}</option>
                                                    {this.state.listValue ? this.state.listCategory.map((cat) => (
                                                        <option key={cat.category_id} value={cat.category_name}>{cat.category_name}</option>
                                                    )) : null}
                                                </Input>
                                            </div>

                                            <hr className="my-4" /> */}

                                            <Button color="danger" onClick={() => this.getAnimasi()}>
                                                Cari
                                            </Button>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default Animasi;
