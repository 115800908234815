import React, { Component, createRef } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    FormGroup,
    Input,
    Label,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardGroup,
    CardImg,
    CardTitle,
    CardSubtitle,
    CardText,
    Form,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import {
    Container,
} from "reactstrap"
import httprequester from '../../lib/httprequester'
import { Typeahead } from 'react-bootstrap-typeahead';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { AvForm, AvField } from 'availity-reactstrap-validation';

import { Editor } from '@tinymce/tinymce-react';

class EditEbook extends Component {
    constructor(props) {
        super(props);
        this.fileUploads = createRef();
        this.state = {
            basic: false,
            listCategory: [],
            listSpesialis: [],
            listMolekul: [],
            listPenyakit: [],
            listPenulis: [],
            ebookJudul: '',
            ebookPenulis: [],
            ebookPenyakit: [],
            ebookMolekul: [],
            ebookSpesialis: [],
            ebookKonten: '',
            ebookGambar: '',
            file: null,
            penulis: '',
            chspepe: false,
            spesialisasi: '',
            chspe: false,
            molekul: '',
            chspem: false,
            penyakit: '',
            chspep: false,
            upload: '',
            chspeu: false,
            deskripsiedit: '',
            chsped: false,
            ebook_id: ''
        };
    }

    componentDidMount() {
        this.getCategory();
        this.getLOV();
        this.getEbook()
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    back = () => {
        this.props.history.push(`/ebook`)
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState: editorState,
            articleKonten: editorState.getCurrentContent().getPlainText()
        });
    };

    clearKonten = () => {
        this.setState({
            basic: false,
            editorState: EditorState.createEmpty(),
            ebookJudul: '',
            ebookPenulis: [],
            ebookKonten: '',
            ebookGambar: '',
            ebookSpesialis: [],
            ebookMolekul: [],
            ebookPenyakit: []
        });
    }

    getCategory = () => {
        httprequester.get('category/getall', {
            success: async function (response) {

                console.log("resCat => ", response)
                if (response.data.message == "success") {
                    this.setState({
                        listCategory: response.data.detailMessage
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    getEbook = () => {
        let router = window.location.href
        let routerSplitter = router.split("/");

        let body = {
            "ebook_id": routerSplitter[5]
        }

        // console.log("body =>", body)
        httprequester.postarticle('ebook/detail', body, {
            success: async function (response) {
                let data = []
                // let cek = response.data.result.data
                // console.log("res => ", cek)
                if (response.data.message == "success") {
                    // console.log("res2 => ", response.data.result.data.dataRows)

                    // if(response.data.result.data.length)
                    response.data.settings.datarows.forEach(async (v, key) => {
                        await data.push({
                            ebook_status: v[0],
                            ebook_tags: v[1],
                            ebook_molekul: v[2],
                            created_at: v[3],
                            created_by: v[4],
                            ebook_spesialis: v[5],
                            ebook_thumbnail: v[6],
                            event_spesialis: v[5],
                            ebook_penyakit: v[9],
                            ebook_slug: v[10],
                            ebook_title: v[11],
                            ebook_category: v[12],
                            ebook_content: v[13],
                            ebook_id: v[14],
                            ebook_penulis: v[15]
                        })
                    })
                    let edit = data.find(o => o.ebook_id == routerSplitter[5])

                    this.setState({
                        ebookJudul: edit.ebook_title,
                        penulis: edit.ebook_penulis,
                        jurnalGambar: edit.ebook_thumbnail,
                        spesialisasi: edit.ebook_spesialis,
                        molekul: edit.ebook_molekul,
                        penyakit: edit.ebook_penyakit,
                        upload: edit.ebook_thumbnail,
                        deskripsiedit: edit.ebook_content,
                        ebook_id: edit.ebook_id
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    getLOV = () => {
        httprequester.get('artikel/lov', {
            success: async function (response) {

                console.log("resLOV => ", response)
                if (response.data.message == "success") {
                    let datapenyakit = []
                    response.data.detailPenyakit.forEach((v, key) => {
                        // console.log('v',v);
                        datapenyakit.push({
                            penyakit_id: v.penyakit_id,
                            penyakit_name: v.penyakit_name == null ? '-' : v.penyakit_name
                        })
                    })
                    this.setState({
                        listSpesialis: response.data.detailSpecialist,
                        listMolekul: response.data.detailMolekul,
                        listPenyakit: datapenyakit,
                        listPenulis: response.data.detailPenulis
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    saveArticle = () => {
        let slugLower = this.state.articleJudul.toLowerCase();
        let slugRep = slugLower.replace(/\s/g, '-');

        let body = {
            "article_title": this.state.articleJudul,
            "article_slug": slugRep,
            "article_thumbnail": this.state.articleGambar,
            "article_penulis": this.state.articlePenulis.toString(),
            "article_penyakit": this.state.articlePenyakit[0],
            "article_molekul": this.state.articleMolekul[0],
            "article_spesialis": this.state.articleSpesialis[0],
            "article_status": "Unpublished",
            "article_content": this.state.articleKonten
        }
        console.log("body =>", body)
        httprequester.postarticle('artikel/save', body, {
            success: async function (response) {
                console.log("res => ", response)
                if (response.data.message == "success") {
                    this.setState({
                        basic: true
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    save = () => {
        let slugLower = this.state.ebookJudul.toLowerCase();
        let slugRep = slugLower.replace(/\s/g, '-');

        let router = window.location.href
        let routerSplitter = router.split("/");
        // console.log('sas', routerSplitter)

        let formData = new FormData();
        if (this.state.file == null) {
            formData.append("ebook_title", this.state.ebookJudul);
            formData.append("ebook_id", routerSplitter[5]);
            formData.append("ebook_penyakit", this.state.ebookPenyakit.toString() ? this.state.ebookPenyakit.toString() : this.state.penyakit);
            formData.append("ebook_spesialis", this.state.ebookSpesialis.toString() ? this.state.ebookSpesialis.toString() : this.state.spesialisasi);
            formData.append("ebook_content", this.state.ebookKonten ? this.state.ebookKonten : this.state.deskripsiedit);
            formData.append("ebook_molekul", this.state.ebookMolekul.toString() ? this.state.ebookMolekul.toString() : this.state.molekul);
            formData.append("ebook_slug", slugRep);
            formData.append("ebook_penulis", this.state.ebookPenulis.toString() ? this.state.ebookPenulis.toString() : this.state.penulis);
            formData.append("ebook_status", routerSplitter[6])

            console.log("formData => ", formData);

            httprequester.postupload('ebook/update', formData, {
                success: async function (response) {
                    if (response.data.message == "success") {
                        this.setState({
                            basic: true
                        })
                        this.fileUploads.current
                        // e.target.value = null;
                        // this.fileUploads.current.value
                        // this.getFileUpload()
                    }
                    console.log('ini', this.fileUploads)
                }.bind(this),
                error: function (err) {

                }.bind(this)

            })
        } else {
            formData.append("myfile", this.state.file);
            formData.append("ebook_title", this.state.ebookJudul);
            formData.append("ebook_id", routerSplitter[5]);
            formData.append("ebook_penyakit", this.state.ebookPenyakit.toString() ? this.state.ebookPenyakit.toString() : this.state.penyakit);
            formData.append("ebook_spesialis", this.state.ebookSpesialis.toString() ? this.state.ebookSpesialis.toString() : this.state.spesialisasi);
            formData.append("ebook_content", this.state.ebookKonten ? this.state.ebookKonten : this.state.deskripsiedit);
            formData.append("ebook_molekul", this.state.ebookMolekul.toString() ? this.state.ebookMolekul.toString() : this.state.molekul);
            formData.append("ebook_slug", slugRep);
            formData.append("ebook_penulis", this.state.ebookPenulis.toString() ? this.state.ebookPenulis.toString() : this.state.penulis);
            formData.append("ebook_status", routerSplitter[6])

            console.log("formData => ", formData);

            httprequester.postupload('ebook/update', formData, {
                success: async function (response) {
                    if (response.data.message == "success") {
                        this.setState({
                            basic: true
                        })
                        this.fileUploads.current
                        // e.target.value = null;
                        // this.fileUploads.current.value
                        // this.getFileUpload()
                    }
                    console.log('ini', this.fileUploads)
                }.bind(this),
                error: function (err) {

                }.bind(this)

            })
        }

    }

    handleChangePenulis(e) {
        this.setState({
            ebookPenulis: e
        })
        console.log("INIT N", e);
    }

    handleChangeSpesialis(e) {
        this.setState({
            ebookSpesialis: e
        })
        console.log("INIT S", e);
    }

    handleChangeMolekul(e) {
        this.setState({
            ebookMolekul: e
        })
        console.log("INIT M", e);
    }

    handleChangePenyakit(e) {
        this.setState({
            ebookPenyakit: e
        })
        console.log("INIT P", e);
    }

    handleEditorChange = (e) => {
        console.log(
            'Content was updated:',
            e.target.getContent()
        );
        this.setState({
            ebookKonten: e.target.getContent()
        })
    }

    handlupload = (e) => {
        this.setState({
            file: e.target.files[0]
        })
    }

    render() {
        const option = this.state.listSpesialis.map(o => o.specialist_name)
        const optionMolekul = this.state.listMolekul.map(o => o.molekul_name)
        const optionPenyakit = this.state.listPenyakit.map(o => o.penyakit_name)
        const optionPenulis = this.state.listPenulis.map(o => o.penulis_name)
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Tambah Ebook</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Ebook" breadcrumbItem="Tambah Ebook" />

                        {this.state.basic ? (
                            <SweetAlert
                                success
                                title="Ebook berhasil di simpan"
                                onConfirm={() => this.back()}
                            ></SweetAlert>
                        ) : null}

                        <AvForm>
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardBody>
                                            <Form>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">Judul Ebook</Label>
                                                    <Input
                                                        id="ebookJudul"
                                                        type="input"
                                                        placeholder="Input Judul Ebook"
                                                        onChange={(e) => this.setState({ ebookJudul: e.target.value })}
                                                        value={this.state.ebookJudul}
                                                    />
                                                </div>

                                                <Row>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Label for="exampleSpesialisasi">
                                                                    Penulis
                                                                </Label>
                                                                <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ chspepe: !this.state.chspepe })} >ubah</a>
                                                            </div>
                                                            {/* <Label htmlFor="formrow-firstname-Input">Penulis</Label> */}
                                                            {(!this.state.chspepe) ?
                                                                <AvField
                                                                    name="judul"
                                                                    disabled
                                                                    type="text"
                                                                    value={this.state.penulis} required />
                                                                : <Typeahead
                                                                    // allowNew
                                                                    id="basic-typeahead-multiple"
                                                                    multiple
                                                                    placeholder="Pilih Penulis"
                                                                    labelKey="name"
                                                                    options={optionPenulis}
                                                                    onChange={(e) => this.handleChangePenulis(e)}
                                                                    selected={this.state.ebookPenulis}
                                                                />

                                                            }

                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Label for="exampleSpesialisasi">
                                                                    File Upload
                                                                </Label>
                                                                <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ chspeu: !this.state.chspeu })} >ubah</a>
                                                            </div>
                                                            {/* <Label htmlFor="formrow-InputZip">Penyakit</Label> */}
                                                            {(!this.state.chspeu) ?
                                                                <AvField
                                                                    name="judul"
                                                                    disabled
                                                                    type="text"
                                                                    value={this.state.upload} required />
                                                                : <Input
                                                                    id="exampleFile"
                                                                    name="file"
                                                                    type="file"
                                                                    accept=".png,.jpg,.jpeg,.pdf"
                                                                    ref={this.fileUploads}
                                                                    onChange={this.handlupload}
                                                                />
                                                            }
                                                        </div>
                                                    </Col>
                                                    {/* <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-email-Input">URL Gambar Thumbnail Ebook</Label>
                                                        <Input
                                                            id="ebookThumbnail"
                                                            type="input"
                                                            placeholder="Input Link Thumbnail"
                                                            onChange={(e) => this.setState({ ebookGambar: e.target.value })}
                                                            value={this.state.ebookGambar}
                                                        />
                                                    </div>
                                                </Col> */}
                                                </Row>

                                                <Row>
                                                    <Col lg={4}>
                                                        <div className="mb-3">
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Label for="exampleSpesialisasi">
                                                                    Spesialisasi
                                                                </Label>
                                                                <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ chspe: !this.state.chspe })} >ubah</a>
                                                            </div>
                                                            {/* <Label htmlFor="formrow-InputCity">Spesialis</Label> */}
                                                            {(!this.state.chspe) ?
                                                                <AvField
                                                                    name="judul"
                                                                    disabled
                                                                    type="text"
                                                                    value={this.state.spesialisasi} required />
                                                                : <Typeahead
                                                                    id="basic-typeahead-multiple"
                                                                    multiple
                                                                    placeholder="Pilih Spesialis"
                                                                    labelKey="name"
                                                                    options={option}
                                                                    onChange={(e) => this.handleChangeSpesialis(e)}
                                                                    selected={this.state.ebookSpesialis}
                                                                />
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="mb-3">
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Label for="exampleSpesialisasi">
                                                                    Molekul
                                                                </Label>
                                                                <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ chspem: !this.state.chspem })} >ubah</a>
                                                            </div>
                                                            {/* <Label htmlFor="formrow-InputState">Molekul</Label> */}
                                                            {(!this.state.chspem) ?
                                                                <AvField
                                                                    name="judul"
                                                                    disabled
                                                                    type="text"
                                                                    value={this.state.molekul} required />
                                                                : <Typeahead
                                                                    id="basic-typeahead-multiple"
                                                                    multiple
                                                                    placeholder="Pilih Molekul"
                                                                    labelKey="name"
                                                                    options={optionMolekul}
                                                                    onChange={(e) => this.handleChangeMolekul(e)}
                                                                    selected={this.state.ebookMolekul}
                                                                />
                                                            }
                                                        </div>
                                                    </Col>

                                                    <Col lg={4}>
                                                        <div className="mb-3">
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Label for="exampleSpesialisasi">
                                                                    Penyakit
                                                                </Label>
                                                                <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ chspep: !this.state.chspep })} >ubah</a>
                                                            </div>
                                                            {/* <Label htmlFor="formrow-InputZip">Penyakit</Label> */}
                                                            {(!this.state.chspep) ?
                                                                <AvField
                                                                    name="judul"
                                                                    disabled
                                                                    type="text"
                                                                    value={this.state.penyakit} required />
                                                                : <Typeahead
                                                                    id="basic-typeahead-multiple"
                                                                    multiple
                                                                    placeholder="Pilih Penyakit"
                                                                    labelKey="name"
                                                                    options={optionPenyakit}
                                                                    onChange={(e) => this.handleChangePenyakit(e)}
                                                                    selected={this.state.ebookPenyaki}
                                                                />
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>

                                                {/* <Row>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-email-Input">File Upload</Label>
                                                        <br />
                                                        <Input
                                                            id="exampleFile"
                                                            name="file"
                                                            type="file"
                                                            accept=".png,.jpg,.jpeg"
                                                            ref={this.fileUploads}
                                                            onChange={this.handlupload}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row> */}
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </AvForm>

                        <AvForm>
                            <Row style={{ paddingTop: 10 }}>
                                <Col xs="12" md="12">
                                    <Card>
                                        <CardBody>
                                            <Form method="post">
                                                <Editor
                                                    apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                                                    initialValue={this.state.deskripsiedit}
                                                    content={this.state.ebookKonten}
                                                    init={{
                                                        height: 500,
                                                        menubar: false,
                                                        plugins: [
                                                            'print preview',
                                                            'importcss searchreplace autolink autosave save',
                                                            'directionality  visualblocks visualchars fullscreen image link media',
                                                            'template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists',
                                                            'wordcount imagetools textpattern noneditable help',
                                                            'charmap quickbars emoticons',

                                                        ],
                                                        toolbar:
                                                            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | image | help',
                                                        // images_upload_handler: function (blobInfo, success, failure) {
                                                        //     let data = new FormData();
                                                        //     data.append('myfile', blobInfo.blob(), blobInfo.filename());
                                                        //     console.log(data)
                                                        //     axios.post('http://127.0.0.1:3333/api/v1/filemanager/uploadtest', data)
                                                        //         .then(function (res) {
                                                        //             success(res.data.detailMessage);
                                                        //         })
                                                        //         .catch(function (err) {
                                                        //             console.log(err)
                                                        //             failure('HTTP Error: ' + err.message);
                                                        //         });
                                                        // }
                                                    }}
                                                    onChange={this.handleEditorChange}
                                                />
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </AvForm>

                        <Row style={{ paddingTop: 10 }}>
                            <Col xs="12" md="12">
                                <Card>
                                    <Button type="submit" className="btn btn-primary w-md" onClick={() => this.save()}>
                                        Submit Ebook
                                    </Button>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

export default EditEbook;
