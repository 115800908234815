import React, { Component } from "react"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
// Modal, ModalHeader, ModalBody, ModalFooter
import MetaTags from 'react-meta-tags'
import Axios from 'axios'
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { urlAPI } from '../../config';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

import httprequester from '../../lib/httprequester'

class ListAdmin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            sizePerPage: 10,
            listAdmin: [],
            alert: false,
            openmodal: false,
            // modal: false,
            message: '',
            urlimage: '',
            id: null,
            confirm_both: false,
        }
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    getListAdmin = () => {
        httprequester.get('admin/getall', {
            success: async function (response) {
                if (response.data.message == "success") {
                    this.setState({
                        listAdmin: response.data.detailMessage
                    })
                }
                // console.log(listAdmin)
            }.bind(this),
            error: function (err) {
            }.bind(this)
        })
    }

    edit = (admin_id) => {
        // console.log('ID yang akan di Edit', admin_id)
        this.props.history.push(`edit-admin/${admin_id}`)
    }

    delete = (admin_id) => {
        // console.log('id', this.state.id)
        let body = {
            // "certificate_id": id,
            // "action": "Reject"
            admin_id: admin_id,
            // admin_name: admin_name,
            admin_status: 'D'
        }

        httprequester.postnobody('admin/update', body, {
            success: async function (response) {
                if (response.data.message == "success") {
                    this.setState({
                        message: response.data.detailMessage,
                        alert: true
                    })
                    this.getListAdmin()
                }
                console.log(response)
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    // async delete(admin_id, admin_name) {
    //     // delete = (e, admin_id) => {
    //     console.log('ID yang akan di Hapus', admin_id)
    //     let data = {
    //         admin_id: admin_id,
    //         admin_name: admin_name,
    //         admin_status: 'D'
    //     }
    //     // e.preventDefault();

    //     var config = {
    //         method: 'post',
    //         url: 'http://192.168.3.117:30631/api/v1/admin/update',
    //         headers: {
    //             'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImFkbWluX2lkIjozLCJhZG1pbl9uYW1lIjoic3VwZXIiLCJhZG1pbl9wcmV2aWxlZ2UiOlt7Im5hbWUiOiJhcnRpY2xlIiwicmVhZCI6IlkiLCJjcmVhdGUiOiJZIiwiZGVsZXRlIjoiWSIsInVwZGF0ZSI6IlkifSx7Im5hbWUiOiJjYXRlZ29yeSIsInJlYWQiOiJZIiwiY3JlYXRlIjoiWSIsImRlbGV0ZSI6IlkiLCJ1cGRhdGUiOiJZIn0seyJuYW1lIjoidGFncyIsInJlYWQiOiJZIiwiY3JlYXRlIjoiWSIsImRlbGV0ZSI6IlkiLCJ1cGRhdGUiOiJZIn0seyJuYW1lIjoiZmlsZS1tYW5hZ2VyIiwicmVhZCI6IlkiLCJjcmVhdGUiOiJZIiwiZGVsZXRlIjoiWSIsInVwZGF0ZSI6IlkifSx7Im5hbWUiOiJ1cGxvYWQtZmlsZSIsInJlYWQiOiJZIiwiY3JlYXRlIjoiWSIsImRlbGV0ZSI6IlkifSx7Im5hbWUiOiJhZG1pbiIsInJlYWQiOiJZIiwiY3JlYXRlIjoiWSIsImRlbGV0ZSI6IlkiLCJ1cGRhdGUiOiJZIn1dfSwiaWF0IjoxNjM5NDk0MTQ3LCJleHAiOjE2NzEwMzAxNDd9._K5ZPyIVQgZZlZ9-ezBPkxeZsiFxERLlMVDDOz5Sx70',
    //             'Content-Type': 'application/json',
    //         },
    //         data: data
    //     };

    //     await Axios(config)
    //         // Axios(config)
    //         .then(function (response) {
    //             console.log(JSON.stringify(response.data));
    //         })
    //     // this.setState({
    //     //     message: "Submit Success",
    //     //     alert: true
    //     // })
    //     this.props.history.push('/dashboard')

    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // }

    // delete = (admin_id) => {
    //     var config = {
    //         method: 'post',
    //         url: 'http://192.168.3.117:30631/api/v1/admin/update',
    //         headers: {
    //             'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImFkbWluX2lkIjozLCJhZG1pbl9uYW1lIjoic3VwZXIiLCJhZG1pbl9wcmV2aWxlZ2UiOlt7Im5hbWUiOiJhcnRpY2xlIiwicmVhZCI6IlkiLCJjcmVhdGUiOiJZIiwiZGVsZXRlIjoiWSIsInVwZGF0ZSI6IlkifSx7Im5hbWUiOiJjYXRlZ29yeSIsInJlYWQiOiJZIiwiY3JlYXRlIjoiWSIsImRlbGV0ZSI6IlkiLCJ1cGRhdGUiOiJZIn0seyJuYW1lIjoidGFncyIsInJlYWQiOiJZIiwiY3JlYXRlIjoiWSIsImRlbGV0ZSI6IlkiLCJ1cGRhdGUiOiJZIn0seyJuYW1lIjoiZmlsZS1tYW5hZ2VyIiwicmVhZCI6IlkiLCJjcmVhdGUiOiJZIiwiZGVsZXRlIjoiWSIsInVwZGF0ZSI6IlkifSx7Im5hbWUiOiJ1cGxvYWQtZmlsZSIsInJlYWQiOiJZIiwiY3JlYXRlIjoiWSIsImRlbGV0ZSI6IlkifSx7Im5hbWUiOiJhZG1pbiIsInJlYWQiOiJZIiwiY3JlYXRlIjoiWSIsImRlbGV0ZSI6IlkiLCJ1cGRhdGUiOiJZIn1dfSwiaWF0IjoxNjM5NDk0MTQ3LCJleHAiOjE2NzEwMzAxNDd9._K5ZPyIVQgZZlZ9-ezBPkxeZsiFxERLlMVDDOz5Sx70',
    //             'Content-Type': 'application/json',
    //         },
    //         data: data
    //     };

    //     await Axios(config)
    //         .then(function (response) {
    //             console.log(JSON.stringify(response.data));
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });

    // }

    // Open = (url) => {
    //     this.setState({
    //         openmodal: true,
    //         modal: true,
    //         urlimage: url
    //     })
    // }

    componentDidMount() {
        this.getListAdmin()
    }

    render() {

        const columns = [{
            dataField: 'admin_name',
            text: 'Admin Username',
            sort: true
        }, {
            dataField: 'admin_id',
            text: 'Action',
            sort: true,
            formatter: (row) => {
                // console.log('bener', row)
                return <div>
                    <Button
                        color="success"
                        className="btn-sm"
                        onClick={(e) => { this.edit(row) }}>
                        Edit
                    </Button>
                    &nbsp;
                    <Button
                        color="danger"
                        className="btn-sm"
                        onClick={(e) => this.delete(row)}>
                        Delete
                    </Button>

                    {/* <Button
                        color="danger"
                        className="btn-sm"
                        onClick={() => this.state({ confirm_both: true })}>
                        Delete
                    </Button> */}
                </div>
            }
        }];

        const defaultSorted = [{
            dataField: 'certificate_name',
            order: 'asc'
        }];

        const pageOptions = {
            sizePerPage: 10,
            totalSize: this.state.listAdmin.length, // replace later with size(customers),
            custom: true,
        }

        // Custom Pagination Toggle
        const sizePerPageList = [
            { text: '5', value: 5 },
            { text: '10', value: 10 },
            { text: '15', value: 15 },
            { text: '20', value: 20 },
            { text: '25', value: 25 },
            { text: 'All', value: (this.state.listAdmin).length }];

        const { SearchBar } = Search;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Data Tables | Skote - React Admin & Dashboard Template</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title="Admin" breadcrumbItem="List Admin" />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md="10"></Col>
                                            <Col md="2">
                                                <Link to="/create-admin">
                                                    <Button color="btn-sm btn btn-primary">
                                                        Tambah Admin
                                                    </Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField='admin_name'
                                            columns={columns}
                                            data={this.state.listAdmin}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField='admin_name'
                                                    columns={columns}
                                                    data={this.state.listAdmin}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col md="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={"certificate_name"}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            defaultSorted={defaultSorted}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )
                                                    }
                                                </ToolkitProvider>
                                            )
                                            }</PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {this.state.confirm_both ? (
                            <SweetAlert
                                title="Apakah anda yakin akan menghapus data ini ?"
                                warning
                                showCancel
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="danger"
                                onConfirm={() =>
                                    this.delete({
                                        confirm_both: false,
                                        success_dlg: true,
                                        dynamic_title: "Deleted",
                                        dynamic_description: "Data berhasil di hapus",
                                    })
                                }
                                onCancel={() =>
                                    this.state({
                                        confirm_both: false,
                                        error_dlg: true,
                                        dynamic_title: "Cancelled",
                                        dynamic_description:
                                            "Data tidak berhasil di hapus",
                                    })
                                }
                            >
                            </SweetAlert>
                        ) : null}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ListAdmin