import React, { Component } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Table,
    Input,
    Label,
    Form
} from "reactstrap";
// Modal, ModalHeader, ModalBody, ModalFooter
import MetaTags from "react-meta-tags";
import moment from "moment";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import { Editor } from '@tinymce/tinymce-react';

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import SweetAlert from "react-bootstrap-sweetalert";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// import ReactHtmlParser from 'react-html-parser';

import httprequester from "../../lib/httprequester";

class ListHubungiAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            sizePerPage: 10,
            listVerifikasiNPA: [],
            alert: false,
            message: "",
            card_url: "",
            id: null,
            confirm_both: false,
            popupapprove: false,
            loading: false,
            card_status: {},
            popupreject: false,
            popupkartunpa: false,
            npa_no: '',
            npa_name: '',
            npa_id: 0,
            reason: '',
            prosess:false,
            tanya_summary:'',
            statusHubungiAdmin:''
        };
    }

    componentDidMount() {
        this.getListVerifikasiNPA('waiting');
    }

    // static propTypes = {
    //     history: PropTypes.object.isRequired
    // }

    getListVerifikasiNPA = (status) => {
        let body = {
            status:status
        };
        
        // console.log("isi")
        httprequester.postnobody("user/hubungi-admin/get", body, {
            success: async function (response) {
                let data = [];
                if (response.data.message == "success") {
                    // console.log("response", response.data.detailMessage)

                    response.data.detailMessage.forEach(async (v, key) => {
                        await data.push({
                            id_hub: v.id_hub,
                            nama_hub: v.nama_hub,
                            email_hub: v.email_hub,
                            npa_name: v.npa_name,
                            npa_id: v.npa_id,
                            status_hub: v.status_hub,
                            phone_hub: v.phone_hub,
                            created_at: v.created_at,
                            updated_at: v.updated_at,
                            remarks_hub: v.remarks_hub
                            // reason: "",
                        });
                    });
                    // console.log("isi2", data)

                    // console.log('isi', this.state.listVerifikasiNPA)
                }
                this.setState({
                    listVerifikasiNPA: data,
                });
            }.bind(this),
            error: function (err) { }.bind(this),
        });
    };

    handleselect = e => {
        this.getListVerifikasiNPA(e.target.value);
        this.setState({
            statusHubungiAdmin:e.target.value
        })
    };


    popUpGenerateKartuNPA =(row) => {
        let body = {
            status: 'progress',
            remarks: '',
            id_hub: row.id_hub
        };
        // console.log("isi")
        httprequester.postnobody("user/hubungi-admin/updated", body, {
            success: async function (response) {
                // let data = [];
                if (response.data.message == "success") {
                    this.back()
                    this.setState({
                        basicPilih: true,
                        prosess: false,
                        message: response.data.detailMessage,
                    });
                    this.getListVerifikasiNPA('waiting')
                }
            }.bind(this),
            error: function (err) { }.bind(this),
        });
    };

    popUpGenerateKartuNPADone = (row) => {
        this.setState({
            prosess:true,
            tanya_summary: row.remarks_hub,
            id: row.id_hub
        })
    };

    updatehubungiadmin = () => {
        let body = {
            status: 'done',
            remarks: this.state.tanya_summary,
            id_hub: this.state.id
        };
        // console.log("isi")
        httprequester.postnobody("user/hubungi-admin/updated", body, {
            success: async function (response) {
                // let data = [];
                if (response.data.message == "success") {
                    this.back()
                    this.setState({
                        basicPilih: true,
                        prosess: false,
                        message: response.data.detailMessage,
                    });
                    this.getListVerifikasiNPA('done')
                }
            }.bind(this),
            error: function (err) { }.bind(this),
        });
    }

    back = () => {
        this.setState({
            basicPilih: false,
            prosess: false,
            formmodalstatus: false
        })
      
    }

    popUpGenerateReject = row => {
        let check = this.state.listVerifikasiNPA.find(k => k.card_id == row);
        this.setState({
            popupreject: true,
            card_status: check.card_status,
            card_id: row,
            npa_name: check.npa_name,
            npa_id: check.npa_id,
            npa_no: check.npa_no
        });
        // console.log("isipopup", check)
    };

    popUpGenerateApprove = row => {
        let check = this.state.listVerifikasiNPA.find(k => k.card_id == row);
        // console.log("Isi", check)
        this.setState({
            popupapprove: true,
            card_status: check.card_status,
            card_id: row,
            npa_name: check.npa_name,
            npa_id: check.npa_id,
            npa_no: check.npa_no
        });
    };


    generateReject = () => {
        this.setState({
            popupreject: false,
            loading: true,
        });

        this.getListVerifikasiNPA('W');

        let { npa_id, npa_name, npa_no, reason } = this.state;
        let body = {
            card_id: this.state.card_id,
            card_status: this.state.card_status == 'Approve' ? 'R' : this.state.card_status == 'Waiting' ? 'R' : '',
            npa_id,
            npa_name,
            npa_no,
            reason,
        };

        this.setState({
            reason: ''
        })

        // console.log('isi2', body)



        httprequester.postnobody("admin/verification_status_user", body, {
            success: async function (response) {
                //  if (response.data.message == "success") {
                // console.log(response);
                this.setState({
                    message: response.data.detailMessage,
                    loading: false,
                    alert: true,
                });
                // this.getListVerifikasiNPA();
                // }
            }.bind(this),
            error: function (err) { }.bind(this),
        });

    };

    closeApprove = () => {
        let select = document.querySelector('#mySelect');
        select.value = 'W'
        this.setState({
            alert: false,
        })
        this.getListVerifikasiNPA('W');
    }


    generateApprove = () => {
        this.setState({
            popupapprove: false,
            loading: true,
        });

        this.getListVerifikasiNPA('W');

        let { npa_id, npa_name, npa_no } = this.state;
        let body = {
            card_id: this.state.card_id,
            card_status: this.state.card_status == 'Reject' ? 'A' : this.state.card_status == 'Waiting' ? 'A' : '',
            npa_id,
            npa_name,
            npa_no,
        };



        httprequester.postnobody("admin/verification_status_user", body, {
            success: async function (response) {
                // if (response.data.message == "success") {
                this.setState({
                    message: response.data.detailMessage,
                    loading: false,
                    alert: true,
                });
            }.bind(this),
            error: function (err) { }.bind(this),
        });
        //
    };

  

    buttonverifikasi = (row) => {
        let data = this.state.listVerifikasiNPA.find(b => b.id_hub == row);
        // console.log('data',data);
        return (
            <div>
                {data.status_hub == 'waiting' ?
                    <Button
                        color="primary"
                        className="btn-sm"
                    onClick={e => {
                        this.popUpGenerateKartuNPA(data);
                    }}
                    >
                        In Progress 
                    </Button> : data.status_hub == 'progress' ? <Button
                        color="success"
                        className="btn-sm"
                        onClick={e => {
                            this.popUpGenerateKartuNPADone(data);
                        }}
                    >
                        Done
                    </Button> : <Button
                        color="info"
                        className="btn-sm"
                        onClick={e => {
                            this.popUpGenerateKartuNPADone(data);
                        }}
                    >
                        Preview
                    </Button>}
               
                
            </div>
        );
    };

    render() {
        const columns = [
            {
                dataField: "npa_id",
                text: "Nomor NPA",
                sort: true,
            },
            {
                dataField: 'nama_hub',
                text: "Nama User",
                sort: true,
            },
            {
                dataField: 'email_hub',
                text: 'Email',
                sort: true
            },
            {
                dataField: "npa_name",
                text: "Nama NPA",
                sort: true,
            },
            {
                dataField: "status_hub",
                text: "Status",
                sort: true,
            },
            {
                dataField: "created_at",
                text: "Tanggal Hub",
                sort: true,
                formatter: row => {
                    if (row !== "-") {
                        return <div>{moment(row).format("YYYY-MM-DD HH:mm:ss")}</div>;
                    }
                    return <div>{row}</div>;
                },
            },
            {
                dataField: "id_hub",
                text: "Action",
                sort: true,
                formatter: (row) => {
                    return <div>{this.buttonverifikasi(row)}</div>;
                },
            },
        ];

        const defaultSorted = [
            {
                dataField: "id_hub",
                order: "asc",
            },
        ];

        const pageOptions = {
            sizePerPage: 10,
            totalSize: this.state.listVerifikasiNPA.length,
            custom: true,
        };

        const sizePerPageList = [
            { text: "5", value: 5 },
            { text: "10", value: 10 },
            { text: "15", value: 15 },
            { text: "20", value: 20 },
            { text: "25", value: 25 },
            { text: "All", value: this.state.listVerifikasiNPA.length },
        ];

        const { SearchBar } = Search;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Hubungi Admin | Ruang Ilmiah</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs
                            title="Halaman Utama"
                            breadcrumbItem="Hubungi Admin"
                        />
                    </Container>
                    <div className="container-fluid">
                        {this.state.prosess ? (
                            <SweetAlert
                                title={"Status Penyelesaian"}
                                onConfirm={() => this.updatehubungiadmin()}
                                onCancel={() => this.setState({ prosess: false })}
                                showCancel={true}
                                showConfirm={this.state.statusHubungiAdmin == 'done' ? false : true }
                                type={'controlled'}
                                // dependencies={[this.state.npa_name, this.state.npa_no]}
                            >
                                {(renderProps) => (
                                    <form>
                                        <Input
                                            type={'textarea'}
                                            className="form-control"
                                            value={this.state.tanya_summary}
                                            onKeyDown={renderProps.onEnterKeyDownConfirm}
                                            onChange={(e) => this.setState({ tanya_summary: e.target.value })}
                                            // placeholder={'Status Penyelesaian'}
                                            disabled={this.state.statusHubungiAdmin == 'done' ? true: false}
                                        />
                                        <hr />
                                    </form>
                                )}
                            </SweetAlert> 
                        ) : null}

                        {this.state.basicPilih ? (
                            <SweetAlert
                                success
                                title={this.state.message}
                                onConfirm={() => this.back()}
                            ></SweetAlert>
                        ) : null}

                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField="id_hub"
                                            columns={columns}
                                            data={this.state.listVerifikasiNPA}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id_hub"
                                                    columns={columns}
                                                    data={this.state.listVerifikasiNPA}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col md="4">
                                                                    <Input
                                                                        onChange={this.handleselect}
                                                                        type="select"
                                                                        id="mySelect"
                                                                    >
                                                                        <option value="waiting">Waiting</option>
                                                                        <option value="progress">In Progress</option>
                                                                        <option value="done">Done</option>
                                                                    </Input>
                                                                    <br />
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={"id_hub"}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            defaultSorted={defaultSorted}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <br />
                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    {this.state.alert ?
                        <SweetAlert title={this.state.message} onConfirm={this.closeApprove}>
                        </SweetAlert> : ''
                    }
                </div>
            </React.Fragment>
        );
    }
}
export default ListHubungiAdmin;