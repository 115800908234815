import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    Input,
    Label,
    Button,
    Card,
    CardBody,
    Form,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Container } from "reactstrap"
import httprequester from '../../lib/httprequester'
import { Typeahead } from 'react-bootstrap-typeahead';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Editor } from '@tinymce/tinymce-react';

class TambahWebinar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            basic: false,
            listCategory: [],
            listSpesialis: [],
            listMolekul: [],
            listPenyakit: [],
            listPenulis: [],
            webinarJudul: '',
            webinarPenulis: [],
            webinarPenyakit: [],
            webinarMolekul: [],
            webinarSpesialis: [],
            webinarKonten: '',
            webinarGambar: '',
            webinarVideo: '',
            name: '',
            webinarDefaultImageUrl: '',

            webinarShortJudul1: '',
            webinarShortThumbnail1: '',
            webinarShortUrl1: '',
            webinarShortMenit1: '',

            webinarShortJudul2: '',
            webinarShortThumbnail2: '',
            webinarShortUrl2: '',
            webinarShortMenit2: '',
            
            webinarShortJudul3: '',
            webinarShortThumbnail3: '',
            webinarShortUrl3: '',
            webinarShortMenit3: '',

            webinarShortJudul4: '',
            webinarShortThumbnail4: '',
            webinarShortUrl4: '',
            webinarShortMenit4: '',

            webinarShortJudul5: '',
            webinarShortThumbnail5: '',
            webinarShortUrl5: '',
            webinarShortMenit5: '',

            webinarShortJudul6: '',
            webinarShortThumbnail6: '',
            webinarShortUrl6: '',
            webinarShortMenit6: '',

            webinarShortJudul7: '',
            webinarShortThumbnail7: '',
            webinarShortUrl7: '',
            webinarShortMenit7: '',

            webinarShortJudul8: '',
            webinarShortThumbnail8: '',
            webinarShortUrl8: '',
            webinarShortMenit8: '',

            webinarShortJudul9: '',
            webinarShortThumbnail9: '',
            webinarShortUrl9: '',
            webinarShortMenit9: '',

            webinarShortJudul10: '',
            webinarShortThumbnail10: '',
            webinarShortUrl10: '',
            webinarShortMenit10: '',

            durasiVideoFullDtk: '',
        };
    }

    componentDidMount() {
        let x = JSON.parse(localStorage.getItem('user'))
        if (x) {
            this.setState({
                name: x.admin_name
            })
        }
        this.getCategory();
        this.getLOV();
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    back = () => {
        this.props.history.push(`/webinar`)
    }

    getCategory = () => {
        httprequester.get('category/getall', {
            success: async function (response) {

                console.log("resCat => ", response)
                if (response.data.message == "success") {
                    this.setState({
                        listCategory: response.data.detailMessage
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    getLOV = () => {
        httprequester.get('artikel/lov', {
            success: async function (response) {

                console.log("resLOV => ", response)
                if (response.data.message == "success") {
                    let datapenyakit = []
                    response.data.detailPenyakit.forEach((v, key) => {
                        // console.log('v',v);
                        datapenyakit.push({
                            penyakit_id: v.penyakit_id,
                            penyakit_name: v.penyakit_name == null ? '-' : v.penyakit_name
                        })
                    })
                    this.setState({
                        listSpesialis: response.data.detailSpecialist,
                        listMolekul: response.data.detailMolekul,
                        listPenyakit: datapenyakit,
                        listPenulis: response.data.detailPenulis
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    saveWebinar = () => {
        let slugLower = this.state.webinarJudul.toLowerCase();
        let slugRep = slugLower.replace(/\s/g, '-');

        let body = {
            "webinar_penyakit": this.state.webinarPenyakit.toString(),
            "webinar_penyakit_search": this.state.webinarPenyakit.toString(),
            "webinar_molekul": this.state.webinarMolekul.toString(),
            "webinar_molekul_search": this.state.webinarMolekul.toString(),
            "webinar_spesialis": this.state.webinarSpesialis.toString(),
            "webinar_spesialis_search": this.state.webinarSpesialis.toString(),
            "webinar_title": this.state.webinarJudul,
            "webinar_title_search": this.state.webinarJudul,
            "webinar_slug": slugRep,
            "webinar_content": this.state.webinarKonten,
            "webinar_thumbnail": this.state.webinarGambar,
            "webinar_video": this.state.webinarVideo,
            "webinar_status": "Unpublished",
            "webinar_penulis": this.state.webinarPenulis.toString(),
            "webinar_penulis_search": this.state.webinarPenulis.toString(),
            "created_by": this.state.name,
            "webcast_default_image_url": this.state.webinarDefaultImageUrl,
            "webinar_video_parts": [
                {
                    "minute_start_at": parseInt(this.state.webinarShortMenit1),
                    "part_thumbnail": this.state.webinarShortThumbnail1,
                    "part_url": this.state.webinarShortUrl1,
                    "title": this.state.webinarShortJudul1,
                },
                {
                    "minute_start_at": parseInt(this.state.webinarShortMenit2),
                    "part_thumbnail": this.state.webinarShortThumbnail2,
                    "part_url": this.state.webinarShortUrl2,
                    "title": this.state.webinarShortJudul2,
                },
                {
                    "minute_start_at": parseInt(this.state.webinarShortMenit3),
                    "part_thumbnail": this.state.webinarShortThumbnail3,
                    "part_url": this.state.webinarShortUrl3,
                    "title": this.state.webinarShortJudul3,
                },
                {
                    "minute_start_at": parseInt(this.state.webinarShortMenit4),
                    "part_thumbnail": this.state.webinarShortThumbnail4,
                    "part_url": this.state.webinarShortUrl4,
                    "title": this.state.webinarShortJudul4,
                },
                {
                    "minute_start_at": parseInt(this.state.webinarShortMenit5),
                    "part_thumbnail": this.state.webinarShortThumbnail5,
                    "part_url": this.state.webinarShortUrl5,
                    "title": this.state.webinarShortJudul5,
                },
                {
                    "minute_start_at": parseInt(this.state.webinarShortMenit6),
                    "part_thumbnail": this.state.webinarShortThumbnail6,
                    "part_url": this.state.webinarShortUrl6,
                    "title": this.state.webinarShortJudul6,
                },
                {
                    "minute_start_at": parseInt(this.state.webinarShortMenit7),
                    "part_thumbnail": this.state.webinarShortThumbnail7,
                    "part_url": this.state.webinarShortUrl7,
                    "title": this.state.webinarShortJudul7,
                },
                {
                    "minute_start_at": parseInt(this.state.webinarShortMenit8),
                    "part_thumbnail": this.state.webinarShortThumbnail8,
                    "part_url": this.state.webinarShortUrl8,
                    "title": this.state.webinarShortJudul8,
                },
                {
                    "minute_start_at": parseInt(this.state.webinarShortMenit9),
                    "part_thumbnail": this.state.webinarShortThumbnail9,
                    "part_url": this.state.webinarShortUrl9,
                    "title": this.state.webinarShortJudul9,
                },
                {
                    "minute_start_at": parseInt(this.state.webinarShortMenit10),
                    "part_thumbnail": this.state.webinarShortThumbnail10,
                    "part_url": this.state.webinarShortUrl10,
                    "title": this.state.webinarShortJudul10,
                },
            ],
            "webcast_video_duration_s": parseInt(this.state.durasiVideoFullDtk),
        }

        console.log("body =>", body)
        this.setState({
            prosess: true
        })
        httprequester.postarticle('webinar/save', body, {
            success: async function (response) {
                console.log("res => ", response)
                if (response.data.message == "success") {
                    this.setState({
                        prosess: false,
                        basic: true,
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    handleChangePenulis(e) {
        this.setState({
            webinarPenulis: e
        })
        console.log("INIT N", e);
    }

    handleChangeSpesialis(e) {
        this.setState({
            webinarSpesialis: e
        })
        console.log("INIT S", e);
    }

    handleChangeMolekul(e) {
        this.setState({
            webinarMolekul: e
        })
        console.log("INIT M", e);
    }

    handleChangePenyakit(e) {
        this.setState({
            webinarPenyakit: e
        })
        console.log("INIT P", e);
    }

    handleEditorChange = (e) => {
        console.log(
            'Content was updated:',
            e.target.getContent()
        );
        this.setState({
            webinarKonten: e.target.getContent()
        })
    }

    render() {
        const option = this.state.listSpesialis.map(o => o.specialist_name)
        const optionMolekul = this.state.listMolekul.map(o => o.molekul_name)
        const optionPenyakit = this.state.listPenyakit.map(o => o.penyakit_name)
        const optionPenulis = this.state.listPenulis.map(o => o.penulis_name)
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Tambah Webinar</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Webinar" breadcrumbItem="Tambah Webinar" />

                        {this.state.basic ? (
                            <SweetAlert
                                success
                                title="Webinar berhasil di simpan"
                                onConfirm={() => this.back()}
                            ></SweetAlert>
                        ) : null}

                        {this.state.prosess ? (
                            <SweetAlert title="proses simpan ..." showConfirm={false} >
                            </SweetAlert>
                        ) : null}

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Form>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-firstname-Input">Judul Webinar</Label>
                                                <Input
                                                    id="webinarJudul"
                                                    type="input"
                                                    placeholder="Input Judul Webinar"
                                                    onChange={(e) => this.setState({ webinarJudul: e.target.value })}
                                                    value={this.state.webinarJudul}
                                                />
                                            </div>

                                            <Row>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">Penulis</Label>
                                                        <Typeahead
                                                            // allowNew
                                                            id="basic-typeahead-multiple"
                                                            multiple
                                                            placeholder="Pilih Penulis"
                                                            labelKey="name"
                                                            options={optionPenulis}
                                                            onChange={(e) => this.handleChangePenulis(e)}
                                                            selected={this.state.webinarPenulis} />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-email-Input">URL Gambar Thumbnail</Label>
                                                        <Input
                                                            id="webinarThumbnail"
                                                            type="input"
                                                            placeholder="Input Link Thumbnail"
                                                            onChange={(e) => this.setState({ webinarGambar: e.target.value })}
                                                            value={this.state.webinarGambar}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-email-Input">URL Video</Label>
                                                        <Input
                                                            id="webinarVideo"
                                                            type="input"
                                                            placeholder="Input Link Video"
                                                            onChange={(e) => this.setState({ webinarVideo: e.target.value })}
                                                            value={this.state.webinarVideo}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="formrow-firstname-Input">Durasi Video (detik)</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ durasiVideoFullDtk: e.target.value })}
                                                            value={this.state.durasiVideoFullDtk}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-InputCity">Spesialis</Label>
                                                        <Typeahead
                                                            // allowNew
                                                            id="basic-typeahead-multiple"
                                                            multiple
                                                            placeholder="Pilih Spesialis"
                                                            labelKey="name"
                                                            options={option}
                                                            onChange={(e) => this.handleChangeSpesialis(e)}
                                                            selected={this.state.webinarSpesialis}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-InputState">Molekul</Label>
                                                        <Typeahead
                                                            id="basic-typeahead-multiple"
                                                            multiple
                                                            placeholder="Pilih Molekul"
                                                            labelKey="name"
                                                            options={optionMolekul}
                                                            onChange={(e) => this.handleChangeMolekul(e)}
                                                            selected={this.state.webinarMolekul}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-InputZip">Penyakit</Label>
                                                        <Typeahead
                                                            id="basic-typeahead-multiple"
                                                            multiple
                                                            placeholder="Pilih Penyakit"
                                                            labelKey="name"
                                                            options={optionPenyakit}
                                                            onChange={(e) => this.handleChangePenyakit(e)}
                                                            selected={this.state.articlePenyakit}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label>Default Image URL</Label>
                                                        <Input
                                                            id="webinarDefaultImageUrl"
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarDefaultImageUrl: e.target.value })}
                                                            value={this.state.webinarDefaultImageUrl}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            
                                            <Row>
                                                <Col md={4}>
                                                    <div className="mb-4">
                                                        <Label htmlFor="formrow-firstname-Input">Judul Webinar Short 1</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortJudul1: e.target.value })}
                                                            value={this.state.webinarShortJudul1}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">URL Gambar Thumbnail 1</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortThumbnail1: e.target.value })}
                                                            value={this.state.webinarShortThumbnail1}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">URL Video 1</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortUrl1: e.target.value })}
                                                            value={this.state.webinarShortUrl1}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={2}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="formrow-firstname-Input">Durasi Video 1</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortMenit1: e.target.value })}
                                                            value={this.state.webinarShortMenit1}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <div className="mb-4">
                                                        <Label htmlFor="formrow-firstname-Input">Judul Webinar Short 2</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortJudul2: e.target.value })}
                                                            value={this.state.webinarShortJudul2}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">URL Gambar Thumbnail 2</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortThumbnail2: e.target.value })}
                                                            value={this.state.webinarShortThumbnail2}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">URL Video 2</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortUrl2: e.target.value })}
                                                            value={this.state.webinarShortUrl2}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={2}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="formrow-firstname-Input">Durasi Video 2</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortMenit2: e.target.value })}
                                                            value={this.state.webinarShortMenit2}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <div className="mb-4">
                                                        <Label htmlFor="formrow-firstname-Input">Judul Webinar Short 3</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortJudul3: e.target.value })}
                                                            value={this.state.webinarShortJudul3}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">URL Gambar Thumbnail 3</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortThumbnail3: e.target.value })}
                                                            value={this.state.webinarShortThumbnail3}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">URL Video 3</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortUrl3: e.target.value })}
                                                            value={this.state.webinarShortUrl3}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={2}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="formrow-firstname-Input">Durasi Video 3</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortMenit3: e.target.value })}
                                                            value={this.state.webinarShortMenit3}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <div className="mb-4">
                                                        <Label htmlFor="formrow-firstname-Input">Judul Webinar Short 4</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortJudul4: e.target.value })}
                                                            value={this.state.webinarShortJudul4}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">URL Gambar Thumbnail 4</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortThumbnail4: e.target.value })}
                                                            value={this.state.webinarShortThumbnail4}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">URL Video 4</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortUrl4: e.target.value })}
                                                            value={this.state.webinarShortUrl4}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={2}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="formrow-firstname-Input">Durasi Video 4</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortMenit4: e.target.value })}
                                                            value={this.state.webinarShortMenit4}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <div className="mb-4">
                                                        <Label htmlFor="formrow-firstname-Input">Judul Webinar Short 5</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortJudul5: e.target.value })}
                                                            value={this.state.webinarShortJudul5}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">URL Gambar Thumbnail 5</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortThumbnail5: e.target.value })}
                                                            value={this.state.webinarShortThumbnail5}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">URL Video 5</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortUrl5: e.target.value })}
                                                            value={this.state.webinarShortUrl5}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={2}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="formrow-firstname-Input">Durasi Video 5</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortMenit5: e.target.value })}
                                                            value={this.state.webinarShortMenit5}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <div className="mb-4">
                                                        <Label htmlFor="formrow-firstname-Input">Judul Webinar Short 6</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortJudul6: e.target.value })}
                                                            value={this.state.webinarShortJudul6}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">URL Gambar Thumbnail 6</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortThumbnail6: e.target.value })}
                                                            value={this.state.webinarShortThumbnail6}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">URL Video 6</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortUrl6: e.target.value })}
                                                            value={this.state.webinarShortUrl6}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={2}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="formrow-firstname-Input">Durasi Video 6</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortMenit6: e.target.value })}
                                                            value={this.state.webinarShortMenit6}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <div className="mb-4">
                                                        <Label htmlFor="formrow-firstname-Input">Judul Webinar Short 7</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortJudul7: e.target.value })}
                                                            value={this.state.webinarShortJudul7}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">URL Gambar Thumbnail 7</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortThumbnail7: e.target.value })}
                                                            value={this.state.webinarShortThumbnail7}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">URL Video 7</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortUrl7: e.target.value })}
                                                            value={this.state.webinarShortUrl7}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={2}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="formrow-firstname-Input">Durasi Video 7</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortMenit7: e.target.value })}
                                                            value={this.state.webinarShortMenit7}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <div className="mb-4">
                                                        <Label htmlFor="formrow-firstname-Input">Judul Webinar Short 8</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortJudul8: e.target.value })}
                                                            value={this.state.webinarShortJudul8}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">URL Gambar Thumbnail 8</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortThumbnail8: e.target.value })}
                                                            value={this.state.webinarShortThumbnail8}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">URL Video 8</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortUrl8: e.target.value })}
                                                            value={this.state.webinarShortUrl8}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={2}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="formrow-firstname-Input">Durasi Video 8</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortMenit8: e.target.value })}
                                                            value={this.state.webinarShortMenit8}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <div className="mb-4">
                                                        <Label htmlFor="formrow-firstname-Input">Judul Webinar Short 9</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortJudul9: e.target.value })}
                                                            value={this.state.webinarShortJudul9}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">URL Gambar Thumbnail 9</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortThumbnail9: e.target.value })}
                                                            value={this.state.webinarShortThumbnail9}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">URL Video 9</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortUrl9: e.target.value })}
                                                            value={this.state.webinarShortUrl9}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={2}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="formrow-firstname-Input">Durasi Video 9</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortMenit9: e.target.value })}
                                                            value={this.state.webinarShortMenit9}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <div className="mb-4">
                                                        <Label htmlFor="formrow-firstname-Input">Judul Webinar Short 10</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortJudul10: e.target.value })}
                                                            value={this.state.webinarShortJudul10}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">URL Gambar Thumbnail 10</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortThumbnail10: e.target.value })}
                                                            value={this.state.webinarShortThumbnail10}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">URL Video 10</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortUrl10: e.target.value })}
                                                            value={this.state.webinarShortUrl10}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={2}>
                                                    <div className="mb-2">
                                                        <Label htmlFor="formrow-firstname-Input">Durasi Video 10</Label>
                                                        <Input
                                                            type="input"
                                                            onChange={(e) => this.setState({ webinarShortMenit10: e.target.value })}
                                                            value={this.state.webinarShortMenit10}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 10 }}>
                            <Col xs="12" md="12">
                                <Card>
                                    <CardBody>
                                        <Form method="post">
                                            <Editor
                                                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                                                initialValue=""
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic backcolor | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent lineheight | ' +
                                                        'removeformat',
                                                    content_style: 'body { font-family:Poppins,Arial,sans-serif; }',
                                                    line_height_formats: '0.2 0.4 0.6 0.8 1 1.2 1.4 1.6 2',
                                                }}
                                                onChange={this.handleEditorChange}
                                            />
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row style={{ paddingTop: 10 }}>
                            <Col xs="12" md="12">
                                <Card>
                                    <Button type="submit" className="btn btn-primary w-md" onClick={() => this.saveWebinar()}>
                                        Submit Webinar
                                    </Button>
                                </Card>
                            </Col>
                        </Row>
                        {/* <Row md="10" style={{ paddingTop: 8, justifyContent: "end", alignItems: "end" }}>
                            <Button color="danger" onClick={() => this.saveWebinar()}>
                                Submit Artikel
                            </Button>
                        </Row> */}
                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

export default TambahWebinar;
