import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    Input,
    Label,
    Button,
    Card,
    CardBody,
    Form,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Container } from "reactstrap"
import httprequester from '../../lib/httprequester'
import { Typeahead } from 'react-bootstrap-typeahead';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Editor } from '@tinymce/tinymce-react';

class TambahTindakanMedis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            basic: false,
            listCategory: [],
            listSpesialis: [],
            listMolekul: [],
            listPenyakit: [],
            listPenulis: [],
            tindakanJudul: '',
            tindakanPenulis: '',
            tindakanPenyakit: '',
            tindakanMolekul: '',
            tindakanSpesialis: '',
            tindakanKonten: '',
            tindakanGambar: '',
            tindakanVideo: '',
            name: '',
            prosess: false
        };
    }

    componentDidMount() {
        let x = JSON.parse(localStorage.getItem('user'))
        if (x) {
            this.setState({
                name: x.admin_name
            })
        }
        this.getCategory();
        this.getLOV();
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    back = () => {
        this.props.history.push(`/tindakanmedis`)
    }

    getCategory = () => {
        httprequester.get('category/getall', {
            success: async function (response) {

                console.log("resCat => ", response)
                if (response.data.message == "success") {
                    this.setState({
                        listCategory: response.data.detailMessage
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    getLOV = () => {
        httprequester.get('artikel/lov', {
            success: async function (response) {

                console.log("resLOV => ", response)
                if (response.data.message == "success") {
                    let datapenyakit = []
                    response.data.detailPenyakit.forEach((v, key) => {
                        // console.log('v',v);
                        datapenyakit.push({
                            penyakit_id: v.penyakit_id,
                            penyakit_name: v.penyakit_name == null ? '-' : v.penyakit_name
                        })
                    })

                    this.setState({
                        listSpesialis: response.data.detailSpecialist,
                        listMolekul: response.data.detailMolekul,
                        listPenyakit: datapenyakit,
                        listPenulis: response.data.detailPenulis
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    saveTindakanMedis = () => {
        let slugLower = this.state.tindakanJudul.toLowerCase();
        let slugRep = slugLower.replace(/\s/g, '-');

        let body = {
            "tindakan_penyakit": this.state.tindakanPenyakit.toString(),
            "tindakan_penyakit_search": this.state.tindakanPenyakit.toString(),
            "tindakan_penulis": this.state.tindakanPenulis.toString(),
            "tindakan_penulis_search": this.state.tindakanPenulis.toString(),
            "tindakan_molekul": this.state.tindakanMolekul.toString(),
            "tindakan_molekul_search": this.state.tindakanMolekul.toString(),
            "tindakan_spesialis": this.state.tindakanSpesialis.toString(),
            "tindakan_spesialis_search": this.state.tindakanSpesialis.toString(),
            "tindakan_title": this.state.tindakanJudul,
            "tindakan_title_search": this.state.tindakanJudul,
            "tindakan_slug": slugRep,
            "tindakan_content": this.state.tindakanKonten,
            "tindakan_thumbnail": this.state.tindakanGambar,
            "tindakan_video": this.state.tindakanVideo,
            "tindakan_status": "Unpublished",
            "created_by": this.state.name
        }

        console.log("body =>", body)
        this.setState({
            prosess: true
        })
        httprequester.postarticle('tindakan/save', body, {
            success: async function (response) {
                console.log("res => ", response)
                if (response.data.message == "success") {
                    this.setState({
                        basic: true,
                        prosess: false
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    handleChangePenulis(e) {
        this.setState({
            tindakanPenulis: e
        })
        console.log("INIT N", e);
    }

    handleChangeSpesialis(e) {
        this.setState({
            tindakanSpesialis: e
        })
        console.log("INIT S", e);
    }

    handleChangeMolekul(e) {
        this.setState({
            tindakanMolekul: e
        })
        console.log("INIT M", e);
    }

    handleChangePenyakit(e) {
        this.setState({
            tindakanPenyakit: e
        })
        console.log("INIT P", e);
    }

    handleEditorChange = (e) => {
        console.log(
            'Content was updated:',
            e.target.getContent()
        );
        this.setState({
            tindakanKonten: e.target.getContent()
        })
    }

    render() {
        const option = this.state.listSpesialis.map(o => o.specialist_name)
        const optionMolekul = this.state.listMolekul.map(o => o.molekul_name)
        const optionPenyakit = this.state.listPenyakit.map(o => o.penyakit_name)
        const optionPenulis = this.state.listPenulis.map(o => o.penulis_name)
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Tambah Tindakan Medis Spesialistik</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Tindakan Medis Spesialistik" breadcrumbItem="Tambah Tindakan Medis Spesialistik" />

                        {this.state.basic ? (
                            <SweetAlert
                                success
                                title="Tindakan Medis Spesialistik berhasil di simpan"
                                onConfirm={() => this.back()}
                            ></SweetAlert>
                        ) : null}

                        {this.state.prosess ? (
                            <SweetAlert title="proses simpan ..." showConfirm={false} >
                            </SweetAlert>
                        ) : null}

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Form>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-firstname-Input">Judul Tindakan Medis Spesialistik</Label>
                                                <Input
                                                    id="tindakanJudul"
                                                    type="input"
                                                    placeholder="Input Judul Tindakan Medis Spesialistik"
                                                    onChange={(e) => this.setState({ tindakanJudul: e.target.value })}
                                                    value={this.state.tindakanJudul}
                                                />
                                            </div>

                                            <Row>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">Penulis</Label>
                                                        <Typeahead
                                                            // allowNew
                                                            id="basic-typeahead-multiple"
                                                            multiple
                                                            placeholder="Pilih Penulis"
                                                            labelKey="name"
                                                            options={optionPenulis}
                                                            onChange={(e) => this.handleChangePenulis(e)}
                                                            selected={this.state.tindakanPenulis}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-email-Input">URL Gambar Thumbnail</Label>
                                                        <Input
                                                            id="tindakanThumbnail"
                                                            type="input"
                                                            placeholder="Input Link Thumbnail"
                                                            onChange={(e) => this.setState({ tindakanGambar: e.target.value })}
                                                            value={this.state.tindakanGambar}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-email-Input">URL Video</Label>
                                                        <Input
                                                            id="tindakanVideo"
                                                            type="input"
                                                            placeholder="Input Link Video"
                                                            onChange={(e) => this.setState({ tindakanVideo: e.target.value })}
                                                            value={this.state.tindakanVideo}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-InputCity">Spesialis</Label>
                                                        <Typeahead
                                                            id="basic-typeahead-multiple"
                                                            multiple
                                                            placeholder="Pilih Spesialis"
                                                            labelKey="name"
                                                            options={option}
                                                            onChange={(e) => this.handleChangeSpesialis(e)}
                                                            selected={this.state.tindakanSpesialis}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-InputState">Molekul</Label>
                                                        <Typeahead
                                                            id="basic-typeahead-multiple"
                                                            multiple
                                                            placeholder="Pilih Molekul"
                                                            labelKey="name"
                                                            options={optionMolekul}
                                                            onChange={(e) => this.handleChangeMolekul(e)}
                                                            selected={this.state.tindakanMolekul}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-InputZip">Penyakit</Label>
                                                        <Typeahead
                                                            id="basic-typeahead-multiple"
                                                            multiple
                                                            placeholder="Pilih Penyakit"
                                                            labelKey="name"
                                                            options={optionPenyakit}
                                                            onChange={(e) => this.handleChangePenyakit(e)}
                                                            selected={this.state.tindakanPenyakit}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 10 }}>
                            <Col xs="12" md="12">
                                <Card>
                                    <CardBody>
                                        <Form method="post">
                                            <Editor
                                                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                                                initialValue=""
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic backcolor | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent lineheight | ' +
                                                        'removeformat',
                                                    content_style: 'body { font-family:Poppins,Arial,sans-serif; }',
                                                    line_height_formats: '0.2 0.4 0.6 0.8 1 1.2 1.4 1.6 2',
                                                }}
                                                onChange={this.handleEditorChange}
                                            />
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 10 }}>
                            <Col xs="12" md="12">
                                <Card>
                                    <Button type="submit" className="btn btn-primary w-md" onClick={() => this.saveTindakanMedis()}>
                                        Submit Tindakan Medis Spesialistik
                                    </Button>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

export default TambahTindakanMedis;
