import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardSubtitle,
  CardText,
  Input,
  Alert,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import { Link } from "react-router-dom";
import { Container } from "reactstrap"
import httprequester from '../../lib/httprequester'
import moment from 'moment'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types';

import { Typeahead } from 'react-bootstrap-typeahead';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SweetAlert from 'react-bootstrap-sweetalert';

class event extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            sizePerPage: 10,
            totalPage: [],
            listEvent: [],
            alert: false,
            message: '',
            alertcopy: false,
            namaalert: '',
            status: 'Published',
            listPenyelenggara: [],
            listLokasi: [],
            listSpesialisasi: [],
            hasilLokasi: [],
            hasilSpesialisasi: [],
            hasilPenyelenggara: [],
            search: ''
        }
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    componentDidMount() {
        this.getAcara();
        this.getLov();
    }

    getLov() {
        let body = {
            date: moment().format("YYYY-MM-DD")
        }

        httprequester.postarticle('event/internasional/lov_user', body, {
            success: async function (response) {

                let { data } = response;
                // let { detailMessage } = response.data;
                this.setState({
                    listPenyelenggara: data.detailOrganisasi,
                    listSpesialisasi: data.detailSpecialist,
                    listLokasi: data.detailCountry
                })
                // console.log("artikel => ", this.state.listArtikel)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    hapus = (event_id) => {
        let body = {
            "event_id": event_id,
            "event_status": "Deleted"
        }
        // console.log("body =>", body)
        httprequester.postnobody('event/internasional/update', body, {
            success: async function (response) {
                this.setState({
                    message: response.data.detailMessage,
                    alert: true
                })
                setTimeout(() => {
                    this.getAcara()
                }, 2000);

                // console.log("artikel => ", this.state.listArtikel)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    getReset = () => {
        let urutan = "created_at DESC"
        const body = {
            "status": this.state.status,
            "search": '',
            "organisasi": '',
            "lokasi": '',
            "city": '',
            "spesialisasi": '',
            "start": moment().format("YYYY-MM-DD"),
            "end": '',
            "page": 1,
            "limit": this.state.sizePerPage,
            "urutan": urutan
        }

        this.setState({
            hasilSpesialisasi: [],
            hasilLokasi: [],
            hasilPenyelenggara: [],
            search: '',
            page: 1
        })
        // console.log("body =>", body)
        httprequester.postnobody('event/internasional/getall', body, {
            success: async function (response) {
                let pages = []
                for (let index = 1; index <= response.data.result.lastPage; index++) {
                    pages.push(index)
                }
                // console.log('aa',pages);
                let data = []
                response.data.result.data.dataRows.forEach(async (v, key) => {
                    await data.push({
                        event_date_end: moment(v[0]).format('DD MMM YYYY hh:mm:ss'),
                        event_thumbnail: v[1],
                        event_location: v[2],
                        event_spesialisasi: v[3],
                        event_description: v[4],
                        created_at: v[5],
                        event_type: v[6],
                        event_date: moment.utc(v[7]).format('DD MMM YYYY hh:mm:ss'),
                        event_link: v[8],
                        event_status: v[9],
                        event_organization: v[10],
                        created_by: v[11],
                        myfile: v[12],
                        event_id: v[13],
                        event_name: v[14]
                    })
                })
                this.setState({
                    listEvent: data,
                    page: response.data.result.page,
                    sizePerPage: response.data.result.perPage,
                    totalPage: pages,
                })
                // console.log("artikel => ", response.data.result.page)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    getAcara = () => {
        // console.log('a',this.state.page);
        let search = this.state.search ? this.state.search : ''
        let organisasi = this.state.hasilPenyelenggara[0] ? this.state.hasilPenyelenggara[0] : ''
        let lokasi = this.state.hasilLokasi ? this.state.hasilLokasi[0] : ''
        let spesialisasi = this.state.hasilSpesialisasi[0] ? this.state.hasilSpesialisasi[0] : ''
        let urutan = "created_at DESC"
        const body = {
            "status": this.state.status,
            "search": search,
            "organisasi": organisasi,
            "lokasi": '',
            "city": lokasi,
            "spesialisasi": spesialisasi,
            "start": moment().format("YYYY-MM-DD"),
            "end": '',
            "page": this.state.page,
            "limit": this.state.sizePerPage,
            "urutan": urutan
        }
        // console.log("body =>", body)
        httprequester.postnobody('event/internasional/getall', body, {
            success: async function (response) {
                let pages = []
                for (let index = 1; index <= response.data.result.lastPage; index++) {
                    pages.push(index)
                }
                // console.log("res2 => ", response.data.result.data.dataRows)
                // console.log('aa',pages);
                let data = []
                response.data.result.data.dataRows.forEach(async (v, key) => {
                    await data.push({
                        event_date_end: moment(v[0]).format('DD MMM YYYY hh:mm:ss'),
                        event_thumbnail: v[1],
                        event_location: v[2],
                        event_spesialisasi: v[3],
                        event_description: v[4],
                        created_at: v[5],
                        event_type: v[6],
                        event_date: moment.utc(v[7]).format('DD MMM YYYY hh:mm:ss'),
                        event_link: v[8],
                        event_status: v[9],
                        event_organization: v[10],
                        created_by: v[11],
                        myfile: v[12],
                        event_id: v[13],
                        event_name: v[14]
                    })
                })
                this.setState({
                    listEvent: data,
                    page: response.data.result.page,
                    sizePerPage: response.data.result.perPage,
                    totalPage: pages,
                })
                // console.log("artikel => ", response.data.result.page)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    handlecancel = () => {
        this.setState({
            alert: false
        })
    }

    direct = (id, status) => {
        this.setState({
            basic: false
        })
        this.props.history.push(`/edit-event/internasional/${id}/${status}`)
    }

    directSEO = (id, type) => {
        this.props.history.push(`/seoconfig/${id}/${type}`)
    }

    copyurl = (value, nama) => {
        this.setState({
            namaalert: nama,
            alertcopy: true
        })
        navigator.clipboard.writeText(value)
        setTimeout(() => {
            this.setState({
                alertcopy: false
            })
        }, 3000);
    }

    handlePageClick = (e) => {

        // console.log("page => ", e)
        let a = e
        this.setState({
            page: a
        })

        let search = this.state.search ? this.state.search : ''
        let organisasi = this.state.hasilPenyelenggara[0] ? this.state.hasilPenyelenggara[0] : ''
        let lokasi = this.state.hasilLokasi ? this.state.hasilLokasi[0] : ''
        let spesialisasi = this.state.hasilSpesialisasi[0] ? this.state.hasilSpesialisasi[0] : ''
        let urutan = "created_at DESC"
        const body = {
            "status": this.state.status,
            "search": search,
            "organisasi": organisasi,
            "lokasi": '',
            "city": lokasi,
            "spesialisasi": spesialisasi,
            "start": moment().format("YYYY-MM-DD"),
            "end": '',
            "page": a,
            "limit": this.state.sizePerPage,
            "urutan": urutan
        }
        // console.log("body =>", body)
        httprequester.postnobody('event/internasional/getall', body, {
            success: async function (response) {
                let pages = []
                for (let index = 1; index <= response.data.result.lastPage; index++) {
                    pages.push(index)
                }
                // console.log('aa', pages);
                let data = []
                response.data.result.data.dataRows.forEach(async (v, key) => {
                    await data.push({
                        event_date_end: moment(v[0]).format('DD MMM YYYY hh:mm:ss'),
                        event_thumbnail: v[1],
                        event_location: v[2],
                        event_spesialisasi: v[3],
                        event_description: v[4],
                        created_at: v[5],
                        event_type: v[6],
                        event_date: moment(v[7]).utc().format('DD MMM YYYY hh:mm:ss'),
                        event_link: v[8],
                        event_status: v[9],
                        event_organization: v[10],
                        created_by: v[11],
                        myfile: v[12],
                        event_id: v[13],
                        event_name: v[14]
                    })
                })
                this.setState({
                    listEvent: data,
                    page: response.data.result.page,
                    sizePerPage: response.data.result.perPage,
                    totalPage: pages,
                })
                // console.log("artikel => ", response.data.result.page)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    render() {
        const option = this.state.listPenyelenggara.map(o => o.org_name)
        const optionLokasi = this.state.listLokasi.map(o => o.country_name)
        const optionSpesialisasi = this.state.listSpesialisasi.map(o => o.specialist_name)
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Acara Internasional || Ruang Ilmiah</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Acara Ilmiah Internasional" breadcrumbItem="Acara Ilmiah Internasional" />

                        <Row style={{ paddingBottom: 10 }}>
                            <Col xs="12" md="4">
                                <Link to="/tambah-event/internasional">
                                    <Button color="danger">
                                        Tambah Acara Internasional
                                    </Button>
                                </Link>
                            </Col>
                        </Row>

                        <div>
                            <Row>
                                <Col xs="12" md="9">
                                    {this.state.alertcopy ? <div>
                                        <Alert
                                            color="primary"
                                            isOpen={this.state.alertcopy}
                                        >
                                            sucess copy url {this.state.namaalert}
                                        </Alert>
                                    </div> : ''}
                                    <Row xs="3">
                                        {this.state.listEvent.map(function (event, index) {
                                            return <Col key={index}>
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle tag="h5" style={{ maxHeight: '50%' }}>
                                                            {event.event_name}
                                                        </CardTitle>
                                                        <CardSubtitle
                                                            className="mb-2 text-muted"
                                                            tag="h6"
                                                        >
                                                            {event.event_date} - {event.event_date_end}
                                                        </CardSubtitle>
                                                        <CardSubtitle
                                                            className="mb-2 text-muted"
                                                            tag="h6"
                                                        >
                                                            {event.event_location}
                                                        </CardSubtitle>
                                                        <CardImg
                                                            alt="Card image cap"
                                                            src={event.event_thumbnail}
                                                            top
                                                            width="50%"
                                                            height="50%"
                                                        />
                                                        <CardText >
                                                            {event.event_link}
                                                        </CardText>
                                                        <div className="d-flex flex-wrap gap-3" style={{ paddingBottom: 5, justifyContent: "flex-start", alignItems: "center" }}>
                                                            <div className="btn-group btn-group-lg">
                                                                {/* <button type="button" className="btn btn-outline-danger" onClick={() => { console.log("btnPreview") }}> <i className="fas fa-eye"></i> </button> */}
                                                                <button type="button" className="btn btn-outline-danger" onClick={() => { this.direct(event.event_id, event.event_status) }}> <i className="fas fa-edit"></i> </button>
                                                                <button type="button" className="btn btn-outline-danger" onClick={() => this.hapus(event.event_id)}> <i className="fas fa-trash"></i> </button>
                                                                <button type="button" className="btn btn-outline-danger" onClick={() => { this.copyurl(event.event_id, event.event_name) }}> <i className="fas fa-copy"></i> </button>
                                                                <button type="button" className="btn btn-outline-danger" onClick={() => { this.directSEO(event.event_id, "event-internasional")}}> <i className="fas fa-globe"></i> </button>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        }.bind(this))}


                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <Pagination className="pagination pagination-rounded justify-content-end mb-2">
                                                {this.state.totalPage.map((v, key) => {
                                                    return <PaginationItem active={this.state.page === v} key={key}>
                                                        <PaginationLink
                                                            onClick={() => this.handlePageClick(v)}
                                                        >
                                                            {v}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                })}
                                            </Pagination>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xl={3} lg={4}>
                                    <Card>
                                        <CardBody className="p-4">
                                            <div>

                                                <div className="search-box">
                                                    <p className="text-muted">Cari</p>
                                                    <div className="position-relative">
                                                        <input
                                                            type="search"
                                                            className="form-control rounded bg-light border-light"
                                                            placeholder="Cari..."
                                                            onChange={(e) => this.setState({ search: e.target.value })}
                                                            value={this.state.search}
                                                        />
                                                        <i className="mdi mdi-magnify search-icon"></i>
                                                    </div>
                                                </div>
                                                <hr className="my-4" />
                                                <Input
                                                    // id="focusAfterClose"
                                                    onChange={(e) => this.setState({ status: e.target.value })}
                                                    // onChange= {(e) => console.log(e.target.value) }
                                                    type="select"
                                                >
                                                    <option value="Published">
                                                        Published
                                                    </option>
                                                    <option value="Unpublished">
                                                        Unpublished
                                                    </option>
                                                    <option value="Completed">
                                                      Completed
                                                    </option>
                                                </Input>
                                                <hr className="my-4" />
                                                <br />
                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="name"
                                                    onChange={(e) => this.setState({ hasilLokasi: e })}
                                                    options={optionLokasi}
                                                    placeholder="Pilih Lokasi Acara"
                                                    selected={this.state.hasilLokasi}
                                                />
                                                <hr className="my-4" />
                                                <br />
                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="name"
                                                    onChange={(e) => this.setState({ hasilSpesialisasi: e })}
                                                    options={optionSpesialisasi}
                                                    placeholder="Pilih Spesialisasi"
                                                    selected={this.state.hasilSpesialisasi}
                                                />
                                                <hr className="my-4" />
                                                <br />
                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="name"
                                                    onChange={(e) => this.setState({ hasilPenyelenggara: e })}
                                                    options={option}
                                                    placeholder="Pilih Penyelenggara"
                                                    selected={this.state.hasilPenyelenggara}
                                                />
                                                <hr className="my-4" />
                                                <br />
                                                <Button color="danger" onClick={() => this.getAcara()}>
                                                    Cari
                                                </Button>
                                                &nbsp;&nbsp;
                                                <Button color="danger" onClick={() => this.getReset()}>
                                                    Atur Ulang
                                                </Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        {this.state.alert ?
                            <SweetAlert success title={this.state.message} onConfirm={this.handlecancel}>
                            </SweetAlert> : ''
                        }
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(event);
