import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    Input,
    Label,
    Button,
    Card,
    CardBody,
    Form,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Container } from "reactstrap"
import httprequester from '../../lib/httprequester'
import { Typeahead } from 'react-bootstrap-typeahead';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Editor } from '@tinymce/tinymce-react';

class TambahAnimasi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            basic: false,
            listCategory: [],
            listSpesialis: [],
            listMolekul: [],
            listPenyakit: [],
            listPenulis: [],
            animasiJudul: '',
            animasiPenulis: '',
            animasiPenyakit: '',
            animasiMolekul: '',
            animasiSpesialis: '',
            animasiKonten: '',
            animasiGambar: '',
            animasiVideo: '',
            prosess: false,
            name: ''
        };
    }

    componentDidMount() {
        let x = JSON.parse(localStorage.getItem('user'))
        if (x) {
            this.setState({
                name: x.admin_name
            })
        }
        this.getCategory();
        this.getLOV();
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    back = () => {
        this.props.history.push(`/animasi-penggunaan-obat`)
    }

    getCategory = () => {
        httprequester.get('category/getall', {
            success: async function (response) {

                console.log("resCat => ", response)
                if (response.data.message == "success") {
                    this.setState({
                        listCategory: response.data.detailMessage
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    getLOV = () => {
        httprequester.get('artikel/lov', {
            success: async function (response) {

                console.log("resLOV => ", response)
                if (response.data.message == "success") {
                    let datapenyakit = []
                    response.data.detailPenyakit.forEach((v, key) => {
                        // console.log('v',v);
                        datapenyakit.push({
                            penyakit_id: v.penyakit_id,
                            penyakit_name: v.penyakit_name == null ? '-' : v.penyakit_name
                        })
                    })
                    this.setState({
                        listSpesialis: response.data.detailSpecialist,
                        listMolekul: response.data.detailMolekul,
                        listPenyakit: datapenyakit,
                        listPenulis: response.data.detailPenulis
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    saveAnimasi = () => {
        let slugLower = this.state.animasiJudul.toLowerCase();
        let slugRep = slugLower.replace(/\s/g, '-');

        let body = {
            "animasi_penyakit": this.state.animasiPenyakit.toString(),
            "animasi_penyakit_search": this.state.animasiPenyakit.toString(),
            "animasi_penulis": this.state.animasiPenulis.toString(),
            "animasi_penulis_search": this.state.animasiPenulis.toString(),
            "animasi_molekul": this.state.animasiMolekul.toString(),
            "animasi_molekul_search": this.state.animasiMolekul.toString(),
            "animasi_spesialis": this.state.animasiSpesialis.toString(),
            "animasi_spesialis_search": this.state.animasiSpesialis.toString(),
            "animasi_title": this.state.animasiJudul,
            "animasi_title_search": this.state.animasiJudul,
            "animasi_slug": slugRep,
            "animasi_content": this.state.animasiKonten,
            "animasi_thumbnail": this.state.animasiGambar,
            "animasi_video": this.state.animasiVideo,
            "animasi_status": "Unpublished",
            "created_by": this.state.name
        }

        console.log("body =>", body)
        this.setState({
            prosess: true
        })
        httprequester.postarticle('animasi/save', body, {
            success: async function (response) {
                console.log("res => ", response)
                if (response.data.message == "success") {
                    this.setState({
                        basic: true,
                        prosess: false
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    handleChangePenulis(e) {
        this.setState({
            animasiPenulis: e
        })
        console.log("INIT N", e);
    }

    handleChangeSpesialis(e) {
        this.setState({
            animasiSpesialis: e
        })
        console.log("INIT S", e);
    }

    handleChangeMolekul(e) {
        this.setState({
            animasiMolekul: e
        })
        console.log("INIT M", e);
    }

    handleChangePenyakit(e) {
        this.setState({
            animasiPenyakit: e
        })
        console.log("INIT P", e);
    }

    handleEditorChange = (e) => {
        console.log(
            'Content was updated:',
            e.target.getContent()
        );
        this.setState({
            animasiKonten: e.target.getContent()
        })
    }

    render() {
        const option = this.state.listSpesialis.map(o => o.specialist_name)
        const optionMolekul = this.state.listMolekul.map(o => o.molekul_name)
        const optionPenyakit = this.state.listPenyakit.map(o => o.penyakit_name)
        const optionPenulis = this.state.listPenulis.map(o => o.penulis_name)
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Tambah Petunjuk Penggunaan Obat</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Petunjuk Penggunaan Obat" breadcrumbItem="Tambah Petunjuk Penggunaan Obat" />

                        {this.state.basic ? (
                            <SweetAlert
                                success
                                title="Petunjuk Penggunaan Obat berhasil di simpan"
                                onConfirm={() => this.back()}
                            ></SweetAlert>
                        ) : null}

                        {this.state.prosess ? (
                            <SweetAlert title="proses simpan ..." showConfirm={false} >
                            </SweetAlert>
                        ) : null}

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Form>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-firstname-Input">Judul Petunjuk Penggunaan Obat</Label>
                                                <Input
                                                    id="animasiJudul"
                                                    type="input"
                                                    placeholder="Input Judul Petunjuk Penggunaan Obat"
                                                    onChange={(e) => this.setState({ animasiJudul: e.target.value })}
                                                    value={this.state.animasiJudul}
                                                />
                                            </div>

                                            <Row>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">Penulis</Label>
                                                        <Typeahead
                                                            // allowNew
                                                            id="basic-typeahead-multiple"
                                                            multiple
                                                            placeholder="Pilih Penulis"
                                                            labelKey="name"
                                                            options={optionPenulis}
                                                            onChange={(e) => this.handleChangePenulis(e)}
                                                            selected={this.state.animasiPenulis}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-email-Input">URL Gambar Thumbnail</Label>
                                                        <Input
                                                            id="animasiThumbnail"
                                                            type="input"
                                                            placeholder="Input Link Thumbnail"
                                                            onChange={(e) => this.setState({ animasiGambar: e.target.value })}
                                                            value={this.state.animasiGambar}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-email-Input">URL Video</Label>
                                                        <Input
                                                            id="animasiVideo"
                                                            type="input"
                                                            placeholder="Input Link Video"
                                                            onChange={(e) => this.setState({ animasiVideo: e.target.value })}
                                                            value={this.state.animasiVideo}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-InputCity">Spesialis</Label>
                                                        <Typeahead
                                                            id="basic-typeahead-multiple"
                                                            multiple
                                                            placeholder="Pilih Spesialis"
                                                            labelKey="name"
                                                            options={option}
                                                            onChange={(e) => this.handleChangeSpesialis(e)}
                                                            selected={this.state.animasiSpesialis}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-InputState">Molekul</Label>
                                                        <Typeahead
                                                            id="basic-typeahead-multiple"
                                                            multiple
                                                            placeholder="Pilih Molekul"
                                                            labelKey="name"
                                                            options={optionMolekul}
                                                            onChange={(e) => this.handleChangeMolekul(e)}
                                                            selected={this.state.animasiMolekul}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-InputZip">Penyakit</Label>
                                                        <Typeahead
                                                            id="basic-typeahead-multiple"
                                                            multiple
                                                            placeholder="Pilih Penyakit"
                                                            labelKey="name"
                                                            options={optionPenyakit}
                                                            onChange={(e) => this.handleChangePenyakit(e)}
                                                            selected={this.state.animasiPenyakit}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 10 }}>
                            <Col xs="12" md="12">
                                <Card>
                                    <CardBody>
                                        <Form method="post">
                                            <Editor
                                                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                                                initialValue=""
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic backcolor | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent lineheight | ' +
                                                        'removeformat',
                                                    content_style: 'body { font-family:Poppins,Arial,sans-serif; }',
                                                    line_height_formats: '0.2 0.4 0.6 0.8 1 1.2 1.4 1.6 2',
                                                }}
                                                onChange={this.handleEditorChange}
                                            />
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 10 }}>
                            <Col xs="12" md="12">
                                <Card>
                                    <Button type="submit" className="btn btn-primary w-md" onClick={() => this.saveAnimasi()}>
                                        Submit Animasi Penggunaan Obat
                                    </Button>
                                </Card>
                            </Col>
                        </Row>
                        {/* <Row md="10" style={{ paddingTop: 8, justifyContent: "end", alignItems: "end" }}>
                            <Button color="danger" onClick={() => this.saveanimasi()}>
                                Submit Artikel
                            </Button>
                        </Row> */}
                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

export default TambahAnimasi;
