import React, { Component } from "react"
import { Row, Col, Card, CardBody, Button, Form, FormGroup, Label, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import moment from 'moment'

import { AvForm, AvField } from 'availity-reactstrap-validation';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import SweetAlert from 'react-bootstrap-sweetalert';
import cellEditFactory from 'react-bootstrap-table2-editor';


import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss";


import httprequester from '../../lib/httprequester'

class MasterMolekul extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      ListMolekul: [],
      alert: false,
      loading: false,
      message: '',
      kategori_id: null,
      id: null,
      nama: '',
      validation: false,
      hasilEdit: null,
      oldEdit: '',
      hasilEditId: 0,
      formUbah: false,
      formDelete: false
    }

  }

  handlecancel = () => {
    this.setState({
      alert: false,
      validation: false
    })
  }

  save = () => {

    this.setState({
      loading: true
    })

    if (this.state.nama) {
      let body = {
        "molekul_name": this.state.nama,
      }

      httprequester.postnobody('artikel/saveMolekul', body, {
        success: async function (response) {
          if (response.data.message == "success") {
            this.setState({
              message: response.data.detailMessage,
              alert: true,
              loading: false,
              nama: '',
              validation: false
            })
            this.getLOV()
          }
          // console.log(response)
        }.bind(this),
        error: function (err) {

        }.bind(this)

      })
    } else {
      this.setState({
        loading: false,
        validation: true
      })
    }



  }

  deletePopUp = (id) => {
    let pencarian = this.state.ListMolekul.find(i => i.molekul_id == id)
    this.setState({
      hasilEditId: id,
      hasilEdit: pencarian.molekul_name,
      formDelete: true
    })
  }

  deleteMolekul = () => {
    let body = {
      molekul_id: this.state.hasilEditId,
      molekul_name: this.state.hasilEdit
    }

    this.setState({
      loading: true,
      formDelete: false
    })

    httprequester.postnobody('artikel/deleteMolekul', body, {
      success: async function (response) {
        // if (response.data.message == "success") {
        this.setState({
          message: response.data.detailMessage,
          alert: true,
          loading: false,
          nama: '',
          validation: false
        })
        this.getLOV()
        // }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
    console.log(body)
  }

  ubahPopUp = (id) => {
    let pencarian = this.state.ListMolekul.find(i => i.molekul_id == id)
    // console.log('pencarian', pencarian)
    this.setState({
      hasilEdit: pencarian.molekul_name,
      oldEdit: pencarian.molekul_name,
      hasilEditId: pencarian.molekul_id,
      formUbah: true
    })
  }

  updateMolekul(e) {
    let body = {
      molekul_id: this.state.hasilEditId,
      molekul_name: e,
      old_molekul: this.state.oldEdit
    }

    this.setState({
      loading: true,
      formUbah: false
    })

    httprequester.postnobody('artikel/updateMolekul', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          this.setState({
            message: response.data.detailMessage,
            alert: true,
            loading: false,
            nama: '',
            validation: false
          })
          this.getLOV()
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  getLOV = () => {
    httprequester.get('artikel/lov', {
      success: async function (response) {
        if (response.data.message == "success") {
          this.setState({
            ListMolekul: response.data.detailMolekul,
            // alert: true,
          })
          // this.getKategoriTags()
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  componentDidMount() {
    this.getLOV()
  }

  cancel = () => {
    this.setState({
      openmodal: false
    })
  }

  handleselect = (e) => {
    this.setState({
      kategori_id: e.target.value,
      validation: false
    })
    // console.log('this', this.state.kategori_id)
  }


  render() {

    const columns = [
      {
        dataField: 'molekul_id',
        text: 'Nama ID',
        sort: true,
        hidden: true
      },
      {
        dataField: 'molekul_name',
        text: 'Nama Molekul',
        sort: true
      }, {
        dataField: 'molekul_id',
        editable: false,
        sort: false,
        formatter: (row) => {
          return <div>
            <Button color="success" className="btn-sm" onClick={(e) => this.ubahPopUp(row)}>
              Ubah
            </Button>&nbsp;&nbsp;
            <Button color="danger" className="btn-sm" onClick={(e) => this.deletePopUp(row)}>
              Hapus
            </Button>

          </div>
        }
      }];

    const defaultSorted = [{
      dataField: 'molekul_name',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.state.ListMolekul.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.ListMolekul).length }];


    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Master Molekul | Ruang Ilmiah</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Master" breadcrumbItem="Molekul" />
            {
              (this.state.formUbah) ?
                <SweetAlert
                  input
                  inputType="text"
                  defaultValue={this.state.hasilEdit}
                  title="Ubah Molekul"
                  validationMsg="Nama Molekul Tidak Boleh Kosong"
                  onConfirm={(e) => this.updateMolekul(e)}
                  onCancel={() => this.setState({ formUbah: false })}
                  showCancel={true}
                /> :
                null
            }
            {
              (this.state.formDelete) ?
                <SweetAlert
                  title={"Hapus Molekul " + this.state.hasilEdit}
                  onConfirm={(e) => this.deleteMolekul(e)}
                  onCancel={() => this.setState({ formDelete: false })}
                  showCancel={true}
                /> :
                null
            }
            <Row>
              <Col xs="12" md="4">
                <Form>
                  <FormGroup>
                    <Label for="exampleText">
                      Nama Molekul
                    </Label>
                    <Input
                      id="exampleText"
                      name="text"
                      onChange={(e) => this.setState({ nama: e.target.value })}
                      value={this.state.nama}
                      type="text"
                    />
                  </FormGroup>
                  {this.state.validation ? <p style={{ color: 'red' }}>Nama molekul harus di isi</p> : ''}
                  <br />
                  <Button color='danger' onClick={this.save}>
                    {this.state.loading ? 'Loading...' : 'Submit'}
                  </Button>
                </Form>
              </Col>
            </Row>
            <br />

            <Row>
              <Col className="col-12">
                {/* <BootstrapTable
                  keyField="id"
                  data={this.state.ListMolekul}
                  columns={columns}
                  cellEdit={cellEditFactory({ mode: 'click' })}
                /> */}
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='molekul_name'
                      columns={columns}
                      data={this.state.ListMolekul}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='molekul_name'
                          columns={columns}
                          data={this.state.ListMolekul}
                          search
                          cellEdit={cellEditFactory({ mode: 'click' })}
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      columns={columns}
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      // defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      // cellEdit={cellEditFactory({
                                      //   mode: 'click',
                                      //   blurToSave: true,
                                      //   afterSaveCell: (oldValue, newValue, row, column) => {
                                      //     this.setState({
                                      //       hasilEdit: newValue
                                      //     })
                                      //   }
                                      // })}
                                      cellEdit={cellEditFactory({ mode: 'click' })}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          {this.state.alert ?
            <SweetAlert title={this.state.message} onConfirm={this.handlecancel}>
            </SweetAlert> : ''
          }

          {this.state.loading ? (
            <SweetAlert title="menghubungkan ke server" showConfirm={false} >
            </SweetAlert>
          ) : null}
        </div>
      </React.Fragment>
    )
  }
}

export default MasterMolekul