import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import Axios from "axios";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { Row, Col, Button, Card, CardBody, CardTitle, Form, Input, Label, Table } from 'reactstrap';
import { Container, } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb";

import httprequester from '../../lib/httprequester'

import { urlAPI } from '../../config';

class Edit extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        match: PropTypes.number.isRequired
    }

    constructor(props) {
        super(props);
        // this.onChangeUsername = this.onChangeAdminName.bind(this);
        this.onChangeAdminPassword = this.onChangeAdminPassword.bind(this);
        this.updateAdmin = this.updateAdmin.bind(this);
        this.getAdmin = this.getAdmin.bind(this);

        this.state = {
            admin_name: "",
            admin_password: "",
            admin_status: "A",
            admin_previlege: [],
            successful: false,
            message: "",

            // START FROM HERE
            admin: {
                name: "admin",
                access: "N"
            },
            user: {
                name: "user",
                access: "N"
            },
            jurnal_tatalaksana: {
                name: "jurnal-tata-laksana",
                access: "N"
            },
            ebook: {
                name: "ebook",
                access: "N"
            },
            webinar: {
                name: "webinar",
                access: "N"
            },
            tindakan: {
                name: "tindakan-medis-spesialistik",
                access: "N"
            },
            animasi: {
                name: "animasi-penggunaan-obat",
                access: "N"
            },
            acara_ilmiah: {
                name: "acara-ilmiah",
                access: "N"
            },
            artikel: {
                name: "artikel",
                access: "N"
            },
            cme_online: {
                name: "cme-online",
                access: "N"
            },
            tanya_ahli: {
                name: "tanya-ahli",
                access: "N"
            },
            master: {
                name: "master",
                access: "N"
            },
            file_manajer: {
                name: "file-manajer",
                access: "N"
            },
            headline: {
                name: "headline",
                access: "N"
            },
        };

    }

    componentDidMount() {
        this.getAdmin(this.props.match.params.admin_id);
    }

    // onChangeAdminName(e) {
    //     this.setState({
    //         admin_name: e.target.value
    //     });
    // }

    onChangeAdminPassword(e) {
        this.setState({
            admin_password: e.target.value
        });
    }

    changeStatus(e, obj, name) {
        let x = name
        let y = (e.target.checked) ? "Y" : "N"

        this.setState({
            [obj]: { name: x, access: y }
        })
    }

    getAdmin = (id) => {
        let body = { admin_id: id };

        httprequester.getqueryparam('admin/getall?id=' + this.props.match.params.id, body, {
            success: async function (response) {
                if (response.data.message == "success") {
                    let { admin_name, admin_previlege } = response.data.detailMessage[0]
                    let data = JSON.parse(admin_previlege)

                    this.setState(
                        {
                            admin_name: admin_name,
                            admin: { ...data[0] },
                            user: { ...data[1] },
                            jurnal_tatalaksana: { ...data[2] },
                            ebook: { ...data[3] },
                            webinar: { ...data[4] },
                            tindakan: { ...data[5] },
                            animasi: { ...data[6] },
                            acara_ilmiah: { ...data[7] },
                            artikel: { ...data[8] },
                            cme_online: { ...data[9] },
                            tanya_ahli: { ...data[10] },
                            file_manajer: { ...data[11] },
                            master: { ...data[12] },
                            headline: { ...data[13] }
                        })
                }
            }.bind(this),
            error: function (err) {}.bind(this)
        });
    }

    async updateAdmin(e) {
      e.preventDefault();

      let data = {
        admin_id: this.props.match.params.id,
        admin_name: this.state.admin_name,
        admin_password: this.state.admin_password,
        admin_status: this.state.admin_status,
        admin_previlege: [
          this.state.admin,
          this.state.user,
          this.state.jurnal_tatalaksana,
          this.state.ebook,
          this.state.webinar,
          this.state.tindakan,
          this.state.animasi,
          this.state.acara_ilmiah,
          this.state.artikel,
          this.state.cme_online,
          this.state.tanya_ahli,
          this.state.master,
          this.state.file_manajer,
          this.state.headline,
        ],
      };

      try {
        const config = {
          headers: {
            Authorization: `Bearer ${httprequester.get_token()}`,
          },
        };

        await Axios.post(urlAPI + 'admin/update', data, config);
        this.props.history.push('/list-admin');
      } catch (err) {
        throw err;
      }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Update Admin</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Administrator" breadcrumbItem="Update Admin" />
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md="10" />
                                    <Col md="2">
                                        <Link to="/list-admin">
                                            <Button color="btn-sm btn btn-primary">
                                                Back
                                            </Button>
                                        </Link>
                                    </Col>
                                </Row>
                                <CardTitle className="mb-4">UPDATE ADMINISTRATOR</CardTitle>

                                <Form onSubmit={this.updateAdmin}>
                                    <div className="row mb-3">
                                        <Label
                                            htmlFor="horizontal-firstname-Input"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Admin Name Update
                                        </Label>
                                        <Col sm={9}>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="horizontal-firstname-Input"
                                                name="admin_name"
                                                value={this.state.admin_name}
                                                onChange={this.onChangeAdminName}
                                                placeholder="Admin Name"
                                            />
                                        </Col>
                                    </div>
                                    <div className="row mb-3">
                                        <Label
                                            htmlFor="horizontal-email-Input"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Admin Password Update
                                        </Label>
                                        <Col sm={9}>
                                            <Input
                                                type="password"
                                                className="form-control"
                                                id="horizontal-email-Input"
                                                value={this.state.admin_password}
                                                onChange={this.onChangeAdminPassword}
                                                placeholder="Admin Password"
                                            />
                                        </Col>
                                    </div>
                                    <div className="table-responsive mt-5">
                                        <Table className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th width="1px">No</th>
                                                    <th width="200px">Menu</th>
                                                    <th width="100px">Hak Akses</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">1</th>
                                                    <td>Admin</td>
                                                    <td>
                                                        <input
                                                          className="form-check-Input"
                                                          type="checkbox"
                                                          value="Y"
                                                          checked={this.state.admin.access === 'Y'}
                                                          onChange={(e) => this.changeStatus(e, "admin", "admin")}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">2</th>
                                                    <td>User</td>
                                                    <td>
                                                        <input
                                                          className="form-check-Input"
                                                          type="checkbox"
                                                          value="Y"
                                                          checked={this.state.user.access === 'Y'}
                                                          onChange={(e) => this.changeStatus(e, "user", "user")}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">3</th>
                                                    <td>Jurnal & Tata Laksana</td>
                                                    <td>
                                                        <input
                                                          className="form-check-Input"
                                                          type="checkbox"
                                                          value="Y"
                                                          checked={this.state.jurnal_tatalaksana.access === 'Y'}
                                                          onChange={(e) => this.changeStatus(e, "jurnal_tatalaksana", "jurnal-tata-laksana")}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">4</th>
                                                    <td>eBook</td>
                                                    <td>
                                                        <input
                                                          className="form-check-Input"
                                                          type="checkbox"
                                                          value="Y"
                                                          checked={this.state.ebook.access === 'Y'}
                                                          onChange={(e) => this.changeStatus(e, "ebook", "ebook")}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">5</th>
                                                    <td>Webinar</td>
                                                    <td>
                                                        <input
                                                          className="form-check-Input"
                                                          type="checkbox"
                                                          value="Y"
                                                          checked={this.state.webinar.access === 'Y'}
                                                          onChange={(e) => this.changeStatus(e, "webinar", "webinar")}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">6</th>
                                                    <td>Tindakan Medis Spesialistik</td>
                                                    <td>
                                                        <input
                                                          className="form-check-Input"
                                                          type="checkbox"
                                                          value="Y"
                                                          checked={this.state.tindakan.access === 'Y'}
                                                          onChange={(e) => this.changeStatus(e, "tindakan", "tindakan-medis-spesialistik")}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">7</th>
                                                    <td>Animasi Penggunaan Obat</td>
                                                    <td>
                                                        <input
                                                          className="form-check-Input"
                                                          type="checkbox"
                                                          value="Y"
                                                          checked={this.state.animasi.access === 'Y'}
                                                          onChange={(e) => this.changeStatus(e, "animasi", "animasi-penggunaan-obat")}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">8</th>
                                                    <td>Acara Ilmiah</td>
                                                    <td>
                                                        <input
                                                          className="form-check-Input"
                                                          type="checkbox"
                                                          value="Y"
                                                          checked={this.state.acara_ilmiah.access === 'Y'}
                                                          onChange={(e) => this.changeStatus(e, "acara_ilmiah", "acara-ilmiah")}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">9</th>
                                                    <td>Artikel</td>
                                                    <td>
                                                        <input
                                                          className="form-check-Input"
                                                          type="checkbox"
                                                          value="Y"
                                                          checked={this.state.artikel.access === 'Y'}
                                                          onChange={(e) => this.changeStatus(e, "artikel", "artikel")}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">10</th>
                                                    <td>CME Online</td>
                                                    <td>
                                                        <input
                                                          className="form-check-Input"
                                                          type="checkbox"
                                                          value="Y"
                                                          checked={this.state.cme_online.access === 'Y'}
                                                          onChange={(e) => this.changeStatus(e, "cme_online", "cme-online")}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">11</th>
                                                    <td>Tanya Ahli</td>
                                                    <td>
                                                        <input
                                                          className="form-check-Input"
                                                          type="checkbox"
                                                          value="Y"
                                                          checked={this.state.tanya_ahli.access === 'Y'}
                                                          onChange={(e) => this.changeStatus(e, "tanya_ahli", "tanya-ahli")}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">12</th>
                                                    <td>Master</td>
                                                    <td>
                                                        <input
                                                          className="form-check-Input"
                                                          type="checkbox"
                                                          value="Y"
                                                          checked={this.state.master.access === 'Y'}
                                                          onChange={(e) => this.changeStatus(e, "master", "master")}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">13</th>
                                                    <td>File Manajer</td>
                                                    <td>
                                                        <input
                                                          className="form-check-Input"
                                                          type="checkbox"
                                                          value="Y"
                                                          checked={this.state.file_manajer.access === 'Y'}
                                                          onChange={(e) => this.changeStatus(e, "file_manajer", "file-manajer")}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">14</th>
                                                    <td>Headline</td>
                                                    <td>
                                                        <input
                                                          className="form-check-Input"
                                                          type="checkbox"
                                                          value="Y"
                                                          checked={this.state.headline.access === 'Y'}
                                                          onChange={(e) => this.changeStatus(e, "headline", "headline")}
                                                        />
                                                    </td>
                                                </tr>
                                                <div className="row justify-content-end">
                                                    <Col sm={12}>
                                                        <div>
                                                            <Button type="submit" color="primary" className="w-md">
                                                              Save
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </div>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default Edit;