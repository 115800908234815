import React, { Component, createRef } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Form,
    Label,
    CardGroup,
    CardImg
} from 'reactstrap';
import PropTypes from 'prop-types';
import {
    Container,
} from "reactstrap"
import httprequester from '../../lib/httprequester'

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import { AvForm, AvField } from 'availity-reactstrap-validation';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withRouter } from 'react-router'

import { Typeahead } from 'react-bootstrap-typeahead';
// import { Editor } from 'react-draft-wysiwyg';
// import { EditorState, ContentState, convertFromHTML } from 'draft-js';
// import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// const EditorComponent = () => <Editor />

import { Editor } from '@tinymce/tinymce-react';
// import image from "../../../public/"
import moment from 'moment'


class addEvent extends Component {
    constructor(props) {
        super(props);
        this.fileRef = createRef();
        this.state = {
            basic: false,
            listSpesialistoption: [],
            listLokasioption: [],
            listPenyelenggara: [],
            hasil: [],
            hasilLokasi: [],
            hasilpenyelenggara: [],
            deskripsi: null,
            nama_acara: '',
            alamat: '',
            tanggal: '',
            tanggalselesai:'',
            waktuselesai: '',
            articleKonten: '',
            jenis_acara: '',
            admin_type: '',
            link_acara: '',
            message: '',
            org_id: null,
            upload: null,
            user_image: '/ruang-ilmiah.png',
            prosess:false
        };
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    onCancel = () => {
        this.setState({
            basic: false
        })
    }

    handleclick = () => {
        this.fileRef.current.click()
    }

    handleEditorChange = (e) => {
        // console.log('as', e.target.getContent())
        this.setState({
            deskripsi: e.target.getContent()
        })
    }

    direct = () => {
        this.setState({
            basic: false
        })
        this.props.history.push('/event')
    }

    getLOV = () => {
        httprequester.get('event/lov', {
            success: async function (response) {
                // console.log("res => ", response.data.detailSpecialist)
                if (response.data.message == "success") {
                    this.setState({
                        listPenyelenggara: response.data.detailOrganisasi,
                        listSpesialistoption: response.data.detailSpecialist,
                        listLokasioption: response.data.detailCity
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    componentDidMount() {
        const { org_name, admin_name, org_id, admin_type } = JSON.parse(localStorage.getItem('user'))
        // console.log('hasil',admin)
        this.setState({
            penyelenggara: org_name ? org_name : admin_name,
            org_id: org_id,
            admin_type: admin_type
        })
        // console.log('ad', org_name)
        this.getLOV();
    }

    handleupload = (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            this.setState({
                user_image: [reader.result]
            })
        }.bind(this);
        this.setState({
            upload: file,
            user_image: url
        })
    }



    saveEvent = () => {
        if (this.state.admin_type == 'organisasi') {
            let event_id = moment().format('YYYYMMDDHHmmss') + this.state.org_id
            let event_date = this.state.tanggal + 'T' + this.state.waktu + ':00Z'
            let event_date_end = this.state.tanggalselesai + 'T' + this.state.waktuselesai + ':00Z'

            let formData = new FormData();
            formData.append("myfile", this.state.upload ? this.state.upload : this.state.user_image);
            formData.append("event_id", event_id);
            formData.append("event_name", this.state.nama_acara);
            formData.append("event_organization", this.state.hasilpenyelenggara.length > 0 ? this.state.hasilpenyelenggara[0] : this.state.penyelenggara);
            formData.append("event_spesialisasi", this.state.hasil.toString());
            formData.append("event_date", event_date);
            formData.append("event_date_end", event_date_end)
            formData.append("event_location", this.state.hasilLokasi[0]);
            formData.append("event_type", this.state.jenis_acara);
            formData.append("event_description", this.state.deskripsi);
            formData.append("event_link", this.state.link_acara);
            formData.append("created_by", this.state.penyelenggara);
            formData.append("event_status", 'Unpublished');

            // console.log('data', formData)
            this.setState({
                prosess:true
            })
            httprequester.postupload('event/save', formData, {
                success: async function (response) {
                    if (response.data.message == "success") {
                        this.setState({
                            basic: true,
                            message: response.data.detailMessage,
                            prosess:false
                        })
                        // this.fileUploads.current
                        // e.target.value = null;
                        // this.fileUploads.current.value
                        // this.getLOV()
                    }
                    console.log('ini', this.fileUploads)
                }.bind(this),
                error: function (err) {

                }.bind(this)

            })
        } else {
            // console.log('waktu',this.state.waktu)
            let data = this.state.listPenyelenggara.find(i => i.org_name == this.state.hasilpenyelenggara[0])
            // console.log('hasil', data)
            let event_id = moment().format('YYYYMMDDHHmmss') + data.org_id
            let event_date = this.state.tanggal + 'T' + this.state.waktu + ':00Z'
            let event_date_end = this.state.tanggalselesai + 'T' + this.state.waktuselesai + ':00Z'

            let formData = new FormData();
            formData.append("myfile", this.state.upload ? this.state.upload : this.state.user_image);
            formData.append("event_id", event_id);
            formData.append("event_name", this.state.nama_acara);
            formData.append("event_organization", this.state.hasilpenyelenggara.length > 0 ? this.state.hasilpenyelenggara[0] : this.state.penyelenggara);
            formData.append("event_spesialisasi", this.state.hasil.toString());
            formData.append("event_date", event_date);
            formData.append("event_date_end", event_date_end)
            formData.append("event_location", this.state.hasilLokasi[0]);
            formData.append("event_type", this.state.jenis_acara);
            formData.append("event_description", this.state.deskripsi);
            formData.append("event_link", this.state.link_acara);
            formData.append("created_by", this.state.penyelenggara);
            formData.append("event_status", 'Unpublished');

            // console.log('data', formData)
            this.setState({
                prosess:true
            })
            httprequester.postupload('event/save', formData, {
                success: async function (response) {
                    if (response.data.message == "success") {
                        this.setState({
                            basic: true,
                            message: response.data.detailMessage,
                            prosess:false
                        })
                        // this.fileUploads.current
                        // e.target.value = null;
                        // this.fileUploads.current.value
                        // this.getLOV()
                    }
                    // console.log('ini', this.fileUploads)
                }.bind(this),
                error: function (err) {

                }.bind(this)

            })
        }


    }

    render() {
        // console.log("hacep => ", this.state.articleKonten)




        const option = this.state.listSpesialistoption.map(o => o.specialist_name)
        const optionLokasi = this.state.listLokasioption.map(o => o.city_name)
        const optionPenyelenggara = this.state.listPenyelenggara.map(o => o.org_name)

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Tambah Acara || Ruang Ilmiah</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Acara" breadcrumbItem="Tambah Acara" />

                        {this.state.basic ? (
                            <SweetAlert success title={this.state.message} onConfirm={this.direct}>
                            </SweetAlert>
                        ) : null}

                        {this.state.prosess ? (
                            <SweetAlert title="proses simpan ..." showConfirm={false} >
                            </SweetAlert>
                        ) : null}

                        <AvForm onValidSubmit={this.saveEvent}>
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardBody>
                                            <Form>
                                                <div className="row mb-4">
                                                    <Label for="exampleEmail">
                                                        Foto
                                                    </Label>
                                                    <CardGroup>
                                                        <Card>
                                                            <CardImg
                                                                alt="Card image cap"
                                                                src={this.state.user_image}
                                                                top
                                                                width="100%"
                                                            />
                                                            <CardBody>
                                                                <Button style={{ width: '100%' }} onClick={this.handleclick}>
                                                                    Upload Foto
                                                                </Button>
                                                                <input style={{ display: 'none' }} type="file" accept=".png,.jpg,.jpeg" ref={this.fileRef} onChange={this.handleupload} />
                                                            </CardBody>
                                                        </Card>
                                                    </CardGroup>
                                                    {/* <AvField
                                                        name="file"
                                                        type="file"
                                                        accept=".png,.jpg,.jpeg" 
                                                        onChange={(e) => this.setState({ filefoto: e.target.files[0] })}
                                                        value={this.state.org_name} required /> */}
                                                </div>
                                                <div className="row mb-4">
                                                    <AvField
                                                        name="judul"
                                                        label="Judul"
                                                        type="text"
                                                        onChange={(e) => this.setState({ nama_acara: e.target.value })}
                                                        value={this.state.org_name} required />
                                                </div>
                                                <div className="row mb-4">
                                                    {this.state.admin_type == 'organisasi' ?
                                                        <AvField
                                                            name="penyelenggara"
                                                            label="Penyelenggara"
                                                            type="text"
                                                            value={this.state.penyelenggara}
                                                            disabled /> : <div>
                                                            <Label for="exampleSpesialisasi">
                                                                Penyelenggara
                                                            </Label>
                                                            <Typeahead
                                                                id="basic-typeahead-single"
                                                                labelKey="name"
                                                                onChange={(e) => this.setState({ hasilpenyelenggara: e })}
                                                                options={optionPenyelenggara}
                                                                placeholder="pilih penyelenggara"
                                                                selected={this.state.hasilpenyelenggara}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row mb-4">
                                                    <Label for="exampleSpesialisasi">
                                                        Spesialisasi
                                                    </Label>
                                                    <Typeahead
                                                        id="basic-typeahead-multiple"
                                                        multiple
                                                        labelKey="name"
                                                        onChange={(e) => this.setState({ hasil: e })}
                                                        options={option}
                                                        placeholder="pilih spesialisasi"
                                                        selected={this.state.hasil}
                                                    />
                                                </div>
                                                <div className="row mb-4">
                                                    <div className="col-6">
                                                        <AvField
                                                            name="tanggal"
                                                            label="Tanggal Mulai"
                                                            type="date"
                                                            onChange={(e) => this.setState({ tanggal: e.target.value })}
                                                            value={this.state.tanggal}
                                                            required />
                                                    </div>
                                                    <div className="col-6">
                                                        <AvField
                                                            name="waktu"
                                                            label="Waktu Mulai"
                                                            type="time"
                                                            onChange={(e) => this.setState({ waktu: e.target.value })}
                                                            value={this.state.waktu}
                                                            required />
                                                    </div>
                                                </div>
                                                <div className="row mb-4">
                                                    <div className="col-6">
                                                        <AvField
                                                            name="tanggal"
                                                            label="Tanggal Selesai"
                                                            type="date"
                                                            onChange={(e) => this.setState({ tanggalselesai: e.target.value })}
                                                            value={this.state.tanggalselesai}
                                                            required />
                                                    </div>
                                                    <div className="col-6">
                                                        <AvField
                                                            name="waktu"
                                                            label="Waktu Selesai"
                                                            type="time"
                                                            onChange={(e) => this.setState({ waktuselesai: e.target.value })}
                                                            value={this.state.waktuselesai}
                                                            required />
                                                    </div>
                                                </div>
                                                <div className="row mb-4">
                                                    <AvField
                                                        name="linkacara"
                                                        label="Link Acara"
                                                        type="text"
                                                        onChange={(e) => this.setState({ link_acara: e.target.value })}
                                                        value={this.state.link_acara}
                                                    />
                                                </div>
                                                <div className="row mb-4">
                                                    <Label for="exampleLokasi">
                                                        Lokasi
                                                    </Label>
                                                    <Typeahead
                                                        id="basic-typeahead-single"
                                                        labelKey="name"
                                                        onChange={(e) => this.setState({ hasilLokasi: e })}
                                                        options={optionLokasi}
                                                        placeholder="pilih lokasi"
                                                        selected={this.state.hasilLokasi}
                                                    />
                                                </div>
                                                <div className="row mb-4">
                                                    <AvField type="select" name="select" label="Jenis Acara" onChange={(e) => this.setState({ jenis_acara: e.target.value })}>
                                                        <option>pilih</option>
                                                        <option value="Online">Online</option>
                                                        <option value="Offline">Offllne</option>
                                                        <option value="Hybrid">Hybrid</option>
                                                    </AvField>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label for="exampledeskripsi">
                                                        Deskripsi
                                                    </Label>
                                                    <Form method="post">
                                                        <Editor
                                                            apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                                                            content={this.state.deskripsi}
                                                            init={{
                                                                height: 500,
                                                                menubar: false,
                                                                plugins: [
                                                                    'print preview',
                                                                    'importcss searchreplace autolink autosave save',
                                                                    'directionality  visualblocks visualchars fullscreen image link media',
                                                                    'template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists',
                                                                    'wordcount imagetools textpattern noneditable help',
                                                                    'charmap quickbars emoticons',

                                                                ],
                                                                toolbar:
                                                                    'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | image',
                                                                // images_upload_handler: function (blobInfo, success, failure) {
                                                                //     let data = new FormData();
                                                                //     data.append('myfile', blobInfo.blob(), blobInfo.filename());
                                                                //     console.log(data)
                                                                //     axios.post('http://127.0.0.1:3333/api/v1/filemanager/uploadtest', data)
                                                                //         .then(function (res) {
                                                                //             success(res.data.detailMessage);
                                                                //         })
                                                                //         .catch(function (err) {
                                                                //             console.log(err)
                                                                //             failure('HTTP Error: ' + err.message);
                                                                //         });
                                                                // }
                                                            }}
                                                            onChange={this.handleEditorChange}
                                                        />
                                                    </Form>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: 10 }}>
                                <Col xs="12" md="12">
                                    <Card>
                                        <Button color="danger" >
                                            Simpan Acara
                                        </Button>
                                    </Card>
                                </Col>
                            </Row>
                        </AvForm>
                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

export default withRouter(addEvent);
