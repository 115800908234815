import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardGroup,
    CardImg,
    CardTitle,
    CardSubtitle,
    CardText,
    Form,
    FormGroup,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Alert
} from 'reactstrap';
import { Link } from "react-router-dom";
import {
    Container,
} from "reactstrap"
import httprequester from '../../lib/httprequester'

import { isEmpty, map, size } from "lodash"
import { withRouter } from 'react-router'
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Typeahead } from 'react-bootstrap-typeahead';
import moment from "moment";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TambahTanyaAhli from "./TambahTanyaAhli";

class TanyaAhli extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            sizePerPage: 10,
            totalPage: [],
            listTanyaAhli: [],
            listCategory: [],
            listValue: [],
            alert: false,
            openmodal: false,
            type: '',
            // modal: false,
            message: '',
            urlimage: '',
            id: null,
            categoriesArticle: '',
            statusTanyaAhli: 'Published',
            searchTanyaAhli: '',
            valueJurnal: '',
            urutkanJurnal: '',
            alertcopy: false,
            namaalert: '',
            listPenyakit: [],
            listMolekul: [],
            listPengarang: [],
            listSpesialisasi: [],
            hasilPengarang: [],
            hasilPenyakit: [],
            hasilSpesialisasi: [],
            hasilMolekul: [],
            listNarasumber:[],
            statusTanyaAhliSearch:''
        }
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    componentDidMount() {
        this.getTanyaAhli();
        this.getNarasumber()
        // this.getLov("jurnal")
    }

    getNarasumber = () => {
        httprequester.get('ahli/get-narasumber', {
            success: async function (response) {

                // console.log("resLOV => ", response)
                if (response.data.message == "success") {
                    this.setState({
                        listNarasumber: response.data.detailMessage
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }


    getLov(type) {
        let body = {
            hp_type: type
        }

        httprequester.postarticle('homepage/lov_content', body, {
            success: async function (response) {

                let { data } = response;
                // let { detailMessage } = response.data;
                this.setState({
                    listMolekul: data.resultMolekul,
                    listPengarang: data.resultPenulis,
                    listPenyakit: data.resultPenyakit,
                    listSpesialisasi: data.resultSpecialist
                })
                // console.log("artikel => ", this.state.listArtikel)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    handlePageClick = (e) => {
        let a = e
        this.setState({
            page: a
        })

        let narasumber = this.state.hasilPengarang[0] ? this.state.hasilPengarang[0] : ''
        // let molekul = this.state.hasilMolekul[0] ? this.state.hasilMolekul[0] : ''
        // let spesialisasi = this.state.hasilSpesialisasi[0] ? this.state.hasilSpesialisasi[0] : ''
        let urutan = this.state.statusTanyaAhliSearch ? this.state.statusTanyaAhliSearch : 'created_at DESC'
        let body = {
            "spesialisasi": '',
            "search": this.state.searchTanyaAhli,
            "penyakit": '',
            "status": this.state.statusTanyaAhli,
            "molekul": '',
            "penulis": narasumber,
            "page": e,
            "limit": 10,
            "urutan": urutan,
            "type": this.state.type
        }

        // console.log("body =>", body)
        httprequester.postarticle('ahli/getall-new', body, {
            success: async function (response) {
                let data = []
                // let cek = response.data.result.data
                // console.log("res => ", cek)
                if (response.data.message == "success") {

                    let pages = []
                    for (let index = 1; index <= response.data.result.lastPage; index++) {
                        pages.push(index)
                    }
                    // if(response.data.result.data.length)
                    response.data.result.data.dataRows.forEach(async (v, key) => {
                        await data.push({
                            tanya_id: v[0],
                            tanya_kuota: v[1],
                            tanya_thumbnail: v[2],
                            tanya_periode_start: v[3],
                            created_at: v[4],
                            tanya_molekul: v[5],
                            tanya_kuota_left: v[6],
                            tanya_spesialisasi: v[7],
                            tanya_type: v[8],
                            updated_at: v[9],
                            tanya_status: v[10],
                            tanya_periode_end: v[11],
                            tanya_narasumber: v[12],
                            tanya_penyakit: v[14],
                            tanya_name: v[15],
                            tanya_content_id: v[16],
                            tanya_slug: v[17],
                            tanya_summary: v[18]
                        })
                    })
                    this.setState({
                        listTanyaAhli: data,
                        page: response.data.result.page,
                        sizePerPage: response.data.result.perPage,
                        totalPage: pages,
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    getTanyaAhli = () => {
        let narasumber = this.state.hasilPengarang[0] ? this.state.hasilPengarang[0] : ''
        // let molekul = this.state.hasilMolekul[0] ? this.state.hasilMolekul[0] : ''
        // let spesialisasi = this.state.hasilSpesialisasi[0] ? this.state.hasilSpesialisasi[0] : ''
        let urutan = this.state.statusTanyaAhliSearch ? this.state.statusTanyaAhliSearch : 'created_at DESC'
        let body = {
            "spesialisasi": '',
            "search": this.state.searchTanyaAhli,
            "penyakit": '',
            "status": this.state.statusTanyaAhli,
            "molekul": '',
            "narasumber": narasumber,
            "page": 1,
            "limit": 10,
            "urutan": urutan,
            "type": this.state.type
        }

        // console.log("body =>", body)
        httprequester.postarticle('ahli/getall-new', body, {
            success: async function (response) {
                let data = []
                // let cek = response.data.result.data
                // console.log("res => ", cek)
                if (response.data.message == "success") {

                    let pages = []
                    for (let index = 1; index <= response.data.result.lastPage; index++) {
                        pages.push(index)
                    }
                    // if(response.data.result.data.length)
                    response.data.result.data.dataRows.forEach(async (v, key) => {
                        await data.push({
                            tanya_id: v[0],
                            tanya_kuota: v[1],
                            tanya_thumbnail: v[2],
                            tanya_periode_start: v[3],
                            created_at: v[4],
                            tanya_molekul: v[5],
                            tanya_kuota_left: v[6],
                            tanya_spesialisasi: v[7],
                            tanya_type: v[8],
                            updated_at: v[9],
                            tanya_status: v[10],
                            tanya_periode_end: v[11],
                            tanya_narasumber: v[12],
                            tanya_penyakit: v[14],
                            tanya_name: v[15],
                            tanya_content_id: v[16],
                            tanya_slug: v[17],
                            tanya_summary: v[18]
                        })
                    })
                    this.setState({
                        listTanyaAhli: data,
                        page: response.data.result.page,
                        sizePerPage: response.data.result.perPage,
                        totalPage: pages,
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    reset = () => {
        let body = {
            "spesialisasi": "",
            "search": "",
            "penyakit": "",
            "status": this.state.statusTanyaAhli,
            "molekul": "",
            "narasumber": "",
            "page": 1,
            "limit": 10,
            "urutan": "created_at DESC",
            "type": ""
        }

        this.setState({
            hasilPengarang: [],
            searchTanyaAhli:'',
            type:'',
            search: '',
            page: 1,
            statusTanyaAhliSearch:''
        })

        // console.log("body =>", body)
        httprequester.postarticle('ahli/getall-new', body, {
            success: async function (response) {
                let data = []
                // let cek = response.data.result.data
                // console.log("res => ", cek)
                if (response.data.message == "success") {

                    let pages = []
                    for (let index = 1; index <= response.data.result.lastPage; index++) {
                        pages.push(index)
                    }
                    // if(response.data.result.data.length)
                    response.data.result.data.dataRows.forEach(async (v, key) => {
                        await data.push({
                            tanya_id: v[0],
                            tanya_kuota: v[1],
                            tanya_thumbnail: v[2],
                            tanya_periode_start: v[3],
                            created_at: v[4],
                            tanya_molekul: v[5],
                            tanya_kuota_left: v[6],
                            tanya_spesialisasi: v[7],
                            tanya_type: v[8],
                            updated_at: v[9],
                            tanya_status: v[10],
                            tanya_periode_end: v[11],
                            tanya_narasumber: v[12],
                            tanya_penyakit: v[14],
                            tanya_name: v[15],
                            tanya_content_id: v[16],
                            tanya_slug: v[17],
                            tanya_summary: v[18]
                        })
                    })
                    this.setState({
                        listTanyaAhli: data,
                        page: response.data.result.page,
                        sizePerPage: response.data.result.perPage,
                        totalPage: pages,
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    updateStatusJurnal = (value) => {
        let body = {
            "tanya_id": value.tanya_id,
            "tanya_status": value.tanya_status === 'Published' ? 'Unpublished' : 'Published'
        }

        let dx = {
            content_id: value.tanya_id,
            content_penulis: value.tanya_narasumber,
            content_title: value.tanya_name,
            created_at: value.created_at,
            content_slug: value.tanya_slug,
            content_penyakit: value.tanya_penyakit,
            content_molekul: value.tanya_molekul,
            content_spesialis: value.tanya_spesialisasi,
            hp_type: 'tanya_ahli'
        }

        // console.log("bodyUpdate =>", body)
        httprequester.postarticle('ahli/update-new', body, {
            success: async function (response) {
                // console.log("resUpdate => ", response)
                this.setState({
                    alert: true,
                    message: 'success'
                })

                if (body.tanya_status === "Published") {
                    httprequester.postarticle('homepage/lov_construct', dx, {
                        success: async function (responses) {
                            // console.log(responses)
                        }.bind(this),
                        error: function (err) {
                            console.log("error", err)
                        }.bind(this)
                    })
                } else {
                    httprequester.postarticle('homepage/lov_destruct', dx, {
                        success: async function (responses) {
                            //console.log(responses)
                        }.bind(this),
                        error: function (err) {
                            console.log("error", err)
                        }.bind(this)
                    })
                }

                setTimeout(() => {
                    this.getTanyaAhli();
                }, 2000);

                // if (response.data.message == "success") {
                //     this.setState({
                //         listArtikel: data
                //     })
                // }
                // console.log("artikel => ", this.state.listArtikel)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)
        })
    }

    direct = (id, type, status) => {
        // this.setState({
        //     basic: false
        // })
        this.props.history.push(`/tanya-ahli/edit-tanya-ahli/${id}/${type}/${status}`)
    }

    directSEO = (id, type, status) => {
        this.props.history.push(`/seoconfig/${id}/${type}`)
    }

    directDetail = (id, type, status) => {
        this.props.history.push(`/tanya-ahli/tambah-tanya-periode/${id}/${type}/${status}`)
    }

    directDetailListPercakapan = (id, type, status) => {
        this.props.history.push(`/tanya-ahli/list-percakapan/${id}/${type}/${status}`)
    }

    handleType(e) {
        this.setState({ type: e.target.value })
    }

    handlecancel = () => {
        this.setState({
            alert: false
        })
    }

    copyurl = (value) => {
        this.setState({
            namaalert: value.tanya_name,
            alertcopy: true
        })
        navigator.clipboard.writeText(value.tanya_id)
        setTimeout(() => {
            this.setState({
                alertcopy: false
            })
        }, 3000);
    }

    deleteTanyaAhli = (value) => {
        let body = {
            "tanya_id": value.tanya_id,
            "tanya_status": "Deleted"
        }
        // console.log("bodyDelete =>", body)
        httprequester.postarticle('ahli/update-new', body, {
            success: async function (response) {
                // console.log("resDelete => ", response)
                this.setState({
                    message: response.data.detailMessage,
                    alert: true
                })
                setTimeout(() => {
                    this.getTanyaAhli()
                }, 2000);

                // if (response.data.message == "success") {
                //     this.setState({
                //         listArtikel: data
                //     })
                // }
                // console.log("artikel => ", this.state.listArtikel)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)
        })
    }

    render() {
        // console.log("pagenya", this.state.page)
        const { page, totalPage } = this.state
        const option = this.state.listSpesialisasi.map(o => o.ri_lov_content_name)
        const optionPengarang = this.state.listNarasumber.map(o => o.narasumber_name)
        const optionMolekul = this.state.listMolekul.map(o => o.ri_lov_content_name)
        const optionPenyakit = this.state.listPenyakit.map(o => o.ri_lov_content_name)
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Tanya Ahli</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Halaman Utama" breadcrumbItem="Tanya Ahli" />

                        <Row style={{ paddingBottom: 10 }}>
                            <Col xs="12" md="4">
                                <Link to="/tanya-ahli/tambah-tanya-ahli">
                                    <Button color="danger">
                                        Tambah Tanya Ahli
                                    </Button>
                                </Link>
                            </Col>
                        </Row>

                        <div>
                            {this.state.alert ? (
                                <SweetAlert success title={this.state.message} onConfirm={() => this.setState({ alert: false })}>
                                </SweetAlert>
                            ) : null}


                            <Row>
                                <Col xs="12" md="9">
                                    {this.state.alertcopy ? <div>
                                        <Alert
                                            color="primary"
                                            isOpen={this.state.alertcopy}
                                        >
                                            sucess copy url {this.state.namaalert}
                                        </Alert>
                                    </div> : ''}
                                    <Row xs="3">
                                        {this.state.listTanyaAhli.map(function (ahli, index) {
                                            return <Col key={index}>
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle className="text-truncate font-size-15" tag="h5" style={{ maxHeight: '50%' }}>
                                                            {ahli.tanya_name}
                                                        </CardTitle>
                                                        <p className="text-muted mb-4">{ahli.tanya_narasumber}</p>
                                                        <CardText>
                                                            <p className="text-muted mb-4"><span>Periode</span><br /><span>{ahli.tanya_periode_start ? moment(ahli.tanya_periode_start).format('DD MMM YYYY') : ''} - {ahli.tanya_periode_end ?  moment(ahli.tanya_periode_end).format('DD MMM YYYY') : ''}</span></p>
                                                           
                                                        </CardText>
                                                        <Row>
                                                            <div className="d-flex flex-wrap gap-3" style={{ paddingBottom: 5, justifyContent: "center", alignItems: "center" }}>
                                                                <div className="btn-group btn-group-lg">
                                                                    {/* <button type="button" className="btn btn-outline-danger" onClick={() => { console.log("btnPreview") }}> <i className="fas fa-eye"></i> </button> */}
                                                                    {/* <Link type="button" className="btn btn-outline-danger" to={`artikel-preview/${jurnal.jurnal_id}-${jurnal.jurnal_slug}`} target="_blank"> <i className="fas fa-eye"></i> </Link> */}
                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => { this.direct(ahli.tanya_id, ahli.tanya_type, ahli.tanya_status) }}> <i className="fas fa-edit"></i> </button>
                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => { this.deleteTanyaAhli(ahli) }}> <i className="fas fa-trash"></i> </button>
                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => { this.copyurl(ahli) }}> <i className="fas fa-copy"></i> </button>
                                                                </div>
                                                                <div className="btn-group btn-group-lg">
                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => { this.directSEO(ahli.tanya_id, "tanya_ahli") }}> <i className="fas fa-globe"></i> </button>
                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => { this.directDetail(ahli.tanya_id, ahli.tanya_type, ahli.tanya_status) }}> <i className="fas fa-calendar"></i> </button>
                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => { this.directDetailListPercakapan(ahli.tanya_id, ahli.tanya_type, ahli.tanya_status) }}> <i className="fas fa-comments"></i> </button>
                                                                </div>
                                                            </div>

                                                            <div className="d-grid gap-2">
                                                                <Button color="primary" type="button" className="btn-lg" onClick={() => { this.updateStatusJurnal(ahli) }}> {ahli.tanya_status === 'Published' ? 'Unpublished' : 'Published'} </Button>
                                                            </div>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        }.bind(this))}
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <Pagination className="pagination pagination-rounded justify-content-end mb-2">
                                                {this.state.totalPage.map((v, key) => {
                                                    return <PaginationItem active={this.state.page === v} key={key}>
                                                        <PaginationLink
                                                            onClick={() => this.handlePageClick(v)}
                                                        >
                                                            {v}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                })}
                                            </Pagination>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xl={3} lg={4}>
                                    <Card>
                                        <CardBody className="p-4">
                                            <div>

                                                <div className="search-box">
                                                    <p className="text-muted">Cari</p>
                                                    <div className="position-relative">
                                                        <input
                                                            type="search"
                                                            className="form-control rounded bg-light border-light"
                                                            placeholder="Cari..."
                                                            onChange={(e) => this.setState({ searchTanyaAhli: e.target.value })}
                                                            value={this.state.searchTanyaAhli}
                                                        />
                                                        <i className="mdi mdi-magnify search-icon"></i>
                                                    </div>
                                                </div>
                                                <hr className="my-4" />
                                                <Input
                                                    // id="focusAfterClose"
                                                    onChange={(e) => this.setState({ statusTanyaAhliSearch: e.target.value })}
                                                    // onChange= {(e) => console.log(e.target.value) }
                                                    type="select"
                                                >
                                                    <option value="created_at DESC">
                                                        Terbaru - Terlama
                                                    </option>
                                                    <option value="created_at ASC">
                                                        Terlama - Terbaru
                                                    </option>
                                                    <option value="tanya_name ASC">
                                                        A - Z
                                                    </option>
                                                    <option value="tanya_name DESC">
                                                        Z - A
                                                    </option>
                                                </Input>
                                                <hr className="my-4" />
                                                <Input
                                                    // id="focusAfterClose"
                                                    onChange={(e) => this.setState({ statusTanyaAhli: e.target.value })}
                                                    // onChange= {(e) => console.log(e.target.value) }
                                                    type="select"
                                                >
                                                    <option value="Published">
                                                        Published
                                                    </option>
                                                    <option value="Unpublished">
                                                        Unpublished
                                                    </option>
                                                </Input>
                                                <br />
                                                <p className="text-muted">Tipe Tanya Ahli</p>
                                                <Input
                                                    // id="focusAfterClose"
                                                    onChange={(e) => this.handleType(e)}
                                                    // onChange= {(e) => console.log(e.target.value) }
                                                    type="select"
                                                >
                                                    <option value="">
                                                        Pilih
                                                    </option>
                                                    <option value="artikel">
                                                        Artikel
                                                    </option>
                                                    <option value="jurnal">
                                                        Jurnal
                                                    </option>
                                                    <option value="tata">
                                                        Pedoman Tata Laksana
                                                    </option>
                                                    <option value="webcast">
                                                        Webcast
                                                    </option>
                                                    <option value="tindakan">
                                                        Tindakan Medis Spesialistik
                                                    </option>
                                                </Input>
                                                <hr className="my-4" />
                                                {/* <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="name"
                                                    onChange={(e) => this.setState({ hasilSpesialisasi: e })}
                                                    options={option}
                                                    placeholder="Pilih Spesialisasi"
                                                    selected={this.state.hasilSpesialisasi}
                                                />
                                                <hr className="my-4" /> */}
                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="name"
                                                    onChange={(e) => this.setState({ hasilPengarang: e })}
                                                    options={optionPengarang}
                                                    placeholder="Pilih Narasumber"
                                                    selected={this.state.hasilPengarang}
                                                />
                                                <hr className="my-4" />
                                                {/* <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="name"
                                                    onChange={(e) => this.setState({ hasilMolekul: e })}
                                                    options={optionMolekul}
                                                    placeholder="Pilih Molekul"
                                                    selected={this.state.hasilMolekul}
                                                />
                                                <hr className="my-4" /> */}
                                                {/* <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="name"
                                                    onChange={(e) => this.setState({ hasilPenyakit: e })}
                                                    options={optionPenyakit}
                                                    placeholder="Pilih Penyakit"
                                                    selected={this.state.hasilPenyakit}
                                                />
                                                <hr className="my-4" /> */}

                                            </div>

                                            <hr className="my-4" />

                                            <Button color="danger" onClick={() => this.getTanyaAhli()}>
                                                Cari
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button color="danger" onClick={() => this.reset()}>
                                                Atur Ulang
                                            </Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        {this.state.alert ?
                            <SweetAlert success title={this.state.message} onConfirm={this.handlecancel}>
                            </SweetAlert> : ''
                        }
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(TanyaAhli);
