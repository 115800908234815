import React from "react";
import { Redirect } from "react-router-dom";

import Login from "../pages/Authentication/Login";

//Verifikasi
import VerifikasiSertifikat from "../pages/Verifikasi/sertifikat"
import VerifikasiRegistrasiUser from "../pages/Verifikasi/registrasi-user"
import VerifikasiListUser from "../pages/Verifikasi/user"
import VerifikasiArtikel from "../pages/Verifikasi/artikel"
import DetailArtikel from "../pages/Verifikasi/artikel-detail"
import ListUserCME from "../pages/user/list-user-cme";
import ListHubungiAdmin from "../pages/hubungi-admin/hubungiAdmin";
import VerifikasiNPA from "../pages/user/verifikasi-npa";
import VerifikasiSTR from "../pages/user/verifikasi-str";

// Dashboard
import Dashboard from "../pages/DashboardNew/index";

//master organizer
import MasterOrganizer from "../pages/Organizer/master-organizer"
import AddOrganizer from "../pages/Organizer/TambahOrganisasi"
import UpdateOrganisasi from "../pages/Organizer/UpdateOrganisasi";

//master LOV
import MasterPenulis from "../pages/Master/Penulis"
import MasterPenyakit from "../pages/Master/Penyakit"
import MasterMolekul from "../pages/Master/Molekul"

//event
import ListEvent from "../pages/Event/list-event"
import Event from "../pages/Event/event"
import addEvent from "../pages/Event/Tambah-event"
import editEvent from "../pages/Event/Edit-event"

// international event
import ListInternationalEvent from "../pages/InternationalEvent/list-event"
import InternationalEvent from "../pages/InternationalEvent/event"
import addInternationalEvent from "../pages/InternationalEvent/Tambah-event"
import editInternationalEvent from "../pages/InternationalEvent/Edit-event"

//file manager
import FileMangaer from "../pages/FileManager";

//handling cms
import HandlingCMS from "../pages/HandlingCMS/handling-cms"

// animasi
import Animasi from "../pages/AnimasiPenggunaanObat/Animasi"
import TambahAnimasi from "../pages/AnimasiPenggunaanObat/TambahAnimasi"
import EditAnimasi from "../pages/AnimasiPenggunaanObat/EditAnimasi"


// webinar
import Webinar from "../pages/Webinar/Webinar"
import TambahWebinar from "../pages/Webinar/TambahWebinar"
import EditWebinar from "../pages/Webinar/EditWebinar"
// import EditWebinar from "../pages/Webinar/EditWebinar"


// tindakan medis
import TindakanMedis from "../pages/TindakanMedis/TindakanMedis"
import TambahTindakanMedis from "../pages/TindakanMedis/TambahTindakanMedis"
import EditTindakanMedis from "../pages/TindakanMedis/EditTindakanMedis"


//#KUN
import ListAdmin from "../pages/Administrator/ListAdmin"
import Create from "../pages/Administrator/Create"
import Edit from "../pages/Administrator/Edit"

//#DEDIAN
import Artikel from "../pages/Artikel/Artikel"
import TambahArtikel from "../pages/Artikel/TambahArtikel"
import EditArtikel from "../pages/Artikel/EditArtikel"
import ModerasiJurnal from "../pages/JurnalTataLaksana/ModerasiJurnal"
import JurnalTataLaksana from "../pages/JurnalTataLaksana/JurnalTataLaksana"
import TambahJurnalTataLaksana from "../pages/JurnalTataLaksana/TambahJurnalTataLaksana"
import EditJurnalTatatLaksana from "../pages/JurnalTataLaksana/EditJurnalTataLaksana"
import Ebook from "../pages/Ebook/Ebook"
import TambahEbook from "../pages/Ebook/TambahEbook"
import EditEbook from "../pages/Ebook/EditEbook"
import ListCME from "../pages/CMEOnline/ListCME"
import TambahCME from "../pages/CMEOnline/TambahCME"
import DetailPertanyaan from "pages/CMEOnline/DetailPertanyaan";
import EditCME from "pages/CMEOnline/EditCME";
import ListTanyaAhli from "pages/TanyaAhli/ListTanyaAhli";
import TambahTanyaAhli from "pages/TanyaAhli/TambahTanyaAhli";
import EditTanyaAhli from "pages/TanyaAhli/EditTanyaAhli";
import TambahTanyaAhliPeriode from "pages/TanyaAhli/TambahTanyaAhliPeriode";
import ListPercakapan from "pages/TanyaAhli/ListPercakapan";
import DetailPercakapan from "pages/TanyaAhli/DetailPercakapan";
import QnaPercakapan from "pages/TanyaAhli/QnaPercakapan";

//#ASA
import SeoConfig from "../pages/ContentSEO/SeoConfig";
import MasterGeneralSEO from "pages/Master/GeneralSEO";
import ListGeneralSEO from "pages/Master/ListGeneralSEO";
import SertifikatCME from "pages/CMEOnline/SertifikatCME";

//upload
import FileUpload from "../pages/FileUpload"


//upload
import Kategori from "../pages/KategoriTags/kategori"
import Tags from "../pages/KategoriTags/tags"

//direct
import triger from "../pages/direct"

// import Artikel from "../pages/Artikel"
// import TambahArtikel from "../pages/TambahArtikel"

const authProtectedRoutes = [
  { path: "/", component: triger },
  { path: "/dashboard", component: Dashboard },
  // { path: "/dashboard-saas", component: DashboardSaas },
  // { path: "/dashboard-crypto", component: DashboardCrypto },
  // { path: "/dashboard-blog", component: DashboardBlog },

  //Verifikasi
  { path: "/verifikasi-sertifikat", component: VerifikasiSertifikat },
  { path: "/verifikasi-registrasi-user", component: VerifikasiRegistrasiUser },
  { path: "/daftar-user", component: VerifikasiListUser },
  { path: "/verifikasi-artikel", component: VerifikasiArtikel },
  { path: "/verifikasi-artikel/detail/:id", component: DetailArtikel },
  { path: "/user/cme", component: ListUserCME },
  { path: "/user/verifikasi-npa", component: VerifikasiNPA },
  { path: "/user/verifikasi-str", component: VerifikasiSTR },
  { path: "/hubungi-admin", component: ListHubungiAdmin },


  { path: "/list-admin", component: ListAdmin },
  { path: "/create-admin", component: Create },
  { path: "/edit-admin/:id", component: Edit },

  //file-upload
  { path: "/filemanager/upload", component: FileUpload },
  { path: "/filemanager", component: FileMangaer },

  //kategori dan tags
  { path: "/kategori", component: Kategori },
  { path: "/tags", component: Tags },

  //Artikel
  { path: "/artikel", component: Artikel },
  { path: "/artikel/tambahartikel", component: TambahArtikel },
  { path: "/artikel/editartikel/:id/:status", component: EditArtikel },
  
  //SEOConfig
  { path: "/seoconfig/:id/:type", component: SeoConfig },

  //Jurnal & Tata Laksana
  { path: "/jurnal-tatalaksana", component: ModerasiJurnal },
  { path: "/jurnaltatalaksana", component: JurnalTataLaksana },
  { path: "/jurnal/tambahjurnaltatalaksana", component: TambahJurnalTataLaksana },
  { path: "/jurnal/editjurnaltatalaksana/:id/:status/:type", component: EditJurnalTatatLaksana },

  //Ebook
  { path: "/ebook", component: Ebook },
  { path: "/ebook/tambahebook", component: TambahEbook },
  { path: "/ebook/editebook/:id/:status", component: EditEbook },

  //Webinar
  { path: "/webinar", component: Webinar },
  { path: "/webinar/tambahwebinar", component: TambahWebinar },
  { path: "/webinar/editwebinar/:id/:status", component: EditWebinar },

  //Tindakan Medis Spesialistik
  { path: "/tindakanmedis", component: TindakanMedis },
  { path: "/tindakanmedis/tambah-tindakan-medis", component: TambahTindakanMedis },
  { path: "/tindakanmedis/edit-tindakan-medis/:id/:status", component: EditTindakanMedis },

  //Animasi Penggunaan Obat
  { path: "/animasi-penggunaan-obat", component: Animasi },
  { path: "/animasi-penggunaan-obat/tambah-animasi-penggunaan-obat", component: TambahAnimasi },
  { path: "/animasi-penggunaan-obat/edit-animasi-penggunaan-obat/:id/:status", component: EditAnimasi },

  //CME Online
  { path: "/cme-online", component: ListCME },
  { path: "/cme-online/tambah-cme", component: TambahCME },
  { path: "/cme-online/detail-pertanyaan/:id", component: DetailPertanyaan },
  { path: "/cme-online/edit-cme/:id", component: EditCME },
  { path: "/cme-online/sertifikat/:id", component: SertifikatCME },

  //Tanya Ahli
  { path: "/tanya-ahli", component: ListTanyaAhli },
  { path: "/tanya-ahli/tambah-tanya-ahli", component: TambahTanyaAhli },
  { path: "/tanya-ahli/edit-tanya-ahli/:id/:type/:status", component: EditTanyaAhli },
  { path: "/tanya-ahli/tambah-tanya-periode/:id/:type/:status", component: TambahTanyaAhliPeriode },
  { path: "/tanya-ahli/list-percakapan/:id/:type/:status", component: ListPercakapan },
  { path: "/tanya-ahli/detail/:id/:user", component: DetailPercakapan },
  { path: "/tanya-ahli/qna/:id/:user", component: QnaPercakapan },

  //Organizer
  { path: "/master-organizer", component: MasterOrganizer },
  { path: "/tambah-organisasi", component: AddOrganizer },
  { path: "/update-organisasi/:id", component: UpdateOrganisasi },
  


  //master
  { path: "/master-penulis", component: MasterPenulis },
  { path: "/master-penyakit", component: MasterPenyakit },
  { path: "/master-molekul", component: MasterMolekul },
  { path: "/list-seogeneral/:id", component: MasterGeneralSEO},
  { path: "/list-seogeneral", component: ListGeneralSEO},
  

  //event
  { path: "/list-event", component: ListEvent },
  { path: "/event", component: Event },
  { path: "/tambah-event", component: addEvent },
  { path: "/edit-event/:id/:status", component: editEvent },

  // international event
  { path: "/list-event/internasional", component: ListInternationalEvent },
  { path: "/event/internasional", component: InternationalEvent },
  { path: "/tambah-event/internasional", component: addInternationalEvent },
  { path: "/edit-event/internasional/:id/:status", component: editInternationalEvent },

  //handling cms
  { path: "/handling-cms", component: HandlingCMS }

];

const publicRoutes = [
  { path: "/login", component: Login },
];

export { authProtectedRoutes, publicRoutes };
