import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardGroup,
    CardImg,
    CardTitle,
    CardSubtitle,
    CardText,
    Form,
    FormGroup,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Alert
} from 'reactstrap';
import { Link } from "react-router-dom";
import {
    Container,
} from "reactstrap"
import httprequester from '../../lib/httprequester'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types';
import { Typeahead } from 'react-bootstrap-typeahead';


import { isEmpty, map, size } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

class Artikel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            sizePerPage: 10,
            totalPage: [],
            listArtikel: [],
            listCategory: [],
            listValue: [],
            alert: false,
            openmodal: false,
            // modal: false,
            message: '',
            urlimage: '',
            id: null,
            categoriesArticle: '',
            statusArticle: 'Published',
            searchArticle: '',
            valueArticle: '',
            urutkanArticle: '',
            alertcopy: false,
            namaalert: '',
            alert: false,
            listPenyakit: [],
            listMolekul: [],
            listPengarang: [],
            listSpesialisasi: [],
            hasilPengarang: [],
            hasilPenyakit: [],
            hasilSpesialisasi: [],
            hasilMolekul: [],
        }
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    direct = (id, status) => {
        // this.setState({
        //     basic: false
        // })
        this.props.history.push(`/artikel/editartikel/${id}/${status}`)
    }

    directSEO = (id, type) => {
        this.props.history.push(`/seoconfig/${id}/${type}`)
    }

    copyurl = (value) => {
        this.setState({
            namaalert: value.article_title,
            alertcopy: true
        })
        navigator.clipboard.writeText(value.article_id)
        setTimeout(() => {
            this.setState({
                alertcopy: false
            })
        }, 3000);
    }

    componentDidMount() {
        this.getArtikel();
        this.getLov();
    }

    getLov() {
        let body = {
            hp_type: "article"
        }

        httprequester.postarticle('homepage/lov_content', body, {
            success: async function (response) {

                let { data } = response;
                // let { detailMessage } = response.data;
                this.setState({
                    listMolekul: data.resultMolekul,
                    listPengarang: data.resultPenulis,
                    listPenyakit: data.resultPenyakit,
                    listSpesialisasi: data.resultSpecialist
                })
                // console.log("artikel => ", this.state.listArtikel)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    handlePageClick = (e) => {


        let a = e
        this.setState({
            page: a
        })

        let penulis = this.state.hasilPengarang[0] ? this.state.hasilPengarang[0] : ''
        let molekul = this.state.hasilMolekul[0] ? this.state.hasilMolekul[0] : ''
        let spesialisasi = this.state.hasilSpesialisasi[0] ? this.state.hasilSpesialisasi[0] : ''
        let penyakit = this.state.hasilPenyakit[0] ? this.state.hasilPenyakit[0] : ''

        let body = {
            "spesialisasi": spesialisasi,
            "penyakit": penyakit,
            "search": this.state.searchArticle,
            "value": "",
            "status": this.state.statusArticle,
            "page": e,
            "limit": this.state.sizePerPage,
            "molekul": molekul,
            "penulis": penulis,
            "urutan": "created_at DESC"
        }

        httprequester.postarticle('user/get_article', body, {
            success: async function (response) {
                let data = []
                let cek = response.data.result.data
                // console.log("res => ", cek)
                if (response.data.message == "success") {
                    let pages = []
                    for (let index = 1; index <= response.data.result.lastPage; index++) {
                        pages.push(index)
                    }
                    // console.log("res2 => ", response.data.result.data.dataRows)

                    // if(response.data.result.data.length)
                    response.data.result.data.dataRows.forEach(async (v, key) => {
                        await data.push({
                            article_category: v[0],
                            article_content: v[1],
                            article_penulis: v[2],
                            created_at: v[3],
                            article_slug: v[4],
                            created_by: v[5],
                            article_molekul: v[6],
                            article_id: v[7],
                            article_spesialis: v[8],
                            article_title: v[9],
                            article_status: v[10],
                            article_penyakit: v[11],
                            article_tags: v[12],
                            article_thumbnail: v[13]
                        })
                    })
                    this.setState({
                        listArtikel: data,
                        page: response.data.result.page,
                        sizePerPage: response.data.result.perPage,
                        totalPage: pages,
                    })
                }
                // console.log("artikel => ", this.state.listArtikel)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    getArtikelReset = () => {

        let body = {
            "spesialisasi": "",
            "penyakit": "",
            "search": "",
            "value": "",
            "status": this.state.statusArticle,
            "page": 1,
            "limit": this.state.sizePerPage,
            "molekul": "",
            "penulis": "",
            "urutan": "created_at DESC"
        }

        this.setState({
            hasilSpesialisasi: [],
            hasilMolekul: [],
            hasilPenyakit: [],
            hasilPengarang: [],
            search: '',
            page: 1
        })

        httprequester.postarticle('user/get_article', body, {
            success: async function (response) {
                let data = []
                let cek = response.data.result.data
                // console.log("res => ", cek)
                if (response.data.message == "success") {
                    let pages = []
                    for (let index = 1; index <= response.data.result.lastPage; index++) {
                        pages.push(index)
                    }
                    // console.log("res2 => ", response.data.result.data.dataRows)

                    // if(response.data.result.data.length)
                    response.data.result.data.dataRows.forEach(async (v, key) => {
                        await data.push({
                            article_category: v[0],
                            article_content: v[1],
                            article_penulis: v[2],
                            created_at: v[3],
                            article_slug: v[4],
                            created_by: v[5],
                            article_molekul: v[6],
                            article_id: v[7],
                            article_spesialis: v[8],
                            article_title: v[9],
                            article_status: v[10],
                            article_penyakit: v[11],
                            article_tags: v[12],
                            article_thumbnail: v[13]
                        })
                    })
                    this.setState({
                        listArtikel: data,
                        page: response.data.result.page,
                        sizePerPage: response.data.result.perPage,
                        totalPage: pages,
                    })
                }
                // console.log("artikel => ", this.state.listArtikel)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    getArtikel = () => {
        // let body = {
        let penulis = this.state.hasilPengarang[0] ? this.state.hasilPengarang[0] : ''
        let molekul = this.state.hasilMolekul[0] ? this.state.hasilMolekul[0] : ''
        let spesialisasi = this.state.hasilSpesialisasi[0] ? this.state.hasilSpesialisasi[0] : ''
        let penyakit = this.state.hasilPenyakit[0] ? this.state.hasilPenyakit[0] : ''

        let body = {
            "spesialisasi": spesialisasi,
            "penyakit": penyakit,
            "search": this.state.searchArticle,
            "value": "",
            "status": this.state.statusArticle,
            "page": this.state.page,
            "limit": this.state.sizePerPage,
            "molekul": molekul,
            "penulis": penulis,
            "urutan": "created_at DESC"
        }

        httprequester.postarticle('user/get_article', body, {
            success: async function (response) {
                let data = []
                // let cek = response.data.result.data
                // console.log("res => ", cek)
                if (response.data.message == "success") {
                    let pages = []
                    for (let index = 1; index <= response.data.result.lastPage; index++) {
                        pages.push(index)
                    }
                    // console.log("res2 => ", response.data.result.data.dataRows)

                    // if(response.data.result.data.length)
                    response.data.result.data.dataRows.forEach(async (v, key) => {
                        await data.push({
                            article_category: v[0],
                            article_content: v[1],
                            article_penulis: v[2],
                            created_at: v[3],
                            article_slug: v[4],
                            created_by: v[5],
                            article_molekul: v[6],
                            article_id: v[7],
                            article_spesialis: v[8],
                            article_title: v[9],
                            article_status: v[10],
                            article_penyakit: v[11],
                            article_tags: v[12],
                            article_thumbnail: v[13]
                        })
                    })
                    this.setState({
                        listArtikel: data,
                        page: response.data.result.page,
                        sizePerPage: response.data.result.perPage,
                        totalPage: pages,
                    })
                }
                // console.log("artikel => ", this.state.listArtikel)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    updateStatusArtikel = (value) => {
        let body = {
            "article_id": value.article_id,
            "article_status": value.article_status === 'Published' ? 'Unpublished' : 'Published'
        }

        let dx = {
            content_id: value.article_id,
            content_penulis: value.article_penulis,
            content_title: value.article_title,
            created_at: value.created_at,
            content_slug: value.article_slug,
            content_penyakit: value.article_penyakit,
            content_molekul: value.article_molekul,
            content_spesialis: value.article_spesialis,
            hp_type: 'article'
        }
        
        // console.log("bodyUpdate =>", body)
        httprequester.postarticle('artikel/update', body, {
            success: async function (response) {
                // console.log("resUpdate => ", response)    
                this.setState({
                    alert: true,
                    message: 'success'
                })

                if(body.article_status === "Published"){
                    httprequester.postarticle('homepage/lov_construct', dx, {
                        success: async function (responses) {
                            // console.log(responses)
                        }.bind(this),
                        error: function (err) {
                            // console.log("error", err)
                        }.bind(this)
                    })
                }else{
                    httprequester.postarticle('homepage/lov_destruct', dx, {
                        success: async function (responses) {
                            // console.log(responses)
                        }.bind(this),
                        error: function (err) {
                            // console.log("error", err)
                        }.bind(this)
                    })
                }

                setTimeout(() => {
                    this.getArtikel();
                }, 2000);

                // console.log("artikel => ", this.state.listArtikel)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)
        })
    }

    deleteArtikel = (value) => {
        let body = {
            "article_id": value.article_id,
            "article_status": "Deleted"
        }
        // console.log("bodyDelete =>", body)
        httprequester.postarticle('artikel/update', body, {
            success: async function (response) {
                // console.log("resDelete => ", response)
                this.setState({
                    message: response.data.message,
                    alert: true
                })
                setTimeout(() => {
                    this.getArtikel()
                }, 2000);

                // if (response.data.message == "success") {
                //     this.setState({
                //         listArtikel: data
                //     })
                // }
                // console.log("artikel => ", this.state.listArtikel)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)
        })
    }

    render() {
        // console.log("pagenya", this.state.page)
        
        const { page, totalPage } = this.state
        const option = this.state.listSpesialisasi.map(o => o.ri_lov_content_name)
        const optionPengarang = this.state.listPengarang.map(o => o.ri_lov_content_name)
        const optionMolekul = this.state.listMolekul.map(o => o.ri_lov_content_name)
        const optionPenyakit = this.state.listPenyakit.map(o => o.ri_lov_content_name)
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Artikel</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Artikel" breadcrumbItem="Artikel" />

                        <Row style={{ paddingBottom: 10 }}>
                            <Col xs="12" md="4">
                                <Link to="/artikel/tambahartikel">
                                    <Button color="danger">
                                        Tambah Artikel
                                    </Button>
                                </Link>
                            </Col>
                        </Row>

                        <div>
                            {this.state.alert ? (
                                <SweetAlert success title={this.state.message} onConfirm={() => this.setState({ alert: false })}>
                                </SweetAlert>
                            ) : null}

                            <Row>
                                <Col xs="12" md="9">
                                    {this.state.alertcopy ? <div>
                                        <Alert
                                            color="primary"
                                            isOpen={this.state.alertcopy}
                                        >
                                            sucess copy url {this.state.namaalert}
                                        </Alert>
                                    </div> : ''}
                                    <Row xs="3">
                                        {this.state.listArtikel.map(function (article, index) {
                                            return <Col key={index}>
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle className="text-truncate font-size-15" tag="h5" style={{ maxHeight: '50%' }}>
                                                            {article.article_title}
                                                        </CardTitle>
                                                        <p className="text-muted mb-4">{article.article_penulis}</p>
                                                        {/* <CardSubtitle
                                                            className="mb-2 text-muted"
                                                            tag="h6"
                                                        >
                                                            {article.article_penulis}
                                                        </CardSubtitle> */}
                                                        {/* <CardImg
                                                            alt="Card image cap"
                                                            src={article.article_thumbnail}
                                                            top
                                                            width="50%"
                                                            height="50%"
                                                        /> */}
                                                        <CardText>
                                                            {article.article_spesialis}
                                                        </CardText>
                                                        <CardText>
                                                            {article.article_penyakit}
                                                        </CardText>
                                                        <CardText>
                                                            {article.article_molekul}
                                                        </CardText>
                                                        <Row>
                                                            <div className="d-flex flex-wrap gap-3" style={{ paddingBottom: 5, justifyContent: "center", alignItems: "center" }}>
                                                                <div className="btn-group btn-group-lg">
                                                                    {/* <button type="button" className="btn btn-outline-danger" onClick={() => { console.log("btnPreview") }}> <i className="fas fa-eye"></i> </button> */}
                                                                    {/* <Link type="button" className="btn btn-outline-danger" to={`artikel-preview/${article.article_id}-${article.article_slug}`} target="_blank"> <i className="fas fa-eye"></i> </Link> */}
                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => { this.direct(article.article_id, article.article_status) }}> <i className="fas fa-edit"></i> </button>
                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => { this.deleteArtikel(article) }}> <i className="fas fa-trash"></i> </button>
                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => { this.copyurl(article) }}> <i className="fas fa-copy"></i> </button>
                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => { this.directSEO(article.article_id, "artikel")}}> <i className="fas fa-globe"></i> </button>
                                                                </div>
                                                            </div>

                                                            <div className="d-grid gap-2">
                                                                <Button color="primary" type="button" className="btn-lg" onClick={() => { this.updateStatusArtikel(article) }}> {article.article_status === 'Published' ? 'Unpublished' : 'Published'} </Button>
                                                            </div>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        }.bind(this))}
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <Pagination className="pagination pagination-rounded justify-content-end mb-2">
                                                {/* <PaginationItem disabled={this.state.page === 1}>
                                                    <PaginationLink
                                                        previous
                                                        // href="#"
                                                        onClick={() => this.handlePageClick(this.state.page - 1)}
                                                    />
                                                </PaginationItem> */}
                                                {this.state.totalPage.map((v, key) => {
                                                    return <PaginationItem active={this.state.page === v} key={key}>
                                                        <PaginationLink
                                                            onClick={() => this.handlePageClick(v)}
                                                        >
                                                            {v}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                })}
                                                {/* <PaginationItem disabled={this.state.page === this.state.totalPage}>
                                                    <PaginationLink
                                                        next
                                                        // href="#"
                                                        onClick={() => this.handlePageClick(this.state.page + 1)}
                                                    />
                                                </PaginationItem> */}
                                            </Pagination>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xl={3} lg={4}>
                                    <Card>
                                        <CardBody className="p-4">
                                            <div>
                                                <div className="search-box">
                                                    <p className="text-muted">Cari</p>
                                                    <div className="position-relative">
                                                        <input
                                                            type="search"
                                                            className="form-control rounded bg-light border-light"
                                                            placeholder="Cari..."
                                                            onChange={(e) => this.setState({ searchArticle: e.target.value })}
                                                            value={this.state.searchArticle}
                                                        />
                                                        <i className="mdi mdi-magnify search-icon"></i>
                                                    </div>
                                                </div>
                                                <hr className="my-4" />
                                                <Input
                                                    // id="focusAfterClose"
                                                    onChange={(e) => this.setState({ statusArticle: e.target.value })}
                                                    // onChange= {(e) => console.log(e.target.value) }
                                                    type="select"
                                                >
                                                    <option value="Published">
                                                        Published
                                                    </option>
                                                    <option value="Unpublished">
                                                        Unpublished
                                                    </option>
                                                </Input>
                                                <hr className="my-4" />
                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="name"
                                                    onChange={(e) => this.setState({ hasilSpesialisasi: e })}
                                                    options={option}
                                                    placeholder="Pilih Spesialisasi"
                                                    selected={this.state.hasilSpesialisasi}
                                                />
                                                <hr className="my-4" />
                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="name"
                                                    onChange={(e) => this.setState({ hasilPengarang: e })}
                                                    options={optionPengarang}
                                                    placeholder="Pilih Narasumber"
                                                    selected={this.state.hasilPengarang}
                                                />
                                                <hr className="my-4" />
                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="name"
                                                    onChange={(e) => this.setState({ hasilMolekul: e })}
                                                    options={optionMolekul}
                                                    placeholder="Pilih Molekul"
                                                    selected={this.state.hasilMolekul}
                                                />
                                                <hr className="my-4" />
                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="name"
                                                    onChange={(e) => this.setState({ hasilPenyakit: e })}
                                                    options={optionPenyakit}
                                                    placeholder="Pilih Penyakit"
                                                    selected={this.state.hasilPenyakit}
                                                />
                                                <hr className="my-4" />

                                            </div>



                                            <Button color="danger" onClick={() => this.getArtikel()}>
                                                Cari
                                            </Button>&nbsp;&nbsp;
                                            <Button color="danger" onClick={() => this.getArtikelReset()}>
                                                Atur Ulang
                                            </Button>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(Artikel);
