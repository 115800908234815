import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Container,
  Button,
  Alert
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// import images
import img1 from "../../assets/images/small/img-2.jpg"
import avtar1 from "../../assets/images/users/avatar-2.jpg"
import httprequester from '../../lib/httprequester'

export default class DetailArtikel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      details: {},
      alertcopy: false,
      namaalert: ''
    }
  }

  getDetails = () => {
    let router = window.location.href
    let routerSplitter = router.split("/");
    // console.log(routerSplitter[5])
    let body = {
      "artikel_id": routerSplitter[5]
    }

    httprequester.postnobody('artikel/submit_detail', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          this.setState({
            details: response.data.detailMessage[0]
          })
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  copyurl = (value) => {
    this.setState({
      namaalert: value,
      alertcopy: true
    })
    navigator.clipboard.writeText(value)
    setTimeout(() => {
      this.setState({
        alertcopy: false
      })
    }, 3000);
  }

  componentDidMount() {
    this.getDetails()
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Detail Artikel | Ruang Ilmiah</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Artikel" breadcrumbItem="Detail Artikel" />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="pt-3">
                      <div className="row justify-content-center">
                        <div className="col-xl-8">
                          {this.state.alertcopy ? <div>
                            <Alert
                              color="primary"
                              isOpen={this.state.alertcopy}
                            >
                              sucess copy url {this.state.namaalert}
                            </Alert>
                          </div> : ''}
                          <div>
                            <div className="text-center">
                              <h4>{this.state.details.artikel_title}</h4>
                              <Button size="sm" outline onClick={() => this.copyurl(this.state.details.artikel_title)}>
                                copy
                              </Button>
                              
                              {/* <p className="text-muted mb-4">
                                <i className="mdi mdi-calendar me-1"></i> 10 Apr,
                            2020
                          </p> */}
                            </div>



                            <hr />
                            <div className="text-center">
                              <Row>
                                <Col sm={3}>
                                  <div>
                                    <p className="text-muted mb-2">Cover 123RF</p>
                                    <h5>{this.state.details.artikel_image_id}</h5>
                                    <Button size="sm" outline onClick={() => this.copyurl(this.state.details.artikel_image_id)}>
                                      copy
                                    </Button>
                                  </div>
                                </Col>
                                <Col sm={3}>
                                  <div>
                                    <p className="text-muted mb-2">Spesialis</p>
                                    <h5 className="font-size-15">{this.state.details.artikel_spesialis}</h5>
                                    <Button size="sm" outline onClick={() => this.copyurl(this.state.details.artikel_spesialis)}>
                                      copy
                                    </Button>
                                  </div>
                                </Col>
                                <Col sm={3}>
                                  <div className="mt-4 mt-sm-0">
                                    <p className="text-muted mb-2">Penyakit</p>
                                    <h5 className="font-size-15">{this.state.details.artikel_penyakit}</h5>
                                    <Button size="sm" outline onClick={() => this.copyurl(this.state.details.artikel_penyakit)}>
                                      copy
                                    </Button>
                                  </div>
                                </Col>
                                <Col sm={3}>
                                  <div className="mt-4 mt-sm-0">
                                    <p className="text-muted mb-2">Molekul</p>
                                    <h5 className="font-size-15">{this.state.details.artikel_molekul}</h5>
                                    <Button size="sm" outline onClick={() => this.copyurl(this.state.details.artikel_molekul)}>
                                      copy
                                    </Button>
                                  </div>
                                </Col>
                                <Col sm={3}>
                                  <div className="mt-4 mt-sm-0">
                                    <p className="text-muted mb-2">Penulis</p>
                                    <h5 className="font-size-15">{this.state.details.artikel_penulis}</h5>
                                    <Button size="sm" outline onClick={() => this.copyurl(this.state.details.artikel_penulis)}>
                                      copy
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </div>


                            <hr />

                            <div className="mt-4">
                              <div className="text-muted font-size-14">
                                <Button outline onClick={() => window.open(this.state.details.artikel_pdf, "_blank")}><i className="fas fa-download"></i>{" "}Download Artikel</Button>
                              </div>

                              <hr />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
