import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardGroup,
    CardImg,
    CardTitle,
    CardSubtitle,
    CardText,
    Form,
    FormGroup,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
} from 'reactstrap';
import { Link } from "react-router-dom";
import {
    Container,
} from "reactstrap"
import { withRouter } from 'react-router'
import PropTypes from 'prop-types';
import httprequester from '../../lib/httprequester'

import { isEmpty, map, size } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SweetAlert from 'react-bootstrap-sweetalert';

class Ebook extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            sizePerPage: 10,
            totalPage: 5,
            listEbook: [],
            listCategory: [],
            listValue: [],
            alert: false,
            openmodal: false,
            // modal: false,
            message: '',
            urlimage: '',
            id: null,
            categoriesEbook: '',
            statusEbook: 'Published',
            searchEbook: '',
            valueEbook: '',
            urutkanEbook: ''
        }
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    direct = (id, status) => {
        this.setState({
            basic: false
        })
        this.props.history.push(`/ebook/editebook/${id}/${status}`)
    }

    componentDidMount() {
        this.getEbook();
        this.getCategory();
    }

    handlePageClick = page => {
        this.setState({ page })
        console.log("page => ", page)

        this.getEbook();
    }

    getEbook = () => {
        let body = {
            "category": "",
            "search": this.state.searchEbook,
            "value": "",
            "status": this.state.statusEbook,
            "page": 1,
            "limit": 10,
            "urutan": "ebook_title ASC"
        }

        console.log("body =>", body)
        httprequester.postarticle('ebook/getall', body, {
            success: async function (response) {
                let data = []
                let cek = response.data.result.data
                console.log("res => ", cek)
                if (response.data.message == "success") {
                    // console.log("res2 => ", response.data.result.data.dataRows)

                    // if(response.data.result.data.length)
                    response.data.result.data.dataRows.forEach(async (v, key) => {
                        await data.push({
                            ebook_status: v[0],
                            ebook_tags: v[1],
                            ebook_molekul: v[2],
                            created_at: v[3],
                            created_by: v[4],
                            ebook_spesialis: v[5],
                            ebook_thumbnail: v[6],
                            event_spesialis: v[5],
                            ebook_penyakit: v[9],
                            ebook_slug: v[10],
                            ebook_title: v[11],
                            ebook_category: v[12],
                            ebook_content: v[13],
                            ebook_id: v[14],
                            ebook_penulis: v[15]
                        })
                    })
                    console.log('data',data)
                    this.setState({
                        listEbook: data,
                        page: response.data.result.page,
                        sizePerPage: response.data.result.perPage,
                        totalPage: response.data.result.lastPage,
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    getCategory = () => {
        httprequester.get('category/getall', {
            success: async function (response) {

                // console.log("resCat => ", response)
                if (response.data.message == "success") {
                    this.setState({
                        listCategory: response.data.detailMessage
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    updateStatusEbook = (value) => {
        let body = {
            "ebook_id": value.ebook_id,
            "ebook_status": value.ebook_status === 'Published' ? 'Unpublished' : 'Published'
        }
        console.log("bodyUpdate =>", body)
        httprequester.postarticle('ebook/update', body, {
            success: async function (response) {
                // console.log("resUpdate => ", response)
                
                this.setState({
                    alert: true,
                    message: 'success'
                })
                setTimeout(() => {
                    this.getEbook();
                }, 2000);
                // if (response.data.message == "success") {
                //     this.setState({
                //         listArtikel: data
                //     })
                // }
                // console.log("artikel => ", this.state.listArtikel)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)
        })
    }

    deleteJurnal = (value) => {
        let body = {
            "ebook_id": value.ebook_id,
            "ebook_status": "Deleted"
        }
        console.log("bodyDelete =>", body)
        httprequester.postarticle('ebook/update', body, {
            success: async function (response) {
                console.log("resDelete => ", response)
                
                this.setState({
                    message: response.data.message,
                    alert: true
                })
                setTimeout(() => {
                    this.getEbook()
                }, 2000);
                // if (response.data.message == "success") {
                //     this.setState({
                //         listArtikel: data
                //     })
                // }
                // console.log("artikel => ", this.state.listArtikel)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)
        })
    }

    render() {
        // console.log("pagenya", this.state.page)
        const { page, totalPage } = this.state
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Ebook</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Halaman Utama" breadcrumbItem="Ebook" />

                        <Row style={{ paddingBottom: 10 }}>
                            <Col xs="12" md="4">
                                <Link to="/ebook/tambahebook">
                                    <Button color="danger">
                                        Tambah Ebook
                                    </Button>
                                </Link>
                            </Col>
                        </Row>

                        <div>
                            {this.state.alert ? (
                                <SweetAlert success title={this.state.message} onConfirm={() => this.setState({ alert: false })}>
                                </SweetAlert>
                            ) : null}
                            <Row>
                                <Col xs="12" md="9">

                                    <Row xs="3">
                                        {this.state.listEbook.map(function (ebook, index) {
                                            return <Col key={index}>
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle className="text-truncate font-size-15" tag="h5" style={{ maxHeight: '50%' }}>
                                                            {ebook.ebook_title}
                                                        </CardTitle>
                                                        <p className="text-muted mb-4">{ebook.ebook_penulis}</p>
                                                        {/* <CardSubtitle
                                                            className="mb-2 text-muted"
                                                            tag="h6"
                                                        >
                                                            {article.article_penulis}
                                                        </CardSubtitle> */}
                                                        {/* <CardImg
                                                            alt="Card image cap"
                                                            src={article.article_thumbnail}
                                                            top
                                                            width="50%"
                                                            height="50%"
                                                        /> */}
                                                        <CardText>
                                                            {ebook.ebook_spesialis}
                                                        </CardText>
                                                        <CardText>
                                                            {ebook.ebook_penyakit}
                                                        </CardText>
                                                        <CardText>
                                                            {ebook.ebook_molekul}
                                                        </CardText>
                                                        <Row>
                                                            <div className="d-flex flex-wrap gap-3" style={{ paddingBottom: 5, justifyContent: "center", alignItems: "center" }}>
                                                                <div className="btn-group btn-group-lg">
                                                                    {/* <button type="button" className="btn btn-outline-danger" onClick={() => { console.log("btnPreview") }}> <i className="fas fa-eye"></i> </button> */}
                                                                    {/* <Link type="button" className="btn btn-outline-danger" to={`artikel-preview/${ebook.ebook_id}-${ebook.ebook_slug}`} target="_blank"> <i className="fas fa-eye"></i> </Link> */}
                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => { this.direct(ebook.ebook_id, ebook.ebook_status) }}> <i className="fas fa-edit"></i> </button>
                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => { this.deleteJurnal(ebook) }}> <i className="fas fa-trash"></i> </button>
                                                                </div>
                                                            </div>

                                                            <div className="d-grid gap-2">
                                                                <Button color="primary" type="button" className="btn-lg" onClick={() => { this.updateStatusEbook(ebook) }}> {ebook.ebook_status === 'Published' ? 'Unpublished' : 'Published'} </Button>
                                                            </div>


                                                            {/* <Col>
                                                                <Button
                                                                    color="danger"
                                                                    type="button"
                                                                    onClick={() => { this.updateStatusEbook(article) }}
                                                                // onClick={() => {console.log("tah eta => ", article)}}
                                                                >
                                                                    {article.article_status === 'Published' ? 'Unpublished' : 'Published'}
                                                                </Button>
                                                            </Col>
                                                            <Col>
                                                                <Button
                                                                    color="danger"
                                                                    type="button"
                                                                    onClick={() => { this.deleteJurnal(article) }}
                                                                // onClick={() => {console.log("tah eta => ", article)}}
                                                                >
                                                                    <i className="fas fa-eye"></i>
                                                                </Button>
                                                            </Col>
                                                            <Col>
                                                                <Button
                                                                    color="danger"
                                                                    type="button"
                                                                    onClick={() => { this.deleteJurnal(article) }}
                                                                // onClick={() => {console.log("tah eta => ", article)}}
                                                                >
                                                                    <i className="fas fa-trash"></i>
                                                                </Button>
                                                            </Col> */}
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        }.bind(this))}
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <Pagination className="pagination pagination-rounded justify-content-end mb-2">
                                                <PaginationItem disabled={page === 1}>
                                                    <PaginationLink
                                                        previous
                                                        href="#"
                                                        onClick={() => this.handlePageClick(page - 1)}
                                                    />
                                                </PaginationItem>
                                                {map(Array(totalPage), (item, i) => (
                                                    <PaginationItem active={i + 1 === page} key={i}>
                                                        <PaginationLink
                                                            onClick={() => this.handlePageClick(i + 1)}
                                                            href="#"
                                                        >
                                                            {i + 1}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                ))}
                                                <PaginationItem disabled={page === totalPage}>
                                                    <PaginationLink
                                                        next
                                                        href="#"
                                                        onClick={() => this.handlePageClick(page + 1)}
                                                    />
                                                </PaginationItem>
                                            </Pagination>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xl={3} lg={4}>
                                    <Card>
                                        <CardBody className="p-4">
                                            <div>
                                                <p className="text-muted">Ebook Status</p>

                                                <Input
                                                    // id="focusAfterClose"
                                                    onChange={(e) => this.setState({ statusEbook: e.target.value })}
                                                    // onChange= {(e) => console.log(e.target.value) }
                                                    type="select"
                                                >
                                                    <option value="Published">
                                                        Published
                                                    </option>
                                                    <option value="Unpublished">
                                                        Unpublished
                                                    </option>
                                                </Input>

                                            </div>

                                            <hr className="my-4" />

                                            {/* <div>
                                                <p className="text-muted">Urutkan</p>

                                                <Input
                                                    type="select"
                                                    name="select"
                                                    id="select"
                                                    onChange={(e) => this.setState({ urutkanEbook: e.target.value })}
                                                    value={this.urutkanEbook}
                                                >
                                                    <option value="">Urutkan berdasarkan</option>
                                                    <option value="ASC">
                                                        ASC
                                                    </option>
                                                    <option value="DESC">
                                                        DESC
                                                    </option>
                                                </Input>
                                            </div>

                                            <hr className="my-4" /> */}

                                            <div className="search-box">
                                                <p className="text-muted">Cari</p>
                                                <div className="position-relative">
                                                    <input
                                                        type="search"
                                                        className="form-control rounded bg-light border-light"
                                                        placeholder="Cari..."
                                                        onChange={(e) => this.setState({ searchEbook: e.target.value })}
                                                        value={this.state.searchEbook}
                                                    />
                                                    <i className="mdi mdi-magnify search-icon"></i>
                                                </div>
                                            </div>

                                            <hr className="my-4" />

                                            {/* <div>
                                                <p className="text-muted">Kategori</p>

                                                <Input
                                                    type="select"
                                                    name="select"
                                                    id="select"
                                                    onChange={(e) => this.setState({ categoriesEbook: e.target.value })}
                                                    value={this.categoriesEbook}
                                                >
                                                    <option value="">Pilih Kategori</option>
                                                    <option value="Kategori">
                                                        Kategori
                                                    </option>
                                                    <option value="Spesialis">
                                                        Spesialis
                                                    </option>
                                                    <option value="Molekul">
                                                        Molekul
                                                    </option>
                                                    <option value="Penyakit">
                                                        Penyakit
                                                    </option>
                                                    <option value="Penulis">
                                                        Penulis
                                                    </option>
                                                    {this.state.listCategory ? this.state.listCategory.map((cat) => (
                                                        <option key={cat.category_id} value={cat.category_name}>{cat.category_name}</option>
                                                    )) : null}
                                                </Input>
                                            </div>

                                            <hr className="my-4" /> */}

                                            {/* <div>
                                                <p className="text-muted">List {this.state.categoriesEbook}</p>

                                                <Input
                                                    type="select"
                                                    name="select"
                                                    id="select"
                                                    onChange={(e) => this.setState({ c: e.target.value })}
                                                    value={this.valueEbook}
                                                >
                                                    <option value="">Pilih {this.state.categoriesEbook}</option>
                                                    {this.state.listValue ? this.state.listCategory.map((cat) => (
                                                        <option key={cat.category_id} value={cat.category_name}>{cat.category_name}</option>
                                                    )) : null}
                                                </Input>
                                            </div>

                                            <hr className="my-4" /> */}

                                            <Button color="danger" onClick={() => this.getEbook()}>
                                                Cari
                                            </Button>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        {this.state.alert ?
                            <SweetAlert success title={this.state.message} onConfirm={() => this.setState({alert:false})}>
                            </SweetAlert> : ''
                        }
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(Ebook);
