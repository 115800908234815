import React, { Component } from "react"
import { Row, Col, Card, CardBody, Button, Alert } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import moment from 'moment'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

//urlAPI
import UrlApi from "../../helpers/UrlAPI"

import httprequester from '../../lib/httprequester'

class verifikasilistuser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      listSTRUser: [],
      alert: false,
      message: '',
      id: null,
      formNPA: false,
      npa_no: '',
      npa_id: 0,
      npa_name: '',
      user_id: 0,
      formSTR: false,
      formEmail: false,
      user_email: '',
      user_name: '',
      str_id: 0,
      str_no: '',
      str_expired: '',
      loading: false,
      formNarasumber: false,
      formNarasumberHapus: false,
      narasumber_name: '',
      status_narasumber: '',
      tanya_id: 0,
      // narasumber_id:0,
      status_tanya_ahli: '',
      formSedangBerlangsung:false,
      formHp:false,
      user_handphone:0
    }

  }


  popUpNPA = (row) => {
    // console.log('npa_id',row);
    this.setState({
      npa_no: row.npa_no,
      npa_id: row.npa_id,
      npa_name: row.npa_name,
      user_id: row.user_id,
      formNPA: true
    })
  }

  popUpEmail = (row) => {
    this.setState({
      user_id: row.user_id,
      user_email: row.user_email,
      user_name: row.user_name,
      formEmail: true
    })
  }

  cekprefixnohp = (hp) => {
    let nohp = hp.includes('62')
    let nohps = hp.charAt(0);
    // console.log('nohp',nohp); 
    if (nohp) {
      let hasil = hp.toString().substring(2).trim()
      return hasil
    } else if (nohps == 0) {
      let hasil = hp.toString().substring(1).trim()
      return hasil
    } else {
      return hp
    }
  }

  cekprefixnohpSend = (hp) => {
    let nohps = hp.charAt(0)
    let nohp = hp.includes('62')
    if (nohp) {
      let hasil = '62' + hp.toString().substring(2).trim()
      return hasil
    } else if (nohps == 0) {
      let hasil = '62' + hp.toString().substring(1).trim()
      return hasil
    } else {
      let hasil = '62' + hp
      return hasil
    }
  }

  popUpNoHp = (row) => {
    this.setState({
      user_id: row.user_id,
      user_email: row.user_email,
      user_name: row.user_name,
      user_handphone: this.cekprefixnohp(row.user_handphone),
      formHp: true
    })
  }

  popUpSTR = (row) => {
    let dates = moment(row.str_expired)
    this.setState({
      str_no: row.str_no,
      str_id: row.str_id,
      str_expired: (dates.isValid) ? dates.format("YYYY-MM-DD") : '',
      user_id: row.user_id,
      formSTR: true
    })
  }

  popUpNarasumber = (row) => {
    this.setState({
      user_id: row.user_id,
      narasumber_name: row.narasumber_name,
      formNarasumber: true,
      status_narasumber:'y'
      // tanya_id:tanya_id
    })
  }

  popUpHapusNarasumber = (row) => {
    this.setState({
      user_id: row.user_id,
      narasumber_name: row.narasumber_name,
      status_narasumber: 'n',
      formNarasumberHapus: true
    })
  }

  submitEmail = () => {
    let { user_id, user_email, user_name } = this.state;
    let body = {
      user_id,
      user_email,
      user_name
    }

    this.setState({
      loading: true,
      formEmail: false
    })

    httprequester.postnobody('admin/update_user', body, {
      success: async function (response) {
        // if (response.data.message == "success") {
        this.setState({
          message: response.data.detailMessage,
          alert: true,
          loading: false,
        })
        // this.getUser()
        // }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })

  }

  submitNoHp = () => {
    let { user_id, user_handphone, user_name } = this.state;
    let nohpnew = this.cekprefixnohpSend(user_handphone)
    let body = {
      user_id,
      user_handphone: nohpnew,
      user_name
    }

    this.setState({
      loading: true,
      formHp: false
    })

    httprequester.postnobody('admin/update_user', body, {
      success: async function (response) {
        // if (response.data.message == "success") {
        this.setState({
          message: response.data.detailMessage,
          alert: true,
          loading: false,
        })
        // this.getUser()
        // }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })

  }

  submitNPA = () => {
    // console.log(this.state.npa_no, this.state.npa_id, this.state.npa_name)
    let { npa_id, npa_name, npa_no, user_id } = this.state;
    let body = {
      npa_id,
      npa_name,
      npa_no,
      user_id
    }

    // console.log(body)
    this.setState({
      loading: true,
      formNPA: false
    })

    httprequester.postnobody('admin/npa', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          this.setState({
            message: response.data.detailMessage,
            alert: true,
            loading: false,
          })
          // this.getUser()
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  submitSTR = () => {
    let { str_id, str_no, str_expired, user_id } = this.state;
    let body = {
      str_id,
      str_no,
      str_expired,
      user_id
    }

    this.setState({
      loading: true,
      formSTR: false
    })

    httprequester.postnobody('admin/str', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          this.setState({
            message: response.data.detailMessage,
            alert: true,
            loading: false,
          })
          // this.getUser()
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  submitNarasumber = () => {
    let { user_id, narasumber_name, status_narasumber } = this.state;
    let body = {
      narasumber_name: narasumber_name,
      user_id: user_id,
      status_narasumber:status_narasumber
    }

    this.setState({
      loading: true,
      formNarasumber: false
    })

    httprequester.postnobody('ahli/save-narasumber', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          this.setState({
            message: response.data.detailMessage,
            alert: true,
            loading: false,
          })
          // this.getUser()
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  hapusNarasumber = () => {
    let { user_id, narasumber_name, status_narasumber } = this.state;
    let body = {
      narasumber_name: narasumber_name,
      user_id: user_id,
      status_narasumber: status_narasumber
    }

    this.setState({
      loading: true,
      formNarasumberHapus: false
    })

    httprequester.postnobody('ahli/save-narasumber', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          this.setState({
            message: response.data.detailMessage,
            alert: true,
            loading: false,
          })
          // this.getUser()
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  getUser = () => {
    let body = {
      // "str_status": "A"
    }

    this.setState({
      loading: true,
      formSTR: false,
      formNPA: false,
      formNarasumber: false,
      formNarasumberHapus: false,
      alert: false
    })
    httprequester.postnobody('admin/list_users', body, {
      success: async function (response) {
        if (response.data.message == "success") {
          let data = []
          // response.data.detailMessage.forEach(async (v, key) => {
          // await data.push({
          //   str_id: v.str_id,
          //   str_no: v.str_no,
          //   str_expired: moment(v.str_expired).format('DD MMM YYYY'),
          //   created_at: v.created_at,
          //   updated_at: v.updated_at,
          //   str_status: v.str_status,
          //   user_id: v.user_id,
          //   user_name: v.user_name
          // })
          // })
          this.setState({
            listSTRUser: response.data.detailMessage,
            loading: false
          })
        }
        // console.log(response)
      }.bind(this),
      error: function (err) {

      }.bind(this)

    })
  }

  handlecancel = () => {
    this.setState({
      alert: false
    })
  }


  componentDidMount() {
    this.getUser()
  }

  render() {

    const columns = [{
      dataField: 'user_name',
      text: 'Username',
      sort: true
    }, {
      dataField: 'user_membership',
      text: 'Membership',
      sort: true
    }, {
      dataField: 'user_email',
      text: 'Email',
      sort: true
    },
    {
      dataField: 'user_handphone',
      text: 'Handphone',
      sort: true
    }, {
      dataField: 'user_email',
      text: 'Email',
      sort: true
    },
      {
        dataField: 'user_specialist_name',
        text: 'Spesialis',
        sort: true
      },
    {
      dataField: 'npa_no',
      text: 'Nomor NPA',
      sort: true
    },
    {
      dataField: 'npa_name',
      text: 'Nama NPA',
      sort: true
    },
    {
      dataField: 'str_no',
      text: 'Nomor STR',
      sort: true
    },
    {
      dataField: 'str_expired',
      text: 'Masa Berlaku STR',
      sort: true,
      formatter: (row) => {
        let dates = moment(row)
        if (dates.isValid()) {
          return dates.format("YYYY-MM-DD")
        }

        return ''

      }
    },
    {
      dataField: 'user_id',
      text: 'Action',
      sort: true,
      formatter: (cell, row, index) => {
        console.log(cell, row, index)

        return <div>
          {
            (row.npa_name == null) ?
              <Button
                color="primary"
                className="btn-sm"
                onClick={(e) => { this.popUpNPA(row) }}
              >
                Isi NPA
              </Button> :
              <Button
                color="primary"
                className="btn-sm"
                onClick={(e) => { this.popUpNPA(row) }}
              >
                Ubah NPA
              </Button>
          }

          &nbsp;

          {
            (row.str_no == null) ?
              <Button
                color="info"
                className="btn-sm"
                onClick={(e) => { this.popUpSTR(row) }}
              >
                Isi STR
              </Button> :
              <Button
                color="info"
                className="btn-sm"
                onClick={(e) => { this.popUpSTR(row) }}
              >
                Ubah STR
              </Button>
          }
          &nbsp;
          <Button
            color="primary"
            className="btn-sm"
            onClick={(e) => { this.popUpEmail(row) }}
          >
            Ubah Email
          </Button>
          &nbsp;
          <Button
            color="primary"
            className="btn-sm"
            onClick={(e) => { this.popUpNoHp(row) }}
          >
            Ubah No Handphone
          </Button>
          &nbsp;
          {/* {
            (row.user_membership == 'Tenaga Medis Terverifikasi') ?
              <Button
                color="info"
                className="btn-sm"
                onClick={(e) => { this.popUpNarasumber(row) }}
              >
                {row.narasumber_id && row.status_narasumber == 'y' ? 'Ubah Narasumber Tanya Ahli' : 'Set Narasumber Tanya Ahli'}
              </Button> :
              ''
          }
          &nbsp;
          {
            (row.user_membership == 'Tenaga Medis Terverifikasi') ?
            <div>
                <br />
              {row.status_narasumber == 'y' ?
                  <Button
                    color="danger"
                    className="btn-sm"
                    onClick={(e) => { this.popUpHapusNarasumber(row) }}
                  >
                    Hapus Narasumber Tanya Ahli
                  </Button> :
                  null }
            </div> : null
             
          } */}
        </div>
      }
    }];

    const defaultSorted = [{
      dataField: 'user_name',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.state.listSTRUser.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.listSTRUser).length }];

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>User | Ruang Ilmiah</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="User" breadcrumbItem="Daftar User" />
            {
              (this.state.formNPA) ?
                <SweetAlert
                  title={"Form NPA"}
                  onConfirm={() => this.submitNPA()}
                  onCancel={() => this.setState({ formNPA: false })}
                  showCancel={true}
                  type={'controlled'}
                  dependencies={[this.state.npa_name, this.state.npa_no]}
                >
                  {(renderProps) => (
                    <form>
                      <input
                        type={'text'}
                        ref={renderProps.setAutoFocusInputRef}
                        className="form-control"
                        value={this.state.npa_no}
                        onKeyDown={renderProps.onEnterKeyDownConfirm}
                        onChange={(e) => this.setState({ npa_no: e.target.value })}
                        placeholder={'Nomor NPA'}
                      />
                      <br />
                      <input
                        type={'text'}
                        className="form-control"
                        value={this.state.npa_name}
                        onKeyDown={renderProps.onEnterKeyDownConfirm}
                        onChange={(e) => this.setState({ npa_name: e.target.value })}
                        placeholder={'Nama NPA'}
                      />
                      <hr />
                    </form>
                  )}
                </SweetAlert> : null
            }

            {
              (this.state.formEmail) ?
                <SweetAlert
                  title={"Ubah / Resend Email User"}
                  onConfirm={() => this.submitEmail()}
                  onCancel={() => this.setState({ formEmail: false })}
                  showCancel={true}
                  type={'controlled'}
                  dependencies={[this.state.user_email, this.state.user_id, this.state.user_name]}
                >
                  {(renderProps) => (
                    <form>
                      <input
                        type={'text'}
                        ref={renderProps.setAutoFocusInputRef}
                        className="form-control"
                        value={this.state.user_email}
                        onKeyDown={renderProps.onEnterKeyDownConfirm}
                        onChange={(e) => this.setState({ user_email: e.target.value })}
                        placeholder={'User Email'}
                      />
                      <hr />
                    </form>
                  )}
                </SweetAlert> : null
            }

            {
              (this.state.formHp) ?
                <SweetAlert
                  title={"Ubah No Handphone"}
                  onConfirm={() => this.submitNoHp()}
                  onCancel={() => this.setState({ formHp: false })}
                  showCancel={true}
                  type={'controlled'}
                  dependencies={[this.state.user_handphone, this.state.user_id, this.state.user_name]}
                >
                  {(renderProps) => (
                    <form>
                      <div className='input-group'>
                        <span className="input-group-text" id="basic-addon1">62</span>
                        <input id="form-phone" type="text" ref={renderProps.setAutoFocusInputRef} className={"form-control"} value={this.state.user_handphone} onChange={(e) => this.setState({ user_handphone: e.target.value })} />
                      </div>
                      <hr />
                    </form>
                  )}
                </SweetAlert> : null
            }

            {
              (this.state.formSTR) ?
                <SweetAlert
                  title={"Form STR"}
                  onConfirm={() => this.submitSTR()}
                  onCancel={() => this.setState({ formSTR: false })}
                  showCancel={true}
                  type={'controlled'}
                  dependencies={[this.state.str_no, this.state.str_expired]}
                >
                  {(renderProps) => (
                    <form>
                      <input
                        type={'text'}
                        ref={renderProps.setAutoFocusInputRef}
                        className="form-control"
                        value={this.state.str_no}
                        onKeyDown={renderProps.onEnterKeyDownConfirm}
                        onChange={(e) => this.setState({ str_no: e.target.value })}
                        placeholder={'Nomor STR'}
                      />
                      <br />
                      <input
                        type={'date'}
                        className="form-control"
                        value={this.state.str_expired}
                        onKeyDown={renderProps.onEnterKeyDownConfirm}
                        onChange={(e) => this.setState({ str_expired: e.target.value })}
                        placeholder={'Masa Berlaku STR'}
                        min={moment().format("YYYY-MM-DD")} max={"YYYY-MM-DD"}
                      />
                      <hr />
                    </form>
                  )}
                </SweetAlert> : null
            }

            {
              (this.state.formNarasumber) ?
                <SweetAlert
                  title={"Form Narasumber"}
                  onConfirm={() => this.submitNarasumber()}
                  onCancel={() => this.setState({ formNarasumber: false })}
                  showCancel={true}
                  type={'controlled'}
                // dependencies={[this.state.str_no, this.state.str_expired]}
                >
                  {(renderProps) => (
                    <form>
                      <input
                        type={'text'}
                        ref={renderProps.setAutoFocusInputRef}
                        className="form-control"
                        value={this.state.narasumber_name}
                        onKeyDown={renderProps.onEnterKeyDownConfirm}
                        onChange={(e) => this.setState({ narasumber_name: e.target.value })}
                        placeholder={'Nama Narasumber'}
                      />
                      <hr />
                    </form>
                  )}
                </SweetAlert> : null
            }

            {
              (this.state.formNarasumberHapus) ?
                <SweetAlert
                  warning
                  showCancel
                  // showCloseButton
                  confirmBtnText="ya"
                  cancelBtnText="tidak"
                  confirmBtnBsStyle="danger"
                  title="Apakah anda yakin?"
                  onConfirm={() => this.hapusNarasumber()}
                  onCancel={() => this.setState({ formNarasumberHapus: false })}
                  focusCancelBtn
                >
                </SweetAlert>
                : null
            }

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='user_name'
                      columns={columns}
                      data={this.state.listSTRUser}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='user_id'
                          columns={columns}
                          data={this.state.listSTRUser}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"user_name"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          {this.state.alert ?
            <SweetAlert title={this.state.message} onConfirm={() => this.getUser()}>
            </SweetAlert> : ''
          }

          {this.state.loading ? (
            <SweetAlert title="menghubungkan ke server" showConfirm={false} >
            </SweetAlert>
          ) : null}
        </div>
      </React.Fragment>
    )
  }
}

export default verifikasilistuser