import React, { Component } from "react"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
// Modal, ModalHeader, ModalBody, ModalFooter
import MetaTags from 'react-meta-tags'
import Axios from 'axios'
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { urlAPI } from '../../config';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import SweetAlert from 'react-bootstrap-sweetalert';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

import httprequester from '../../lib/httprequester'

class ListGeneralSEO extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            sizePerPage: 10,
            ListGeneralSEO: [],
            alert: false,
            content_id:{},
            content_type: 'general'
        }
    }

    componentDidMount() {
        this.getListGeneralSEO()
    }
    
    getListGeneralSEO = () => {
       
        let body = {
            content_type: this.state.content_type,
        }
        // console.log('isi', body)
        
        
        httprequester.postnobody('admin/getdetail_seo', body , {
            success: async function (response) {
                if (response.data.message == "success") {
                    // console.log("isi", response.data.result)
                    this.setState({
                        ListGeneralSEO: response.data.result
                    })
                }
                // console.log("isi", response.data.result)
            }.bind(this),
            error: function (err) {
            }.bind(this)
        })
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    edit = (row) => {
        this.props.history.push(`/list-seogeneral/${row}`)
    }

    render() {
        const columns = [{
            dataField: 'content_id',
            text: 'Menu SEO',
            sort: true
        },
        {
            dataField:'content_id',
            text: 'Action',
            sort: true,
            formatter: (row) => {
                // console.log('bener', row)
                return <div>
                    <Button
                        color="success"
                        className="btn-sm"
                        onClick={(e) => { this.edit(row) }}>
                        Edit
                        {/* -{(row)} */}
                    </Button>
                </div>
            }
        },
        // {
        //     dataField: 'content_type',
        //     text: 'SEO Type',
        //     sort: true
        // },
        
    ];

        const defaultSorted = [{
            dataField: 'content_id',
            order: 'asc'
        }];

        const pageOptions = {
            sizePerPage: 10,
            totalSize: this.state.ListGeneralSEO.length, // replace later with size(customers),
            custom: true,
        }

        // Custom Pagination Toggle
        const sizePerPageList = [
            { text: '5', value: 5 },
            { text: '10', value: 10 },
            { text: '15', value: 15 },
            { text: '20', value: 20 },
            { text: '25', value: 25 },
            { text: 'All', value: (this.state.ListGeneralSEO).length }];

        const { SearchBar } = Search;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Menu SEO General | Ruang Ilmiah</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title="List SEO" breadcrumbItem="Menu SEO General" />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField='content_id'
                                            columns={columns}
                                            data={this.state.ListGeneralSEO}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField='content_id'
                                                    columns={columns}
                                                    data={this.state.ListGeneralSEO}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col md="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={"content_id"}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            defaultSorted={defaultSorted}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )
                                                    }
                                                </ToolkitProvider>
                                            )
                                            }</PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ListGeneralSEO